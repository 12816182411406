import assets from "./assets";
import isEmpty from 'lodash/isEmpty'

/**
 * Requires stringified HTML content
 * Adds all JSON attributes as proper attributes to each element in the HTML content
 * Returns stringified HTML content
 * @param {string} content
 * @returns {string}
 */
export function parseHTMLAttributes(content) {
  const tempStrs = [];

  let jsonAttrs = content.substring(content.lastIndexOf('{'), content.lastIndexOf('}') + 1)
  while (jsonAttrs) {
    try {
      const attrs = JSON.parse(jsonAttrs)
      let attrsString = ''
      Object.keys(attrs).forEach(k => attrsString += `${k}="${attrs[k]}" `)
      content = content.replace(jsonAttrs, attrsString);
    } catch (e) {
      console.log(e);
      const tempStr = "(" + jsonAttrs.substring(1, jsonAttrs.length - 1) + ")";
      tempStrs.push(tempStr);
      content = content.replace(jsonAttrs, tempStr);
    } finally {
      jsonAttrs = content.substring(content.lastIndexOf('{'), content.lastIndexOf('}') + 1)
    }
  }

  tempStrs.forEach(str => {
    const oldStr = "{" + str.substring(1, str.length - 1) + "}";
    content = content.replace(str, oldStr);
  })

  return content;
}

/**
 * Replace all image sources with provided images
 * Image name must contain its format
 * @param {HTMLElement} html
 * @param {string} folder
 * @param {{ name, type }[]} images
 * @returns {HTMLElement}
 */
export function replaceImageSources(html, folder, images) {
  if (!html) { return html; }
	images = images || [];

  // Get image elements
  const imageElements = html.getElementsByTagName('img')

  // Replace sources
  if (imageElements && imageElements.length > 0) {
    for (let index = 0; index < imageElements.length; index++) {
      // Get image data
      const imgToReplaceWith = images[index] ? images[index] : null;

      // Get image
      // const imageData = imgToReplaceWith
        //? assets.getImageData(folder, images[index].name)
        // : assets.getImagePlaceholder();
      const imageData = imgToReplaceWith
        ? imgToReplaceWith.src
        : assets.getImagePlaceholder();

      // Replace source
      imageElements[index].setAttribute('src', imageData);
    }
  }

  return html;

}

/**
 * Replace all video sources with provided videos
 * Video name must contain its format
 * @param {HTMLElement} html
 * @param {string} folder
 * @param {{ name, type }[]} videos
 * @param {boolean} controls
 * @param {boolean} autoplay
 * @returns {HTMLElement}
 */
export function replaceVideoSources(html, folder, videos, controls = true, autoplay = false, controlsList='') {
  if (!html) { return html; }
	videos = videos || [];

  // Get image elements
  const videoElements = html.getElementsByTagName('video')

  // Replace sources
  if (videoElements && videoElements.length > 0) {
    for (let index = 0; index < videoElements.length; index++) {
      // Get video data
      const videoData = videos[index] ? videos[index] : null;
      if (!videoData) { continue; }

      // Create video el
      const videoEl = document.createElement('video');

      // videoEl.poster = isEmpty(videoData.posterImage)
      //   ? assets.getImagePlaceholder()
      //   : assets.getImageData(folder, videoData.posterImage.name)
      videoEl.poster = videoData.posterImage.src
      videoEl.controls = controls;
      videoEl.autoplay = autoplay;
      videoEl.controlsList = controlsList;
      videoEl.disablePictureInPicture = true;

      // Create sources
      videoData.sources.forEach(source => {
      	// if (source.name) {
      	  const sourceEl = document.createElement('source');
		      // sourceEl.src = assets.getFileURL(folder, source.name)
		      sourceEl.src = source.src;
		      sourceEl.type = source.type;
		      videoEl.appendChild(sourceEl);
      	// }
      })

      // Replace
      videoElements[index].replaceWith(videoEl)
    }
  }

  return html;

}

/**
 * Replace all link hrefs with provided callback
 * @param {HTMLElement} html
 * @param {function} callback
 * @returns {HTMLElement}
 */
export function replaceLinkHrefs(html) {
  if (!html) { return html; }

  // Get anchor elements
  const anchorElements = html.getElementsByTagName('a');

	// Replace sources
	if (anchorElements && anchorElements.length > 0) {
		for (let index = 0; index < anchorElements.length; index++) {
			// Create anchor el
			const anchorEl = document.createElement('a');

			// Get sublesson id
			const href = anchorElements[index].href;
			const sublessonId = href.indexOf('(') === -1
				? href.substring(href.lastIndexOf('/') + 1, href.length)
				: href.substring(href.lastIndexOf('(') + 1, href.lastIndexOf(')'))
      anchorEl.classList.add(...['article-anchorEl', `lesson-${sublessonId}`]);

			// Get anchor text
			anchorEl.href = "javascript:void(0)";
			anchorEl.innerHTML = anchorElements[index].innerHTML;

			// Replace
			anchorElements[index].replaceWith(anchorEl);
		}
	}

  return html;

}

/**
 * Replace all form actions with actual pdf paths
 * @param {HTMLElement} html
 * @param {string} folder
 * @param {{ name, type }[]} pdfs
 * @returns {HTMLElement}
 */
export function replacePdfSources(html, folder, pdfs) {
  if (!html) { return html; }

  // Get form elements
  const formElements = html.getElementsByTagName('form');

	// Replace sources
	if (formElements && formElements.length > 0) {
		for (let index = 0; index < formElements.length; index++) {
			const pdf = pdfs[index] ? pdfs[index] : null;
      if (!pdf) { continue; }

      formElements[index].dataset.pdfname = pdf.name;
      let url = new URL(pdf.url);
			if (pdf.params) {
				pdf.params.forEach(param => {
          url.searchParams.append(param.name,param.value);
        });
			}
      formElements[index].action = url.href;
		}
	}

  return html;

}

export default { parseHTMLAttributes, replaceImageSources, replaceVideoSources, replaceLinkHrefs, replacePdfSources };
