<template>
  <div class="wrapper d-flex flex-fill" style="background-color: #f2f2f2; overflow-x: auto;">
    <template v-if="!pendingComponentInit">
      <!-- Left column -->
      <div class="left-column d-flex flex-column align-items-center justify-content-end justify-content-lg-between pr-3" style="flex: 6%">
        <div v-if="!isLayoutLimited" class="logo"><img src="../../../../assets/images/logo-watermark.png" alt="logo-watermark" class="mw-100 h-auto"/></div>

				<div class="d-flex flex-column align-items-center">
          <div class="tls-ruler-player__tools">
            <button class="tool-button reset" :title="$t('telestia.hover_text.info')"
                    @click="reset()"></button>
            <button class="tool-button changeBackground"
                    :class="{ changeBackground_active: changeBackgroundActive }" :title="$t('telestia.hover_text.change_bg')"
                    @click="changeBackgroundActive = !changeBackgroundActive"></button>
            <button class="tool-button showFront"
                    :class="{ showFront_active: showFrontActive }" :title="$t('telestia.hover_text.show_front')" @click="showFront()"></button>
            <button class="tool-button showBack"
                    :class="{ showBack_active: showBackActive }" :title="$t('telestia.hover_text.show_back')" @click="showBack()"></button>
            <button v-if="!isObjEmpty(selectedLesson.html.moveText)" class="tool-button move"
                    :class="{ move_active: moveActive }" :title="$t('telestia.hover_text.move')" @click="toggleMoveMode()"></button>
            <button v-if="!isObjEmpty(selectedLesson.html.zoomText)" class="tool-button zoom"
                    :class="{ zoom_active: zoomActive }" :title="$t('telestia.hover_text.zoom')" @click="toggleZoomMode()"></button>
            <button v-if="!isObjEmpty(selectedLesson.html.manekenText) && !isLayoutLimited" class="tool-button maneken"
                    :class="{ maneken_active: manekenActive }" :title="$t('telestia.hover_text.show_maneken')" @click="toggleManekenMode()"></button>
          </div>
        </div>
      </div>

      <!-- Lesson content -->
      <template>

        <!-- Middle column -->
        <div class="middle-column d-flex flex-column" style="flex: 65%; max-height: 100%">
          <div v-if="hasCheckboxes" class="w-100 d-flex justify-content-center align-items-center mb-2">
            <div class="d-flex justify-content-between w-75 p-2 border">
              <b-form-checkbox id="checkbox-cross" v-model="crossActive"><span class="ml-2">Cross Marks</span></b-form-checkbox>
              <b-form-checkbox id="checkbox-diagonal" v-model="diagonalActive"><span class="ml-2">Diagonal Lines</span></b-form-checkbox>
              <b-form-checkbox id="checkbox-horizontal" v-model="horizontalActive"><span class="ml-2">Horizontal Lines</span></b-form-checkbox>
            </div>
          </div>

          <div
            ref="contentWrapper"
            class="d-flex justify-content-center align-items-center position-relative"
            :class="{ 'main-content_move_active': moveActive, 'mb-2': showStepper }"
            style="flex: 100%; max-height: 100%; background-color: white; background-repeat: no-repeat; background-size: 100% 100%; overflow: hidden;"
            :style="{ backgroundImage: 'url(' + rulerBackground + ')' }"
          >
            <maneken v-if="manekenActive" :show-front="showFrontActive" />

            <template v-if="!manekenActive">
            	<div class="main-image-container d-flex" :class='{ "main-image-container-bordered": moveActive || zoomActive }' style="overflow: hidden">
            		<div v-if="zoomActive" class="main-content-image-zoom-container" @click="setZoomState('click')" @mouseleave="setZoomState('mouseleave')">
				      		<inner-image-zoom
				          	class="main-content-image-zoom"
				          	:src="mainContentImage"
				          	:zoomScale="2"
				          	:style="{ width: `${containerDimensions.width}px`, height: `${containerDimensions.height}px` }"
				          ></inner-image-zoom>
                </div>

            		<div v-else class="moveable-image-container" :style="{ width: `${containerDimensions.width}px`, height: `${containerDimensions.height}px` }">
            			<Moveable class="moveable-image" v-bind="moveable" @drag="handleMove">
				            <img
				              class="main-content-image-movable"
				              :class='{ "main-content-image_move_active": moveActive }'
				              :src="mainContentImage"
				              :height="mainContentImageDimensions.height * contentImageMoveScale"
		              		:width="mainContentImageDimensions.width * contentImageMoveScale"
				              alt="main-content-image"
				            >
				          </Moveable>
            		</div>
            	</div>
            </template>
          </div>

          <div v-if="showStepper" class="controls-wrapper d-flex justify-content-center align-items-center w-100">
            <div class="controls d-flex  justify-content-center align-items-center position-relative w-75" style="height: fit-content;">
              <button
              	v-if="mainLesson"
              	class="slideshow-button-main-lesson position-absolute"
              	:title="$t('telestia.hover_text.back_to_mlfp')"
              	@click="backToMainLesson()"
              	style="top: 8px; left: 15px;"
              >
	              <font-awesome-icon :icon="['fas', 'th-large']" style="font-size: 1.8rem;" />
	            </button>
              <button
              	class="controls-button begin"
              	:class="{ disabled: currentStep === 0 }"
              	:title="$t('telestia.hover_text.start_again')"
              	@click="navigateTo(0)"
              ></button>
              <button
              	class="controls-button prev ml-2"
              	:class="{ disabled: currentStep === 0 }"
              	:title="$t('telestia.hover_text.previous_step')"
              	@click="navigateTo(currentStep - 1)"
              ></button>
              <button
              	class="controls-button next ml-2"
              	:class="{ disabled: currentStep === stepsCount - 1 }"
              	:title="$t('telestia.hover_text.next_step')"
              	@click="navigateTo(currentStep + 1)"
              ></button>
              <span class="position-absolute steps" style="top: 10px; right: 10px;">{{ currentStep + 1 }}/{{ stepsCount }}</span>
            </div>
          </div>
        </div>

        <!-- Right column -->
        <div class="right-column d-flex flex-column px-3" style="flex: 30%; max-height: 100%;">
          <div class="tls-html-textpanel d-flex flex-column" :class="[voiceover ? 'flex-80' : 'flex-90']">
            <div class="titlewrapper container px-0 mb-4">
              <div class="row">
                <div class="code col-auto d-flex justify-content-center align-items-center">
                  <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
                </div>
                <div class="title col d-flex flex-column">
                  <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
                  <span style="border-bottom: 1px solid #1b96cf"/>
                </div>
              </div>
            </div>
            <div class="tls-html-text" v-html="currentText" style="overflow-y: auto;"></div>
          </div>

          <div v-if="voiceover" style="flex: 20%"></div>

          <div v-if="!isOfflineBuild" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
		        <div class="d-flex">
				      <button	class="action-button px-0 mr-2" :class="{ 'disabled': !packagePdf }" :title="$t('telestia.hover_text.download_lp')" @click="downloadPackagePdf">
				        <font-awesome-icon :icon="['fas', 'box']" size="2x" />
				      </button>
				      <button
				        class="action-button px-0"
				        :class="{ 'disabled': !hasAssignment }"
				        :title="$t('telestia.hover_text.upload_assignment')"
				        @click="$router.push({ name: 'App:Assignments' })"
				      >
				        <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
				      </button>
				    </div>

            <mark-as-complete :completed="completed" :lessonid="lessonId" />
		      </div>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import backgroundImage from '../../../../assets/images/tools/bgk.png'
import Moveable from 'vue-moveable';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import Maneken from './Maneken';
import MarkAsComplete from '../../shared/MarkAsComplete.vue';
import _isEmpty from "lodash/isEmpty";
import assets from "../../../../shared/helpers/assets";
import lessons from "../../../../shared/helpers/lessons";
import envConfig from "../../../../shared/configs/config.local";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  components: {
    Moveable, 'inner-image-zoom': InnerImageZoom, Maneken, MarkAsComplete
  },

  name: 'ruler-html',

  props: ['selectedLesson', 'completed', 'mainLesson'],

  mounted() {
    this.showVoiceover(0);
  },

  data: function () {
    return {
      pendingComponentInit: false,

      currentStep: 0,
      showStepper: true,
      changeBackgroundActive: false,
      showFrontActive: true,
      showBackActive: false,
      crossActive: false,
      horizontalActive: false,
      diagonalActive: false,
      moveActive: false,
      zoomActive: false,
      zoomInProgress: false,
      manekenActive: false,
      moveable: { draggable: true },
      mainContentImageDimensions: { height: 0, width: 0 },

      voiceover: undefined,
      currentlyViewingExtra: null,
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

		rulerLesson() {
      const steps = this.selectedLesson.html.steps;
      const moveText = this.selectedLesson.html.moveText;
      const zoomText = this.selectedLesson.html.zoomText;
      const manekenText = this.selectedLesson.html.manekenText;

      return { steps, moveText, zoomText, manekenText };
    },

    rulerBackground() {
      return this.changeBackgroundActive ? backgroundImage : ''
    },

    packagePdf() {
      if (this.mainLesson) { return this.$store.getters.getPackagePdf(this.mainLesson.code); }
      return this.$store.getters.getPackagePdf(this.selectedLesson.key);
    },

    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },

    hasCheckboxes() {
      return (this.selectedLesson.folder.toLowerCase() === "pm" && this.lessonCode === "A02") ||
          (this.selectedLesson.folder.toLowerCase() === "pgr" && this.lessonCode === "A05");
    },

    stepsCount() {
      return this.rulerLesson.steps.en.length;
    },

    currentText() {
      if (this.moveActive) { return this.translate(this.rulerLesson.moveText); }
      if (this.zoomActive) { return this.translate(this.rulerLesson.zoomText); }
      if (this.manekenActive) { return this.translate(this.rulerLesson.manekenText); }

      return this.translate(this.rulerLesson.steps)[this.currentStep];
    },

    assetsFolder() {
      let assetsFolder;
      switch (this.selectedLesson.folder.toLowerCase()) {
        case 'pm':
          assetsFolder = 'pattern_making';
          break;
        case 'pgr':
          assetsFolder = 'pattern-grading';
          break;
        case 'fe':
          assetsFolder = 'fashion-express';
          break;
      }
      return assetsFolder;
    },

    mainContentImage() {
      let suffix = this.showFrontActive ? '_front' : '_back';
      if (this.crossActive) { suffix += '_cross'; }
      if (this.diagonalActive) { suffix += '_diagonal'; }
      if (this.horizontalActive) { suffix += '_horizontal'; }
      const lang = this.selectedLanguage;

			try {
      	return require(`../../../../assets/images/${this.assetsFolder}/${this.lessonCode}/${this.selectedLesson.folder.toUpperCase()}_${this.lessonCode}${suffix}_${lang}.png`);
      } catch (e) {
      	return require(`../../../../assets/images/${this.assetsFolder}/${this.lessonCode}/${this.selectedLesson.folder.toUpperCase()}_${this.lessonCode}${suffix}_en.png`);
      }
    },

    containerDimensions() {
    	if (!this.moveActive && !this.zoomInProgress) { return this.mainContentImageDimensions; }

    	if (this.mainContentImageDimensions.width > this.mainContentImageDimensions.height) {
    		return { width: this.mainContentImageDimensions.width, height: this.mainContentImageDimensions.height * 2.5 };
    	} else if (this.mainContentImageDimensions.width < this.mainContentImageDimensions.height) {
    		return { width: this.mainContentImageDimensions.width * 2.5, height: this.mainContentImageDimensions.height };
    	}
    	return this.mainContentImageDimensions;

    },

    contentImageMoveScale() { return this.moveActive ? 2.5 : 1 },

    hasAssignment() { return this.selectedLesson.hasAssignment || (this.mainLesson && this.mainLesson.hasAssignment); }

  },

  methods: {
  	backToMainLesson() {
      const menuItems = {
        menu1Index: this.mainLesson.menu1.index,
        menu2Index: this.mainLesson.menu2.index,
        lessonKey: this.mainLesson.code
      };
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = this.isObjEmpty(productSkeleton) ? menuItems : { ...menuItems, productSkeleton  };
      this.$store.commit('selectMenu', payload);
      this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(payload.lessonKey) } });
    },

    navigateToExtra(index) { this.currentlyViewingExtra = this.otherExtras[index]; },

    navigateToLesson() { this.currentlyViewingExtra = null; },

    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    getImageDimensions(imgSrc) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => { resolve({ height: image.naturalHeight, width: image.naturalWidth }); }
        image.src = imgSrc;
      })
    },

    resizeContentImage() {
      if (!this.$refs.contentWrapper) { return; }

      const contentAreaHeight = this.$refs.contentWrapper.clientHeight;
      const contentAreaWidth = this.$refs.contentWrapper.clientWidth;
      const ratio = this.mainContentImageDimensions.width / this.mainContentImageDimensions.height;

      let target_width = 0;
      let target_height = 0;

      if (this.mainContentImageDimensions.width > this.mainContentImageDimensions.height) {
        target_width = contentAreaWidth - 10;
        target_height = target_width / ratio;
        if (target_height > contentAreaHeight) {
          target_height = contentAreaHeight - 10;
          target_width = target_height * ratio;
        }
      } else {
        target_height = contentAreaHeight - 10;
        target_width = target_height * ratio;
        if (target_width > contentAreaWidth) {
          target_width = contentAreaWidth - 10;
          target_height = target_width / ratio;
        }
      }

      this.mainContentImageDimensions.height = target_height;
      this.mainContentImageDimensions.width = target_width;
    },

    showVoiceover(query) {
      const voiceoverExist =
          this.selectedLesson.voiceOver && Object.keys(this.selectedLesson.voiceOver).some(k => this.selectedLesson.voiceOver[k].length > 0);
      const translatedVoiceover = voiceoverExist ? this.selectedLesson.voiceOver[this.selectedLanguage] || this.selectedLesson.voiceOver.en : [];
      if (voiceoverExist) {
        this.voiceover = typeof query === "string"
            ? translatedVoiceover.find(v => v.name.includes(query))
            : translatedVoiceover.find(v => v.order === query + 1 && ['on-body', 'extra-zoom', 'zoom-window'].every(term => !v.name.includes(term)));
      } else {
      	this.voiceover = null;
      }

      const objectToEmit = this.voiceover ? this.voiceover.name : null;

      this.$emit('show-voiceover', objectToEmit);
    },

    isObjEmpty(obj) { return _isEmpty(obj); },

    translate(text) {
    	const translation = text[this.selectedLanguage];
    	if (translation) { return translation; }

    	return text.en;
    },

    reset() {
      this.navigateTo(0);
      this.showStepper = true;
      this.showFrontActive = true;
      this.showBackActive = false;
      this.changeBackgroundActive = false;
      this.zoomActive = false;
      this.moveActive = false;
      this.manekenActive = false;
      this.resetRulerPosition();
    },

    showFront() {
      this.showStepper = this.showStepper ? true : false;
      this.showFrontActive = true;
      this.showBackActive = false;
    },

    showBack() {
      this.showStepper = this.showStepper ? true : false;
      this.showBackActive = true;
      this.showFrontActive = false;
    },

    toggleMoveMode() {
    	this.zoomActive = false;
      this.manekenActive = false;
    	this.moveActive = !this.moveActive;
      this.showStepper = this.moveActive ? false : true;
      if (this.moveActive) { this.showVoiceover("extra-zoom"); }
      else { this.resetRulerPosition(); }
    },

    setZoomState(event) { this.zoomInProgress = event === 'click' ? !this.zoomInProgress : false; },

    handleMove({ target, left, top }) {
      if (this.moveActive) {
      	target.style.position = 'relative';
      	if (left <= 0 && Math.abs(left) <= (this.mainContentImageDimensions.width * this.contentImageMoveScale - this.mainContentImageDimensions.width) + 1) {
      		target.style.left = `${left}px`;
      	}
      	if (top <= 0 && Math.abs(top) <= (this.mainContentImageDimensions.height * this.contentImageMoveScale - this.mainContentImageDimensions.height) + 1) {
      		target.style.top = `${top}px`;
      	}
      }
    },

    resetRulerPosition() {
      if (!document.querySelector('.moveable-image')) { return; }
      document.querySelector('.moveable-image').style.left = '0px';
      document.querySelector('.moveable-image').style.top = '0px';
    },

    toggleZoomMode() {
    	this.moveActive = false;
      this.manekenActive = false;
      this.zoomActive = !this.zoomActive;
      this.showStepper = this.zoomActive ? false : true;
      if (this.zoomActive) { this.showVoiceover("zoom-window"); }
    },

    toggleManekenMode() {
    	this.zoomActive = false;
      this.moveActive = false;
      this.changeBackgroundActive = false;
    	this.manekenActive = !this.manekenActive;
      this.showStepper = this.manekenActive ? false : true;
      if (this.manekenActive) { this.showVoiceover("on-body"); }
    },

    navigateTo(stepIndex) {
      this.currentStep = stepIndex;
      this.showVoiceover(stepIndex);
    },

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
  	selectedLesson() {
  		this.reset();
  	},

    mainContentImage: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          const dimensions = await this.getImageDimensions(this.mainContentImage);
          this.mainContentImageDimensions = dimensions;
          this.resizeContentImage();
        }
      }
    },
    
    moveActive(n, o) {
    	if (n === true) { document.body.style.overflow = 'hidden'; }
    	else { document.body.style.overflow = 'auto'; }
    }
  }
}
</script>

<style>
.tls-ruler-player__tools {
  background: #1b96cf;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  width: 50px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tls-ruler-player__tools .tool-button {
  width: 28px;
  margin: 8px;
  height: 30px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
}

.tls-ruler-player__tools .reset {
  background-image: url(../../../../assets/images/tools/btn_reset_uncheck.png);
}

.tls-ruler-player__tools .reset:hover {
  background-image: url(../../../../assets/images/tools/btn_reset_check.png);
}

.tls-ruler-player__tools .changeBackground {
  background-image: url(../../../../assets/images/tools/btn_bkg_uncheck.png);
}

.tls-ruler-player__tools .changeBackground:hover {
  background-image: url(../../../../assets/images/tools/btn_bkg_check.png);
}

.tls-ruler-player__tools .changeBackground_active {
  background-image: url(../../../../assets/images/tools/btn_bkg_check.png);
}

.tls-ruler-player__tools .showFront {
  background-image: url(../../../../assets/images/tools/btn_rlrfrnt_uncheck.png);
}

.tls-ruler-player__tools .showFront:hover {
  background-image: url(../../../../assets/images/tools/btn_rlrfrnt_check.png);
}

.tls-ruler-player__tools .showFront_active {
  background-image: url(../../../../assets/images/tools/btn_rlrfrnt_check.png);
}

.tls-ruler-player__tools .showBack {
  background-image: url(../../../../assets/images/tools/btn_rlrbck_uncheck.png);
}

.tls-ruler-player__tools .showBack:hover {
  background-image: url(../../../../assets/images/tools/btn_rlrbck_check.png);
}

.tls-ruler-player__tools .showBack_active {
  background-image: url(../../../../assets/images/tools/btn_rlrbck_check.png);
}

.tls-ruler-player__tools .move {
  background-image: url(../../../../assets/images/tools/btn_rlrmove_uncheck.png);
}

.tls-ruler-player__tools .move:hover {
  background-image: url(../../../../assets/images/tools/btn_rlrmove_check.png);
}

.tls-ruler-player__tools .move_active {
  background-image: url(../../../../assets/images/tools/btn_rlrmove_check.png);
}

.tls-ruler-player__tools .zoom {
  background-image: url(../../../../assets/images/tools/btn_rlrzoom_uncheck.png);
}

.tls-ruler-player__tools .zoom:hover {
  background-image: url(../../../../assets/images/tools/btn_rlrzoom_check.png);
}

.tls-ruler-player__tools .zoom_active {
  background-image: url(../../../../assets/images/tools/btn_rlrzoom_check.png);
}

.tls-ruler-player__tools .maneken {
  background-image: url(../../../../assets/images/tools/btn_mnk_uncheck.png);
}

.tls-ruler-player__tools .maneken:hover {
  background-image: url(../../../../assets/images/tools/btn_mnk_check.png);
}

.tls-ruler-player__tools .maneken_active {
  background-image: url(../../../../assets/images/tools/btn_mnk_check.png);
}

.main-content_move_active {
  overflow: scroll;
  cursor: -webkit-grab;
}

.main-image-container {
  max-height: 100%;
  max-width: 100%
}

.main-content-image {
  /*max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding-left: 20px;
  position: relative;*/
}

.main-image-container-bordered {
	border: 0.5px solid grey;
}

.title-text {
  font-size: 18px;
}

.previous-step {
  cursor: pointer;
}

.next-step {
  cursor: pointer;
}

.current-step {
  color: #1b96cf;
  font-size: 24px;
  font-weight: bold;
}

.rCSwtyrwf .moveable-line {
  background: transparent !important;
}

.controls {
  background-color: #1b96cf;
  border-radius: 50px;
}

.controls .controls-button {
  position: relative;
  display: inline-block;
  background-image: url(../../../../assets/images/slideshow/toolbar-down28px-white.png);
  background-color: #1b96cf;
  width: 33px;
  height: 28px;
  border: none;
  margin: 8px 10px;
  outline: 0;
  color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
}
.controls .controls-button:hover {
  background-image: url(../../../../assets/images/slideshow/toolbar-down28px-yellow.png);
}
.controls .controls-button.prev {
  background-position: -86px 0;
}
.controls .controls-button.next {
  background-position: -172px 0;
}
.controls .controls-button.disabled {
	opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.controls .steps {
  color: white;
}
#app.rtl .controls .controls-button {
  transform: scaleX(-1);
}
</style>
