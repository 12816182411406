<template>
  <div class="d-flex flex-fill">
    <!-- Left column -->
    <div class="ml-0" style="flex: 25%;">
      <div class="card shadow p-1 p-lg-3 h-100">
        <div class="d-flex flex-column justify-content-between" style="flex: 93%; max-height: 93%;">
		      <div class="textpanel d-flex flex-column">
		        <div class="titlewrapper container px-0 mb-4">
		          <div class="row">
		            <div class="code col-auto d-flex justify-content-center align-items-center">
		              <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
		            </div>
		            <div class="title col d-flex flex-column">
		              <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
		              <span style="border-bottom: 1px solid #1b96cf"/>
		            </div>
		          </div>
		        </div>

		        <div v-if="sidebarText.length > 0" class="textwrapper mb-2" style="overflow-y: auto;" v-html="sidebarText[currentStep]"></div>
		      </div>
		    </div>

        <div v-if="sidebarText.length > 1" class="controls d-flex justify-content-center align-items-center position-relative mt-auto" style="height: fit-content;">
          <button
          	class="controls-button begin"
          	:class="{ disabled: currentStep === 0 }"
          	:title="$t('telestia.hover_text.start_again')"
          	@click="currentStep = 0"
          ></button>
          <button
          	class="controls-button prev ml-2"
          	:class="{ disabled: currentStep === 0 }"
          	:title="$t('telestia.hover_text.previous_step')"
          	@click="currentStep = currentStep - 1"
          ></button>
          <button
          	class="controls-button next ml-2"
          	:class="{ disabled: currentStep === sidebarText.length - 1 }"
          	:title="$t('telestia.hover_text.next_step')"
          	@click="currentStep = currentStep + 1"
          ></button>
          <span class="position-absolute steps" style="top: 10px; right: 10px;">{{ currentStep + 1 }}/{{ sidebarText.length }}</span>
        </div>

        <div v-if="figures" class="figures">
          <img v-for="(figure, index) in figures" :alt="`figure-${figure.name}`" :key="index" :src="figure.src"
          />
        </div>
      </div>
    </div>

    <!-- Middle column -->
    <div id="grid-container" class="d-flex justify-content-center align-items-center" style="flex: 50%;">
      <div id="grid-box" class="d-flex flex-column p-1 position-relative" :class="{ 'grid-box-limited': isLayoutLimited }">
        <!-- Loading overlay -->
		    <div v-if="pendingComponentInit" class="loading-overlay" style="opacity: 95%;">
			    <div class="loading-state mx-auto align-self-center">
			      <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
			    </div>
		    </div>

        <div class="d-flex justify-content-between mb-2">
          <img id="xy-nums-1" class="static-asset grid-element" src="../../../../assets/images/slideshow/xy-nums1.png" alt="" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='original'}"/>
          <img id="xy-nums-2" class="static-asset grid-element" src="../../../../assets/images/slideshow/xy-nums2.png"   alt="" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='figure'}"/>
          <img id="height-num" class="static-asset grid-element" src="../../../../assets/images/slideshow/height-num.png" alt="" :style="{opacity: gridOpacity}"/>
          <img id="xy-nums" class="static-asset grid-element" src="../../../../assets/images/slideshow/xy-nums.png" alt="" :style="{opacity: gridOpacity}"/>
        </div>

        <div class="position-relative noselect">
        	<div id="grid-overlay" @mousemove="mousemove" @mouseout="mouseout" @mousedown="mousedown" @mouseup="mouseup"></div>

          <img id="grid" class="grid-image grid-element" src="../../../../assets/images/slideshow/grid.png" alt="grid" :style="{opacity: gridOpacity}"/>

          <!-- Bodies -->
          <img
            v-if="bodies.length === 1"
            id="body"
            class="grid-image position-absolute"
            :src="bodies[0].src"
            alt="body"
            :style="{ ...positions['#body'], opacity: bodyOpacity }"
          />
	        <img
	          v-else-if="bodies.length > 1"
	          :id="`body${side === 'front' ? 1 : 2}`"
	          class="grid-image position-absolute"
	          :src="bodies[`${side === 'front' ? 0 : 1}`].src"
	          :alt="`body${side === 'front' ? 1 : 2}`"
	          :style="{ ...positions[`#body${side === 'front' ? 1 : 2}`], opacity: bodyOpacity }"
	        />

          <!-- Blocks -->
          <img
          	v-if="blocks.length === 1"
          	id="block"
          	class="grid-image grid-element position-absolute"
          	:src="blocks[0].src"
          	alt="block"
          	:style="{ ...positions['#block'], opacity: blockOpacity }"
          />
          <img
          	v-else-if="blocks.length > 1"
          	:id="`block${side === 'front' ? 1 : 2}`"
		        class="grid-image grid-element position-absolute"
		        :src="blocks[`${side === 'front' ? 0 : 1}`].src"
		        :alt="`block${side === 'front' ? 1 : 2}`"
		        :style="{ ...positions[`#block${side === 'front' ? 1 : 2}`], opacity: blockOpacity }"
          />

          <!-- Clothes -->
          <template v-for="cloth in Object.keys(clothes)">
		        <img
		        	:key="cloth"
		        	v-if="clothes[cloth].length === 1"
		        	:id="cloth"
		        	class="grid-image grid-element position-absolute"
		        	:src="clothes[cloth][0].src"
		        	:alt="cloth"
		        	:style="positions[`#${cloth}`]"
		        />
            <img
              :key="cloth"
              v-else-if="clothes[cloth].length > 1"
              :id="`${cloth}${side === 'front' ? 1 : 2}`"
              class="grid-image grid-element position-absolute"
              :src="clothes[cloth][`${side === 'front' ? 0 : 1}`].src"
              :alt="`${cloth}${side === 'front' ? 1 : 2}`"
              :style="positions[`#${cloth}${side === 'front' ? 1 : 2}`]"
            />
		      </template>

					<!-- Scaling numbers -->
          <img id="numbers1-1" class="static-asset grid-element position-absolute" src="../../../../assets/images/slideshow/numbers1-1.png" alt="nums1" style="right: 0; top: 1%;" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='figure'}"/>
          <img id="numbers1-2" class="static-asset grid-element position-absolute" src="../../../../assets/images/slideshow/numbers1-2.png" alt="nums2" style="right: 10%; top: -2%;" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='figure'}"/>

          <img id="numbers2-1" class="static-asset grid-element position-absolute" src="../../../../assets/images/slideshow/numbers2-1.png" alt="nums2-1" style="right: 0; top: 1%;" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='original'}"/>
          <img id="numbers2-2" class="static-asset grid-element position-absolute" src="../../../../assets/images/slideshow/numbers2-2.png" alt="nums2-2" style="right: 10%; top: -2%;" :style="{opacity: gridOpacity}" :class="{'d-none': metrics==='original'}"/>

          <!-- Cursor marker -->
          <div id="cursor-marker" ></div>
        </div>
      </div>
    </div>

    <!-- Right column -->
    <div class="text-center mr-0" style="flex: 25%;">
      <div class="card justify-content-between shadow p-1 p-lg-3 h-100">
        <div class="d-flex flex-column justify-content-between" style="flex: 90%; max-height: 90%; overflow-y: auto;">
          <table id="coordinates-tab-koala" class="coordinates-tab-koala table table-bordered">
            <tr>
              <td class="vars-title" style="width: 30%">X</td>
              <td id="x-axis" class="vars-value">{{ relativeXPosition }}</td>
            </tr>
            <tr>
              <td class="vars-title">Y</td>
              <td id="y-axis" class="vars-value">{{ relativeYPosition }}</td>
            </tr>
            <tr>
              <td class="vars-title">ΔX</td>
              <td id="x-diff" class="vars-value">{{ diff_vector.x }}</td>
            </tr>
            <tr>
              <td class="vars-title">ΔY</td>
              <td id="y-diff" class="vars-value">{{ diff_vector.y }}</td>
            </tr>
            <tr>
              <td class="vars-title">A</td>
              <td id="angle" class="vars-value">{{ angle }}</td>
            </tr>
            <tr>
              <td class="vars-title">D</td>
              <td id="distance" class="vars-value">{{ distance }}</td>
            </tr>
          </table>

          <div id="coordinates-type" class="my-2 d-flex justify-content-center align-items-center" style="font-size: 12px;">
          <span class="mr-3">
            <b-form-radio v-model="metrics" name="coordinates" value="original"><span class="ml-1">{{ $t("telestia.title.original_coordinates") }}</span></b-form-radio>
          </span>
            <span>
            <b-form-radio v-model="metrics" name="coordinates" value="figure"><span class="ml-1">{{ $t("telestia.title.figure_coordinates") }}</span></b-form-radio>
          </span>
          </div>

          <div v-if="secondFigureExists" id="side" class="my-2 d-flex justify-content-center align-items-center" style="font-size: 12px;">
          <span class="mr-3">
            <b-form-radio v-model="side" name="side" value="front"><span class="ml-1">{{ $t("telestia.title.figure_1") }}</span></b-form-radio>
          </span>
            <span>
            <b-form-radio v-model="side" name="side" value="back"><span class="ml-1">{{ $t("telestia.title.figure_2") }}</span></b-form-radio>
          </span>
          </div>

          <div id="sliders" class="my-2 h6 px-1">
            <div class="slider-wrapper d-flex flex-column mb-2">
              <div class="d-flex mb-2">
                <span id="slider-text-grid">Grid</span>
                <span id="slider-perc-grid" class="ml-auto" v-html="gridOpacityPerc"></span>
              </div>
              <input v-model="gridOpacity" type="range" min="0" max="1" step="0.01">
            </div>

            <div class="slider-wrapper d-flex flex-column mb-2">
              <div class="d-flex mb-2">
                <span id="slider-text-body">Body</span>
                <span id="slider-perc-body" class="ml-auto" v-html="bodyOpacityPerc"></span>
              </div>
              <input v-model="bodyOpacity" type="range" min="0" max="1" step="0.01">
            </div>

            <div class="slider-wrapper d-flex flex-column mb-2">
              <div class="d-flex mb-2">
                <span id="slider-text-block">Block</span>
                <span id="slider-perc-block" class="ml-auto" v-html="blockOpacityPerc"></span>
              </div>
              <input v-model="blockOpacity" type="range" min="0" max="1" step="0.01">
            </div>
          </div>

          <div id="measures-wrapper" class="mt-2 p-1 border border-danger">
            <img id="measures" class="" src="../../../../assets/images/slideshow/measures.png" alt="" />
          </div>
        </div>

        <div v-if="selectedLesson && !isOfflineBuild" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
          <div class="d-flex">
            <button	class="action-button px-0 mr-2" :class="{ 'disabled': !packagePdf }" :title="$t('telestia.hover_text.download_lp')" @click="downloadPackagePdf">
              <font-awesome-icon :icon="['fas', 'box']" size="2x" />
            </button>
            <button
              class="action-button px-0"
              :class="{ 'disabled': !selectedLesson.hasAssignment }"
              :title="$t('telestia.hover_text.upload_assignment')"
              @click="$router.push({ name: 'App:Assignments' })"
            >
              <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
            </button>
          </div>

          <mark-as-complete :completed="completed" :lessonid="lessonId" />
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import assets from "../../../../shared/helpers/assets";
import _cloneDeep from "lodash/cloneDeep";
import envConfig from '../../../../shared/configs/config.local.js';
import lessons from "../../../../shared/helpers/lessons";
import MarkAsComplete from "../../shared/MarkAsComplete";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {

  name: 'technical-drawing',

  components: { MarkAsComplete },

  props: [
    'title',
    'assets',
    'figures',

    'selectedLesson',
    'completed'
  ],

  data() {
    return {
    	pendingComponentInit: false,
    	currentStep: 0,

      gridOpacity: 1,
      bodyOpacity: 0.5,
      blockOpacity: 1,

      //decimal points for calculations and presentation
      decimals: 4,
      //calculation metrics
      metrics: 'original',
      current_factor: { scale_X:0, scale_Y:0 },

      side: 'front',

      //custom offsets used for coordinates calibration
      grid_offset_X: 132,
      grid_offset_Y: 0,

      //pointer coordinates
      relativeXPosition: '--',
      relativeYPosition: '--',
      //click coordinates (evaluates on mousedown)
      clickedXPosition: NaN,
      clickedYPosition: NaN,

      //grid overlay div selector
      grid_overlay: null,
      //cursor marker selector
      cursor_marker: null,

      bodies: [],
      blocks: [],
      clothes: {}
    }
  },

  mounted() { this.initComponent(); },

  computed: {
    isOfflineBuild() { return this.$store.getters["app/isOffline"] },

  	isOnlineBuild() { return this.$store.getters["app/isOnline"] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

  	selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

    secondFigureExists() {
      const assets = this.selectedLesson ? this.selectedLesson.html.assets : this.assets;
    	return assets.bodies.length > 1;
    },

    positions() {
    	const assets = this.selectedLesson ? this.selectedLesson.html.assets : this.assets;
    	const positions = _cloneDeep(assets.positions);

    	const gridOffset = {
    		top: positions['#grid'].top ? parseInt(positions['#grid'].top.slice(0, -2)) : 0,
    		left: positions['#grid'].left ? parseInt(positions['#grid'].left.slice(0, -2)) : 0
			}
			Object.keys(positions).forEach(selector => {
				if (positions[selector].top) { positions[selector].top = `${parseInt(positions[selector].top.slice(0, -2)) - gridOffset.top}px`; }
				if (positions[selector].left) { positions[selector].left = `${parseInt(positions[selector].left.slice(0, -2)) - gridOffset.left}px`; }
				if (positions[selector].width) { positions[selector].width = `${parseFloat(positions[selector].width) * 6.10}px` }
				if (positions[selector].height) { positions[selector].height = `${parseFloat(positions[selector].height) * 6.60}px` }
			})
    	return positions;
    },

    selectedProductKey() {
      return this.$store.getters.getSelected.productKey;
    },

    angle() {
      if (isNaN(this.clickedXPosition) || isNaN(this.clickedYPosition)) return '--';
      let rot = this.rotated_vector;
      return (Math.atan2(rot.x, rot.y) * (180 / Math.PI)).toFixed(this.decimals);
    },

    blockOpacityPerc() {
      return parseInt(this.blockOpacity*100)+'%';
    },

    bodyOpacityPerc() {
      return parseInt(this.bodyOpacity*100)+'%';
    },

    diff_vector() {
      if (isNaN(this.clickedXPosition) || isNaN(this.clickedYPosition)) return {x:'--',y:'--'};
      return {
        x: (this.relativeXPosition - this.clickedXPosition).toFixed(this.decimals),
        y: (this.relativeYPosition - this.clickedYPosition).toFixed(this.decimals)
      };
    },

    distance() {
      if (isNaN(this.clickedXPosition) || isNaN(this.clickedYPosition)) return '--';
      let diff = this.diff_vector;
      return (Math.sqrt(Math.pow(diff.x, 2) + Math.pow(diff.y, 2))).toFixed(this.decimals);
    },

    factor() {
      switch (this.metrics) {
        case 'original':
          return {
            scale_X: 40 / this.grid_offset_X,
            scale_Y: 170 / this.grid_overlay.offsetHeight
          };
        case 'figure':
          return {
            scale_X: 6 / this.grid_offset_X,
            scale_Y: 25.5 / this.grid_overlay.offsetHeight
          };
        default:
          return { scale_X: 0, scale_Y: 0 };
      }
    },

    // figure coordinates factor (from radio buttons)
    figure_factor() {
      return {
        scale_X: 6 / this.grid_offset_X,
        scale_Y: 25.5 / this.grid_overlay.offsetHeight
      };
    },

    gridOpacityPerc() {
      return parseInt(this.gridOpacity*100)+'%';
    },

    //original coordinates factor (from radio buttons)
    original_factor() {
      return {
        scale_X: 40 / this.grid_offset_X,
        scale_Y: 170 / this.grid_overlay.offsetHeight
      };
    },

    rotated_vector() {
      if (isNaN(this.clickedXPosition) || isNaN(this.clickedYPosition)) return {x:'--',y:'--'};
      let theta = 90 * (Math.PI / 180); // convert degrees to radians
      let diff = this.diff_vector;
      return {
        x: diff.x * Math.cos(theta) - diff.y * Math.sin(theta),
        y: diff.x * Math.sin(theta) + diff.y * Math.cos(theta)
      };
    },

    packagePdf() { return this.$store.getters.getPackagePdf(this.selectedLesson.key); },

    lessonCode() {
      const titleObj = (this.selectedLesson) ? this.selectedLesson.title : this.title;

      const title = (titleObj[this.selectedLanguage] || titleObj.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const titleObj = (this.selectedLesson) ? this.selectedLesson.title : this.title;

      const title = (titleObj[this.selectedLanguage] || titleObj.en).trim();
      return lessons.extractLessonTitle(title);
    },

    sidebarText() {
    	if (!this.selectedLesson) { return []; }

    	const texts = this.selectedLesson.html.sideBarText[this.selectedLanguage]
    		? this.selectedLesson.html.sideBarText[this.selectedLanguage]
    		: this.selectedLesson.html.sideBarText.en;
    	return texts ? texts : [];
    }

  },

  methods: {
    async initComponent() {
      try {
        this.grid_overlay   = document.getElementById('grid-overlay');
        this.cursor_marker  = document.getElementById('cursor-marker');

      	this.pendingComponentInit = true;

        const assets = this.selectedLesson ? this.selectedLesson.html.assets : this.assets;
        this.bodies = assets.bodies;
        this.blocks = assets.blocks;
        const clothPieces = ['blouses', 'collars', 'skirts', 'sleeves'];
        this.clothes = {};
        clothPieces.forEach(p => { if (assets[p].length > 0) { this.clothes[p.slice(0, -1)] = assets[p]; } });

        await this.fetchImages();
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.assets') + "\n" + this.$t('telestia.error.connection'),
        });
      } finally {
      	this.pendingComponentInit = false;
      }
    },

    async fetchImages() {
      try {
      	const product = this.selectedProductKey.toUpperCase();
        if (!this.isOnlineBuild) {
          this.bodies.forEach(body => body.src = this.getImageBase64(product, body.name))
          this.blocks.forEach(block => block.src = this.getImageBase64(product, block.name))
          Object.keys(this.clothes).forEach(p => {
            this.clothes[p].forEach(cloth => cloth.src = this.getImageBase64(product, cloth.name))
          })
          return Promise.resolve();
        }

        const requests = [];
        this.bodies.forEach(body => requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: body.name })))
        this.blocks.forEach(block => requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: block.name })))
        Object.keys(this.clothes).forEach(p => {
          this.clothes[p].forEach(cloth => requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: cloth.name })))
        })
        const results = await Promise.allSettled(requests);

        let index = 0;
        this.bodies.forEach(body => {
          if (results[index].status !== 'rejected') { body.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { body.src = this.getImageBase64(''); }
          index++;
        })
        this.blocks.forEach(block => {
          if (results[index].status !== 'rejected') { block.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { block.src = this.getImageBase64(''); }
          index++;
        })
        Object.keys(this.clothes).forEach(p => {
          this.clothes[p].forEach(cloth => {
            if (results[index].status !== 'rejected') { cloth.src = results[index].value ? results[index].value : this.getImageBase64(''); }
            else { cloth.src = this.getImageBase64(''); }
            index++;
          })
        })

        // Error handling
        if (results.some(r => r.status === 'rejected')) {
          this.showErrorAlert({
            title: this.$t('telestia.error.title'),
            text: this.$t('telestia.error.assets') + "\n" + this.$t('telestia.error.connection'),
          });
        }

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    getImageBase64(folder, imageName) {
		  try {
				return this.$store.getters.getImageData({ file: `${folder.toUpperCase()}/${imageName}`, lang: 'en' })
			} catch (e) {
				return '';
		  }
      // return assets.getImageData(folder, imageName);
    },

    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    mousedown(e) {
    	let current_factor = this.factor;
      let cursor_markerWidth = this.cursor_marker.offsetWidth;

      let o = this.grid_overlay.getBoundingClientRect();
      this.clickedXPosition = ((e.pageX - (o.left + scrollX) - this.grid_offset_X) * current_factor.scale_X).toFixed(this.decimals);
      this.clickedYPosition = ((e.pageY - (o.top + scrollY) - this.grid_offset_Y) * current_factor.scale_Y).toFixed(this.decimals);

      // set marker coordinates
      o = this.grid_overlay.getBoundingClientRect();
      this.cursor_marker.style.left = e.pageX - (o.left + scrollX) + 'px';
      this.cursor_marker.style.top = (e.pageY - (o.top + scrollY) - (cursor_markerWidth / 2)) + 'px';

      // show marker
      this.cursor_marker.style.opacity = 1;
    },

    mousemove(e) {
      let current_factor = this.factor;
      let o = this.grid_overlay.getBoundingClientRect();

      this.relativeXPosition = ((e.pageX - (o.left + scrollX) - this.grid_offset_X) * current_factor.scale_X).toFixed(this.decimals);
      this.relativeYPosition = ((e.pageY - (o.top + scrollY) - this.grid_offset_Y) * current_factor.scale_Y).toFixed(this.decimals);
    },

    mouseout() {
    	//reset all
      this.relativeXPosition = '--';
      this.relativeYPosition = '--';

      //reset click position
      this.clickedXPosition = NaN;
      this.clickedYPosition = NaN;
      this.cursor_marker.style.opacity = 0;
    },

    mouseup() {
    	//reset click position
      this.clickedXPosition = NaN;
      this.clickedYPosition = NaN;
      this.cursor_marker.style.opacity = 0;
    },

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  }
};
</script>

<style scoped>

.textpanel {
  overflow-y: auto;
  overflow-x: hidden;
}
div#grid-container {
  background-repeat: no-repeat;
  background-color: #F5F2F0;
  color: #3A4145;
  font-size: 1em;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#grid-overlay {
  width: 292px;
  height: 547px;
  z-index: 999;
  position: absolute;
}
#grid-overlay:hover {
  cursor: crosshair;
}

#cursor-marker {
  position: absolute;
  width: 18px;
  height: 18px;
  opacity: 0;
}
#cursor-marker:before, #cursor-marker:after {
  position: absolute;
  content: ' ';
  height: 18px;
  width: 2px;
  background-color: #F44336;
}
#cursor-marker:after {
  transform: rotate(90deg);
}

.static-asset {
	object-fit: none;
}

.grid-box-limited {
  transform: scale(0.5);
}
</style>
