<template>
	<div v-if="visible">
		<b-button id="btnNetworkStatusInfo" variant="danger" size="sm">
			<font-awesome-icon size="lg" :icon="['fas','wifi']"/> {{ $t('telestia.network.offline_warning') }}
			<b-badge variant="light">
				<font-awesome-icon size="lg" :icon="['fas','info']"/>
			</b-badge>
		</b-button>
		<b-tooltip target="btnNetworkStatusInfo" variant="light">
			<span v-html="tooltipMessage" v-on:click="goToTodaysClass"></span>
		</b-tooltip>
	</div>
</template>

<script>
export default {
	name: 'networkstatus',

	data() {
		return {
		};
	},

	computed: {
		visible() {
			return this.$store.getters['app/isHybrid'] && this.$store.getters['network/isNetworkOffline'];
		},

		tooltipMessage() {
			return this.$t('telestia.network.offline_msg', {
				todaysclass: `<a href="javascript:;" class="todaysclass">${this.$t('telestia.navigation.todays_class')}</a>`,
			});
		},
	},

	mounted() {
	},

	methods: {
		goToTodaysClass(e) {
			if (e.target.className=='todaysclass') {
				this.$router.push({ name: 'App:Progress' });
			}
		},
	},
}
</script>

<style>
.b-tooltip-light .tooltip-inner {
	box-shadow: 0px 0px 5px black;
}
.b-tooltip a {
	color: black;
	font-weight: bold;
}
</style>
