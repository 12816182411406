<template>
  <div id="lesson-wrapper" ref="lessonWrapper" class="d-flex position-relative" :style="{ 'background-image': backgroundImg }">
    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state mx-auto align-self-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>
      <div v-if="!isContentValid" class="flex-fill d-flex justify-content-center align-items-center" style="background-color: #F2F2F2">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
        <span class="ml-3" style="font-size: 20px">{{ $t("telestia.message.invalid_content") }}</span>
      </div>

      <template v-else>
        <!-- Lesson -->
        <new-video v-if="lessonContent.type === 'newVideo'" class="p-3" :selectedLesson="lessonContent" :mainLesson="mainLesson" :completed="lessonCompleted" width="600" />
        <main-lesson-front-page
            v-if="lessonContent.type === 'html' && lessonContent.html.contentCategory === 1"
            class="p-3"
            :selectedLesson="lessonContent"
            :completed="lessonCompleted"
            @update-selected-lesson="selectLesson"
        />
        <ruler
            v-if="lessonContent.type === 'html'  && lessonContent.html.contentCategory === 3"
            class="p-3"
            :selectedLesson="lessonContent"
            :mainLesson="mainLesson"
            :completed="lessonCompleted"
            @show-voiceover="showVoiceoverVideo"
        />
        <technical-drawing v-if="lessonContent.type === 'html' && lessonContent.html.contentCategory === 4" :selectedLesson="lessonContent" :completed="lessonCompleted" />
        <test
            v-if="lessonContent.type === 'html' && lessonContent.html.contentCategory === 6"
            class="p-3"
            :selectedLesson="lessonContent"
            :completed="lessonCompleted"
            @update-selected-lesson="selectLesson"
        />
        <app-video v-if="lessonContent.type === 'video'" class="p-3" :selectedLesson="lessonContent" :mainLesson="mainLesson" :completed="lessonCompleted" />
        <slideshow
            v-if="lessonContent.type === 'slideShow' || (lessonContent.type === 'html' && lessonContent.html.contentCategory === 2)"
            class="p-3"
            :selectedLesson="lessonContent"
            :mainLesson="mainLesson"
            :completed="lessonCompleted"
            @show-voiceover="showVoiceoverVideo"
            @update-selected-lesson="selectLesson"
        />

        <!-- Tutor video -->
        <div v-if="showVoiceover" class="voiceover-wrapper position-absolute" :class="{paused : this.videoPaused}">
          <video
              ref="voiceoverVideo"
              :src="voiceoverSrc"
              preload="metadata"
              disablepictureinpicture
              controlsList="nodownload nofullscreen noplaybackrate"
              class="voiceover"
              oncontextmenu="return false;"
              @click="videoControls" @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused">
          ></video>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import assets from "../../shared/helpers/assets";
import { sleep } from "../../shared/utils/generic";

import NewVideo from './types/NewVideo/NewVideo'
import MainLessonFrontPage from './types/MainLessonFrontPage/MainLessonFrontPage'
import Ruler from './types/Ruler/Ruler'
import TechnicalDrawing from "./types/TechnicalDrawing/TechnicalDrawing.vue";
import Test from "./types/Test/Test.vue";
import AppVideo from "./types/Video/Video"
import Slideshow from "./types/Slideshow/Slideshow.vue"
import _isEmpty from "lodash/isEmpty";
import envConfig from '../../shared/configs/config.local.js';
import { makeDraggable } from "../../shared/utils/draggable";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {

  components: {
    MainLessonFrontPage,
    NewVideo,
    Ruler,
    Slideshow,
    TechnicalDrawing,
    Test,
    AppVideo
  },

  props: {
    lesson: {
      type: Object,
      required: true
    }
  },

  mounted() {
    this.initComponent();
  },

  data() {
    return {
      pendingWrapperAdjustment: false,
      pendingLessonContentFetch: false,

      lessonContent: {},

      showVoiceover: false,
      voiceoverSrc: '',
      videoPaused: false,

      mainLesson: null,
      lessonCompleted: false
    }
  },

  computed: {
    pendingComponentInit() { return this.pendingWrapperAdjustment || this.pendingLessonContentFetch; },

    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isContentValid() {
      switch (this.lessonContent.type) {
        case 'html':
          return !!this.lessonContent.html;
        case 'newVideo':
          return !!this.lessonContent.newVideo;
        case 'video':
          return !!this.lessonContent.video;
        default:
          return !!this.lessonContent.slideShow;
      }
    },
    
    previouslyViewedLesson() { return this.$store.getters.getPreviouslyViewedLesson; },

    backgroundImg() {
      switch (this.lessonContent.folder) {
        case 'PM':
          return `url(${require('../../assets/images/backgrounds/PM.jpeg')})`
        case 'SE':
          return `url(${require('../../assets/images/backgrounds/SE.jpeg')})`
        case 'FD':
          return `url(${require('../../assets/images/backgrounds/FD.jpeg')})`
        case 'FE':
          return `url(${require('../../assets/images/backgrounds/FE.jpeg')})`
        default:
          return `url(${require('../../assets/images/backgrounds/PM.jpeg')})`
      }
    }
  },

  methods: {
    async initComponent() {
      try {
        this.pendingWrapperAdjustment = true;
        await sleep(100);

        this.setWrapperHeight();
      } catch (e) {
        console.log(e)
      } finally {
        this.pendingWrapperAdjustment = false;
        setTimeout(() => { if (this.$refs.voiceoverVideo) { makeDraggable(this.$refs.voiceoverVideo, true); } }, 1000);
      }
    },

    updatePaused(e) {
      this.videoPaused = e.target.paused;
    },

    videoControls() {
      if (this.videoPaused) {
        this.$refs.voiceoverVideo.play();
      } else {
        this.$refs.voiceoverVideo.pause();
      }
    },

    // Set height of lesson wrapper viewer
    setWrapperHeight() {
      const viewportHeight = document.documentElement.clientHeight;
      // const appNavHeight = document.getElementById('app-navigation').clientHeight;
      const lessonNavHeight = document.getElementById('lesson-navigation').clientHeight;
      // const appFooterHeight = document.getElementById('app-footer').clientHeight;

      // const lessonWrapperHeight = viewportHeight - (appNavHeight + lessonNavHeight + appFooterHeight) + 'px'
      const lessonWrapperHeight = viewportHeight - lessonNavHeight + 'px'
      this.$refs.lessonWrapper.style.height = lessonWrapperHeight;
    },

    // Helper function that sets voiceover videos visible and updates its source
    showVoiceoverVideo(src) {
      this.showVoiceover = !!src;
      if (!this.showVoiceover) { return; }
      this.voiceoverSrc = (this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.lessonContent.folder}&title=${src}&language=${this.$store.getters["user/getSelectedLanguage"]}`
        : this.getFileURL(this.lessonContent.folder, src)) + '#t=0.1'
    },

    // Select lesson
    selectLesson({ key: lessonKey, enabled }) {
      // To select a lesson, we first check if it is enabled
      if (enabled) { 
      	this.selectMenu({ lessonKey });
      	this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(lessonKey) } });
      }
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    // Update content and progress entries
    async onLessonUpdate() {
      try {
        // Hide voiceover video, if previously visible
        this.showVoiceover = false;

        // Fetch lesson content
        await this.updateLessonContent();

        // Register user progress
        this.registerUserLessonProgress();

        // Get info on whether the previously viewed lesson is a main lesson
        if (this.previouslyViewedLesson.isMainLesson) { this.mainLesson = this.getMainLessonData(this.previouslyViewedLesson); }
        else { this.mainLesson = null; }
      } catch (e) {
        console.log(e);
      }
    },

    // Update lesson content when in online mode
    async updateLessonContent() {
      try {
        this.pendingLessonContentFetch = true;

        let lessonContent = this.lesson;
        if (this.isOnlineBuild) {
          const lesson = await this.$store.dispatch("lessons/fetchLessonContent", { productKey: this.lesson.folder, lessonKey: this.lesson.key });
          lessonContent = lesson.props;
        }
        this.lessonContent = lessonContent;

        return Promise.resolve();
      } catch (e) {
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.lesson_content') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error'
        });
        return Promise.reject(e);
      } finally {
        this.pendingLessonContentFetch = false;
      }
    },

    // Update user lesson progress
    async registerUserLessonProgress() {
      if (this.isOfflineBuild) { return; }

      // Add lesson session
      try {
        await this.$store.dispatch('progress/addLessonSession', this.lesson.dbid);
      } catch (e) {
        console.log(e);
      }

      // Fetch lesson progress
      try {
        const progress = await this.$store.dispatch("progress/fetchProgressByLessonId", this.lesson.dbid);
        this.lessonCompleted = !!progress.completed;
      } catch (e) {
        this.lessonCompleted = false;
      }
    },

    // If current lesson is a sublesson, get menu info of its main lesson
    getMainLessonData(previous) {
      const mainLesson = this.$store.getters.getCurrentlyViewingLesson;
      mainLesson.code = previous.key;
      mainLesson.hasAssignment = previous.hasAssignment;
      return mainLesson;
    },

    getFileURL(folder, videoName) { return assets.getFileURL(folder, videoName); },

    showAlert({ title, text, icon }) {
      this.$swal({ title, text, icon, timer: 5000, button: true });
    }
  },

  watch: {
    lesson: {
      immediate: true,
      async handler(n, o) {
        if (_isEmpty(n) || o === n) { return; }

        await this.onLessonUpdate();
      }
    },
    
    previouslyViewedLesson(n, o) {
      if (!n || _isEmpty(n)) { return; }
      
      this.mainLesson = this.getMainLessonData(n);
    },

    $route: {
      async handler(n, o) {
        // Move out of lesson
        if (n.name === 'App:Lessons' && o.name === 'App:Lessons:Lesson') { 
      	  const selected = this.$store.getters.getSelected;
      	  if (selected.productKey.toLowerCase() !== n.params.product_key || selected.course.toLowerCase() !== n.params.course_key) { 
      	    this.$store.commit('selectProductCourse', { productKey: n.params.product_key, courseKey: n.params.course_key.toUpperCase() }); 
      	  
      	    // In case of online build, fetch product skeleton from server
            if (this.isOnlineBuild) { await this.$store.dispatch("products/fetchProductSkeleton", n.params.product_key); }
      	  }
	  if (selected.lessonKey) { this.selectMenu({ lessonKey: null }); }
        }		

        // Move back a lesson
        if (n.name === o.name) { 
          const selected = this.$store.getters.getSelected;
      	  const lessonKey = this.$store.getters.getLessonCode(n.params.lesson_id);
      	  if (selected.lessonKey !== lessonKey) { this.selectMenu({ lessonKey }); }
        }
      }
    }
  }

}
</script>

<style>
/*----------------------------------------------------------------------------------------------*/
/* GENERAL */

.flex-90 { flex: 90%; max-height: 90%; }
.flex-80 { flex: 80%; max-height: 80%; }

/*----------------------------------------------------------------------------------------------*/
/* LESSON WRAPPER */

#lesson-wrapper {
  font-family: 'Lato', sans-serif !important;
  font-size: 1.1rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


/*----------------------------------------------------------------------------------------------*/
/* VOICEOVER VIDEO */

.voiceover-wrapper {
  max-width: 200px;
  bottom: calc(10% - 10px);
}
#app:not(.rtl) .voiceover-wrapper { right: calc(30% - 210px); }
#app.rtl .voiceover-wrapper { left: calc(30% - 210px); }

video.voiceover::-webkit-media-controls-fullscreen-button,
video.voiceover::-webkit-media-controls-volume-control-container {
  display: none !important;
}

.voiceover {
  width: 100%;
}
.voiceover-wrapper {
  width: 200px;
  height: 112px;
}
.voiceover-wrapper::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 40px;
  height: 40px;
  pointer-events: none;
  font-size: 26px;
  color:white;
  border-radius: 3px;
  line-height: 38px;
  text-align: center;
  background-color: rgba(0,0,0,0.5);
  background-image: url(../../assets/images/new-video/toolbar-down28px-white.png);
  background-size: 480px;
  background-repeat: no-repeat;
  display: none;
}
.voiceover-wrapper:hover::after {
  display: block;
}
.voiceover-wrapper.paused:hover::after {
  background-position: -378px 7px;
}
.voiceover-wrapper:not(.paused):hover::after {
  background-position: -273px 9px;
  background-size: 420px;
}


/*----------------------------------------------------------------------------------------------*/
/* LESSON ACTION BUTTONS */

.complete-lesson-button {
  height: fit-content;
  font-weight: bold;
  color: white;
  background-color: #4c68b0;
  border: none;
  border-radius: 10px;
}
.complete-lesson-button:hover { background-color: #8464bc; }
.check-mark {
  position: absolute;
  top: -20px;
  left: 2px;
  font-size: 40px;
  font-weight: lighter;
}
.completed-lesson-sign {
  height: fit-content;
  font-weight: bold;
  color: white;
  background-color: #2ad875;
  border-radius: 10px;
}
.action-button {
  color: #4c68b0;
  background-color: transparent;
  border: none;
  outline: 0;
}
.action-button:hover {
  color: #8464bc;
}
</style>
