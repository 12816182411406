function makeDraggable (element, mobileOnly = false) {

  const data = {};
  data.position = { x: 0, y: 0 };
  data.offset = [0, 0];
  data.isMoving = false;

  const startEvents = mobileOnly ? ['touchstart'] : ['mousedown', 'touchstart'];
  const moveEvents = mobileOnly ? ['touchmove'] : ['mousemove', 'touchmove'];
  const endEvents = mobileOnly ? ['touchend'] : ['mouseup', 'touchend'];

  startEvents.forEach(evt => element.addEventListener(evt, pickup, true));
  moveEvents.forEach(evt => element.addEventListener(evt, move, true));
  endEvents.forEach(evt => element.addEventListener(evt, drop, true));

  function pickup(e) {
    data.isMoving = true;
    if (e.clientX) { data.offset = [element.offsetLeft - e.clientX, element.offsetTop - e.clientY]; }
    else if (e.touches) { data.offset = [element.offsetLeft - e.touches[0].pageX, element.offsetTop - e.touches[0].pageY]; }
  }

  function move(e) {
    if (data.isMoving) {
      if (e.clientX) { data.position = {x : e.clientX, y : e.clientY}; }
      else if (e.touches) { data.position = {x : e.touches[0].pageX, y : e.touches[0].pageY}; }
      element.style.left = (data.position.x + data.offset[0]) + 'px';
      element.style.top  = (data.position.y + data.offset[1]) + 'px';
    }
  }

  function drop(e) {
    data.isMoving = false;
    element.style.left = (data.position.x + data.offset[0]) + 'px';
    element.style.top  = (data.position.y + data.offset[1]) + 'px';
  }
}

export { makeDraggable };
