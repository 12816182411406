<template>
  <div class="wrapper d-flex flex-fill" style="background-color: #F2F2F2;">
    <!-- Left column -->
    <div v-if="!isLayoutLimited" class="left-column d-flex flex-column" style="flex: 5%">
      <div class="logo"><img src="../../../../assets/images/logo-watermark.png" alt="logo-watermark" class="mw-100 h-auto"/></div>
    </div>

    <!-- Middle column -->
    <div class="middle-column d-flex flex-column" style="flex: 65%; max-height: 100%">
      <div ref="contentArea" class="mlfp-content-area bg-white" :class="{ 'mb-2': pages.length > 1 }" style="flex: 100%;" :style="{ 'max-height': `${contentAreaHeightPerc}%` }">
        <!-- Loading state -->
        <div v-if="pendingComponentInit" class="d-flex justify-content-center align-items-center w-100 h-100">
          <div class="loading-state mx-auto align-self-center">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
          </div>
        </div>

        <!-- Loaded state -->
        <template v-else>
          <template v-if="emptyContent">
            <div class="page d-flex justify-content-center align-items-center h-100 w-100">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
              <span class="ml-3" style="font-size: 20px">{{ $t("telestia.message.missing_content") }}</span>
            </div>
          </template>

          <template v-else>
            <!-- Lessons with text links -->
            <div v-if="!currentPage || !currentPage.images || currentPage.images.length === 0" class="d-flex flex-column justify-content-center align-items-center h-100">
              <div
                v-for="(link, index) in subLessons"
                :key="`text-link-${index}`"
                class="w-25 text-center bg-blue text-white p-2 mb-2 rounded mouse-pointer"
                v-html="link.text || link.caption"
                @click="selectLesson(link.subLessonId)"
              ></div>
            </div>

            <!-- Lessons with images -->
            <div
              v-else-if="currentPage"
              v-for="rIndex in currentPage.gridAreaSize[0]"
              :key="`row-${rIndex}`"
              class="row m-0"
              :class="{ 'h-100': currentPage.gridAreaSize[0] === 1}"
              :style="{height: `${100/currentPage.gridAreaSize[0]}%`}"
            >
              <div v-for="cIndex in currentPage.gridAreaSize[1]" :key="`col-${rIndex}-${cIndex}`" class="col d-flex flex-column justify-content-center align-items-center border">
                <template v-if="subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)]">
                  <h6 class="m-0" v-if="subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)].caption" v-html="subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)].caption"></h6>
                  <img
                    :src="currentPage.images[sublessonIndexBasedOnGridArea(rIndex, cIndex)].src"
                    :height="currentPage.images[sublessonIndexBasedOnGridArea(rIndex, cIndex)].height"
                    :width="currentPage.images[sublessonIndexBasedOnGridArea(rIndex, cIndex)].width"
                    :alt="currentPage.images[sublessonIndexBasedOnGridArea(rIndex, cIndex)].name"
                    :class="{ 'sublesson-image': subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)].subLessonId, 'unclickable': !subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)].subLessonId }"
                    style="max-width: 100%;"
                    @mouseenter="showHover($event, subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)])"
                    @mouseleave="hoverText.show = false"
                    @click="selectLesson(subLessons[sublessonIndexBasedOnGridArea(rIndex, cIndex)].subLessonId)"
                  />
                </template>
              </div>
            </div>

            <!-- Hover text container -->
            <div
              v-if="hoverText.show && hoverText.value"
              class="hover-text-container shadow"
              style="tab-index: -1; pointer-events: none;"
              v-html="hoverText.value"
              :style="hoverText.position"
            ></div>
          </template>
        </template>
      </div>

      <!-- Controls container -->
      <div v-if="numOfPages > 1" class="controls-wrapper d-flex justify-content-center align-items-center w-100" style="flex: 8%">
        <div class="controls d-flex  justify-content-center align-items-center position-relative w-75" style="height: fit-content;">
          <button
            class="controls-button begin"
            :class="{ disabled: currentPageIndex === 0 }"
            :title="$t('telestia.hover_text.start_again')" @click="onPageIndexUpdate(0)"
          ></button>
          <button
            class="controls-button prev ml-2"
            :class="{ disabled: currentPageIndex === 0 }"
            :title="$t('telestia.hover_text.previous_step')"
            @click="onPageIndexUpdate(currentPageIndex - 1)"
          ></button>
          <button
            class="controls-button next ml-2"
            :class="{ disabled: currentPageIndex === numOfPages - 1 }"
            :title="$t('telestia.hover_text.next_step')"
            @click="onPageIndexUpdate(currentPageIndex + 1)"
          ></button>
          <span class="position-absolute steps" style="top: 10px; right: 10px;">{{ currentPageIndex + 1 }}/{{ numOfPages }}</span>
        </div>
      </div>
    </div>

    <!-- Right column -->
    <div class="right-column d-flex flex-column pl-3" style="flex: 30%; max-height: 100%;">
      <div class="d-flex flex-column justify-content-between" style="flex: 90%; max-height: 90%;">
        <div class="textpanel d-flex flex-column">
          <div class="titlewrapper container px-0 mb-4">
            <div class="row">
              <div class="code col-auto d-flex justify-content-center align-items-center">
                <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
              </div>
              <div class="title col d-flex flex-column">
                <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
                <span style="border-bottom: 1px solid #1b96cf"/>
              </div>
            </div>
          </div>

          <div class="textwrapper mb-2" v-html="sidebarText" style="overflow-y: auto;"></div>
        </div>

        <div v-if="!isLayoutLimited" class="figures">
          <img v-for="(figure, index) in pageFigures" :alt="`figure-${figure.name}`" :key="index" :src="figure.src"/>
        </div>
      </div>

      <div v-if="!isOfflineBuild && !emptyContent" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
        <div class="d-flex">
          <button
            class="action-button px-0 mr-2"
            :class="{ 'disabled': !packagePdf }"
            :title="$t('telestia.hover_text.download_lp')"
            @click="downloadPackagePdf"
          >
            <font-awesome-icon :icon="['fas', 'box']" size="2x" />
          </button>
          <button
            class="action-button px-0"
            :class="{ 'disabled': !selectedLesson.hasAssignment }"
            :title="$t('telestia.hover_text.upload_assignment')"
            @click="$router.push({ name: 'App:Assignments' })"
          >
            <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
          </button>
        </div>

        <mark-as-complete :completed="completed" :lessonid="lessonId" />
      </div>
    </div>
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy';
import _isEqual from 'lodash/isEqual';
import assets from '../../../../shared/helpers/assets';
import lessons from '../../../../shared/helpers/lessons';
import envConfig from '../../../../shared/configs/config.local';
import MarkAsComplete from '../../shared/MarkAsComplete.vue';

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  components: {
    MarkAsComplete
  },

  name: 'main-lesson-html',

  props: [ 'selectedLesson', 'completed' ],

  mounted() { this.$store.commit('setPreviousLessonPageNumProp', null); this._initComponent(); },

  data() {
    return {
      pendingComponentInit: false,

      gridAreaSize: [0, 0],
      images: [],
      hoverText: { show: false, image: '', value: '', position: { top: '', left: '' } },

      pages: [],
      figures: [],
      currentPageIndex: this.$store.getters.getPreviouslyViewedLesson.mainLessonPageNum || 0
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

    contentAreaHeightPerc() { return this.translatedContent && this.translatedContent.pages && this.translatedContent.pages.length > 1 ? 92 : 100; },

    translatedContent() {
      const translatedContent = this.selectedLesson.html.languages[this.selectedLanguage];
      if (translatedContent) { return translatedContent; }
      return this.selectedLesson.html.languages.en;
    },

    numOfPages() { return this.translatedContent && this.translatedContent.pages ? this.translatedContent.pages.length : 0; },

    emptyContent() {
      return !this.translatedContent || this.translatedContent.pages.every(p => !p.subLessons || p.subLessons.length === 0);
    },

    currentPage() {
      return this.pages[this.currentPageIndex];
    },

    subLessons() {
      return this.translatedContent.pages[this.currentPageIndex].subLessons;
    },

    sidebarText() {
      if (this.emptyContent) { return ''; }

      const sidebarText = this.translatedContent.pages[this.currentPageIndex].sideBarText;
      if (!sidebarText) { return ''; }
      return sidebarText;
    },

    pageFigures() {
      const pageFigures = [];
      if (this.figures[this.currentPageIndex * 2]) { pageFigures.push(this.figures[this.currentPageIndex * 2]); }
      if (this.figures[this.currentPageIndex * 2 + 1]) { pageFigures.push(this.figures[this.currentPageIndex * 2 + 1]); }
      return pageFigures;
    },

    packagePdf() { return this.$store.getters.getPackagePdf(this.selectedLesson.key); },

    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },
  },

  methods: {
    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    getImageBase64(folder, imageName) {
      return assets.getImageData(folder, imageName);
    },

    sublessonIndexBasedOnGridArea(rIndex, cIndex) {
      if (!this.currentPage) { return 0; }
      return rIndex + cIndex - 2  + (this.currentPage.gridAreaSize[1] - 1) * (rIndex - 1);
    },

    showHover(e, imageData) {
      if (imageData.imageName === this.hoverText.image) {
        this.hoverText.show = true;
        return;
      }

      const navMenuHeight = document.getElementById('lesson-navigation').clientHeight;
      this.hoverText = {
        show: true,
        image: imageData.imageName,
        value: imageData.hover, position: { top: (e.pageY - navMenuHeight) + 'px', left: e.pageX + 'px' }
      }
    },

    selectLesson(sublessonId) {
      if (!sublessonId) { return; }

      // save current page number in vuex state
      this.$store.commit('setPreviousLessonPageNumProp', this.currentPageIndex);

      const selectedSublessonCode = this.$store.getters.getLessonCode(sublessonId);
      this.$emit("update-selected-lesson", { key: selectedSublessonCode, enabled: true });
    },

    getSublessonImageDimensions(imgSrc) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => { resolve({ height: image.naturalHeight, width: image.naturalWidth }); }
        image.src = imgSrc;
      })
    },

    async _initComponent(initiatedByWatcher = false) {
      if (this.pendingComponentInit) { return; }

      try {
        this.pendingComponentInit = true;

        if (!this.emptyContent) {
          await this._fetchFiguresImages();
          await this._generateContentImages(initiatedByWatcher ? 0 : this.currentPageIndex);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    },

    async _fetchFiguresImages() {
      try {
        const figures = this.selectedLesson.html.figures.map(f => { return { name: f }; });
        this.figures = await this._fetchImageSources(figures);

        return Promise.resolve();
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.figures') + "\n" + this.$t('telestia.error.connection'),
        });
        return Promise.resolve();
      }
    },

    async onPageIndexUpdate(newIndex) {
      try {
        this.pendingComponentInit = true;

        this.currentPageIndex = newIndex;

        const page = this.translatedContent.pages[newIndex];
        const images = page.subLessons.filter(sl => sl.imageName).map(sl => sl.imageName);
        const stateImages = this.pages[newIndex] && this.pages[newIndex].images ? this.pages[newIndex].images.map(i => i.name) : [];
        if (!_isEqual(images, stateImages)) { await this._generateContentImages(newIndex) }
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    },

    async _generateContentImages(pageIndex) {
      try {
        if (!this.$refs.contentArea) { return; }

        let contentAreaHeight = this.$refs.contentArea.clientHeight;
        if (this.translatedContent.pages.length > 1) { contentAreaHeight = contentAreaHeight * 0.92; }
        const contentAreaWidth = this.$refs.contentArea.clientWidth;

        const page = this.translatedContent.pages[pageIndex];
        let images = page.subLessons.filter(sl => sl.imageName).map(sl => { return { name: sl.imageName, src: '', height: 0, width: 0 } } );

        // Current page contains just text links for navigation to sublessons
        if (images.length === 0) {
          if (this.pages[pageIndex]) {
            this.pages[pageIndex] = {};
          } else {
            const endIndex = this.pages.length === pageIndex ? 0 : pageIndex;
            for (let i = 0; i <= endIndex; i++) { this.pages.push({}); }
          }
          return;
        }

        // Calculate rows & columns
        const gridAreaSize = [0, 0];
        if (images.length < 4) {
          gridAreaSize[0] = 1;
          gridAreaSize[1] = images.length;
        } else if (images.length < 19) {
          gridAreaSize[1] = Math.ceil(images.length / 2);
          gridAreaSize[0] = Math.ceil(images.length / gridAreaSize[1]);
        } else {
          gridAreaSize[0] = 3;
          gridAreaSize[1] = 9;
        }

        images = await this._fetchImageSources(images);

        // Get image heights
        for (let img of images) {
          const imgDimensions = await this.getSublessonImageDimensions(img.src);
          img.height = imgDimensions.height;
          img.width = imgDimensions.width;
        }

        // Highest image - max row height
        const highestImageHeight = _sortBy(images, 'height').slice(-1)[0].height;
        const contentAreaRowMaxHeight = contentAreaHeight / gridAreaSize[0];

        // Reduce image heights and calc image widths based on their ratio if necessary
        if (highestImageHeight > contentAreaRowMaxHeight) {
          images.forEach(img => {
            if (img.height > contentAreaRowMaxHeight) {
              const imgRatio = img.width / img.height;
              img.height = contentAreaRowMaxHeight;
              img.width = img.height * imgRatio;
            }
          })
        }

        // Widest image - max column width
        const widestImageWidth = _sortBy(images, 'width').slice(-1)[0].width;
        const contentAreaColMaxWidth = contentAreaWidth / gridAreaSize[1];

        // Reduce image heights and calc image widths based on their ratio if necessary
        if (widestImageWidth > contentAreaColMaxWidth) {
          images.forEach(img => {
            if (img.width > contentAreaColMaxWidth) {
              const imgRatio = img.height / img.width;
              img.width = contentAreaColMaxWidth;
              img.height = img.width * imgRatio;
            }
          })
        }

        if (this.pages[pageIndex]) {
          this.pages[pageIndex].gridAreaSize = gridAreaSize;
          this.pages[pageIndex].images = images;
        } else {
          const endIndex = this.pages.length === pageIndex ? 0 : pageIndex;
          for (let i = 0; i < endIndex; i++) { this.pages.push({}); }
          this.pages.push({ gridAreaSize, images });
        }
        return Promise.resolve();
      } catch (e) {
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.sublesson_images') + "\n" + this.$t('telestia.error.connection'),
        });
        return Promise.reject(e);
      }
    },

    async _fetchImageSources(images) {
      try {
        const imagesFolder = this.selectedLesson.folder;

        if (!this.isOnlineBuild) {
          images.forEach(img => img.src = this.getImageBase64(imagesFolder, img.name))
          return Promise.resolve(images);
        }

        const requests = [];
        for (let img of images) {
          requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product: imagesFolder, title: img.name }));
        }
        const results = await Promise.allSettled(requests);

        images.forEach((img, index) => {
          if (results[index].status !== 'rejected') { img.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { img.src = this.getImageBase64(''); }
        })

        return Promise.resolve(images);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
    selectedLesson() {
      // Update layout
      this._initComponent(true);
      this.currentPageIndex = 0;
    }
  }
}
</script>

<style scoped>
.sublesson-image {
  cursor: pointer;
}
.sublesson-image:hover {
  opacity: 0.5;
}
.unclickable { cursor: default; }
.sublesson-image.unclickable:hover {
  opacity: 1;
}
.hover-text-container {
  position: absolute;
  /*top: 20px;
  left: 20px;*/
  padding: 10px;
  border: 1px solid #1b96cf;
  background: white;
}
.textpanel {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
