<template>
	<div class="row">
		<div class="col d-flex flex-column justify-content-end">
			<button
				v-for="measExtra in extras"
				:key="measExtra.type"
				class="secondary-btn text-uppercase extras-btn"
				:class="{ 'btn-active': showMeasTable === measExtra.type }"
				@click="showMeasurementTable(measExtra.type)"
				variant="primary"
			>
				<p class="p-rotate-90">{{ getExtrasTitle(measExtra) }}</p>
			</button>
		</div>

		<div
			v-if="showMeasTable"
			class="col d-flex px-0 mt-4"
			:class="{ 'measurement-table-limited': isLayoutLimited }"
			style="height: fit-content"
		>
      		<component
			  	:is="activeMeasurementTable"
				class="position-absolute"
				style="bottom:0">
			</component>
		</div>
	</div>
</template>

<script>
import Ca from './MeasurementTables/caseA';
import Cb from './MeasurementTables/caseB';
import Cc from './MeasurementTables/caseC';
import Cd from './MeasurementTables/caseD';
import Measurements from './MeasurementTables/default';
import ChildrenMeasurements from './MeasurementTables/childrenMeas';
import { makeDraggable } from "../../../../shared/utils/draggable";

export default {
	props: {
		extras: {
			type: Array,
			required: true
		}
	},

	components: {
		Ca,
		Cb,
		Cc,
		Cd,
		Measurements,
		ChildrenMeasurements
	},

	data() {
		return {
			showMeasTable: false,
		}
	},

	computed: {
		isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

		isChildrenswearLesson() {
			return this.$store.getters.getSelected.lessonKey.substring(0, 1).toLowerCase() === 'd';
		},

		activeMeasurementTable() {
			if (this.showMeasTable) {
				return this.showMeasTable === 'measurements' && this.isChildrenswearLesson ? 'children-measurements' : this.showMeasTable;
			}
			return '';
		}
	},

	methods: {
		showMeasurementTable(type) {
			if (this.showMeasTable === type) { this.showMeasTable = false; }
			else { this.showMeasTable = type; }
			setTimeout(() => {
				let mt = document.querySelector('.measurement-table');
				if (mt) makeDraggable(mt);
			}, 100);
		},

		getExtrasTitle(extra) {
			if (extra.title.toLowerCase().includes('case')) { return extra.title.split(' ').slice(-1)[0]; }
			else { return 'm'; }
		}
	}

}
</script>

<style scoped>
.measurement-table-limited {
	transform: scale(.5);
}
.secondary-btn {
	font-weight: bold;
	color: white;
	background-color: lightgrey;
	border: 1px solid lightgrey;
	border-radius: 20px;
}
.secondary-btn:hover, .btn-active {
	color: #1a96cf;
	background-color: white;
	border: 1px solid #1a96cf;
}
.extras-btn {
	margin-top: 10px;
	padding: 10px 5px;
}

.p-rotate-90 {
	transform: rotate(90deg);
	padding: 0;
	margin: 0;
}
</style>

<style>
.measurement-table {
	position: absolute;
	bottom: 0;
	box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 29px 0px;
	z-index: 9999;
}
.measurement-table, .measurement-table td {
	text-align: center;
	border-collapse: collapse;
	border: 1px solid #1a96cf;
}
</style>
