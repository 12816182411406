<template>
  <div class="d-flex">
    <!-- Completed -->
    <template v-if="completed || markedAsComplete">
      <button v-if="isLayoutLimited" class="completed-lesson-sign border-0">
        <font-awesome-icon :icon="['fas', 'check']" size="2x" />
      </button>
      <div v-else class="completed-lesson-sign position-relative px-4 py-1">
        <span class="check-mark">✓</span>
        <span class="pl-2">{{ $t("telestia.button.completed") }}</span>
      </div>
    </template>
    <!-- Not completed -->
    <template v-else>
      <button v-if="isLayoutLimited" class="complete-lesson-button" @click="markAsComplete">
        <font-awesome-icon :icon="['fas', 'check']" size="2x" />
      </button>
      <button v-else class="complete-lesson-button px-4 py-1" @click="markAsComplete">{{ $t("telestia.button.mark_as_complete") }} </button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    completed: {
      require: true,
      type: Boolean
    },
    lessonid: {
      require: true,
      type: Number,
    },
  },

  data() {
    return {
      markedAsComplete: false,
    }
  },

  computed: {
    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },
  },

  methods: {
    async markAsComplete() {
      try {
        await this.$store.dispatch('progress/markLessonAsComplete', this.lessonid);
        this.markedAsComplete = true;
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.lesson_complete') + "\n" + this.$t('telestia.error.connection'),
        });
      }
    },
  },

}
</script>
