<template>
  <div class="maneken-functionality d-flex w-100 h-100">
    <template v-if="showFront">
      <div class="maneken" ref="manekenFront">
        <div id="maneken_merures" class="maneken_merures" :style="{ display: (visiblePart === `maneken_merures`) ? 'block' : 'none' }"></div>
        <div
          v-for="index in 10"
          :id="`maneken_pos_${index}`"
          :style="{ display: (visiblePart === `maneken_pos_${index}`) ? 'block' : 'none' }"
        ></div>
      </div>

      <div class="d-flex flex-column pl-3 w-50">
        <div class="ruler-front-image-map-container mb-5">
        	<img 
        		id="ruler-front-position" class="ruler-front-position-image img-fluid"
						src="../../../../assets/images/ruler/maneken/rouler_positions_front.png" alt="Ruler front positions"
						usemap="#ruler-front-position"
						@load="calcAbsoluteCoords" 
          />
          <map name="ruler-front-position" id="ruler-front-position-map">
            <area
		          v-for="(area, index) in rulerFrontMapAreas"
		          :key="area.id"
		          :shape="area.shape"
		          :id="area.id"
		          :alt="area.title"
		          :title="area.title"
		          :coords="area.absoluteCoords"
		          @mouseover="hoveredPart = `maneken_pos_${index+1}`"
		          @mouseleave="hoveredPart = ''"
		        >
          </map>
        </div>
        
		    <div id="prints">
		      <div :style="printWidth">
				    <img
		          v-for="index in 10"
		          :key="`print-image-${index}`"
		          :id="`print-image-${index}`"
		          width="20%"
		          :src="require(`../../../../assets/images/ruler/maneken/${printImages[index-1]}.png`)"
		          @mouseenter="addManekenStyleClass(`maneken${index}`)"
		          @mouseleave="removeManekenStyleClass(`maneken${index}`)"
		        />
          </div>
		    </div>
		  </div>
    </template>

    <template v-else>
      <div class="maneken_back" ref="manekenBack">
        <div id="maneken_merures_back" class="maneken_merures_back" :style="{ display: (visiblePart === `maneken_merures_back`) ? 'block' : 'none' }"></div>
        <div
          v-for="index in 7"
          :id="`maneken_pos_back_${index}`"
          :style="{ display: (visiblePart === `maneken_pos_back_${index}`) ? 'block' : 'none' }"
        ></div>
      </div>
      
      <div class="ruler-back-image-map-container">
      	<img 
      		id="ruler-back-position" class="ruler-back-position-image img-fluid"
					src="../../../../assets/images/ruler/maneken/rouler_positions_back.png" alt="Ruler back positions"
					usemap="#ruler-back-position"
					@load="calcAbsoluteCoords" 
        />
        <map name="ruler-back-position" id="ruler-back-position-map">
          <area
	          v-for="(area, index) in rulerBackMapAreas"
	          :key="area.id"
	          :shape="area.shape"
	          :id="area.id"
	          :alt="area.title"
	          :title="area.title"
	          :coords="area.absoluteCoords"
	          @mouseover="hoveredPart = `maneken_pos_back_${index+1}`"
	          @mouseleave="hoveredPart = ''"
	        >
        </map>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    showFront: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      hoveredPart: '',
      
      rulerImageResizeObserver: null,
      rulerFrontMapAreas: [
        { id: 'pos-1', title: '', coords: "58,8,114,47", absoluteCoords: "", shape: "rect" },
        { id: 'pos-2', title: '', coords: "271,16,338,50", absoluteCoords: "", shape: "rect" },
        { id: 'pos-3', title: '', coords: "37,126,38,139,55,139,68,136,83,129,89,137,96,134,97,124,111,110,125,91,138,88,131,77,137,58,137,37,127,35,124,56,119,70,111,82,104,95,97,104,80,115,63,122", absoluteCoords: "", shape: "poly" },
        { id: 'pos-4', title: '', coords: "152,26,258,58,264,71,260,82,140,48,143,35", absoluteCoords: "", shape: "poly" },
        { id: 'pos-5', title: '', coords: "139,57,264,93,258,113,191,92,187,83,135,68", absoluteCoords: "", shape: "poly" },
        { id: 'pos-6', title: '', coords: "24,249,87,275", absoluteCoords: "", shape: "rect" },
        { id: 'pos-7', title: '', coords: "159,159,293,282,288,285,280,285,210,227,206,236,195,234,191,221,145,173,147,163", absoluteCoords: "", shape: "poly" },
        { id: 'pos-8', title: '', coords: "112,300,166,300,166,288,154,290,123,285,113,284", absoluteCoords: "", shape: "poly" },
        { id: 'pos-9', title: '', coords: "168,300,342,300,341,286,248,287,245,278,181,276,178,287,168,287", absoluteCoords: "", shape: "poly" }
      ],
      printImages: [
      	'Body-block_small', 'cf-cb_small', 'neckcurve_small', 'shoulderseam_small', 'armholecurve_small', 'chestline_small', 'bustline_small', 'waistline_small', 'hipline_small', 'sideseam_small'
      ],
      rulerBackMapAreas: [
      	{ id: 'pos-1', title: '', coords: "15,17,77,50", absoluteCoords: "", shape: "rect" },
        { id: 'pos-2', title: '', coords: "239,25,294,51", absoluteCoords: "", shape: "rect" },
        { id: 'pos-3', title: '', coords: "75,72,78,84,207,47,204,35,198,35,195,25,130,44,135,54", absoluteCoords: "", shape: "poly" },
        { id: 'pos-4', title: '', coords: "244,70,252,65,267,85,282,97,276,106,258,92", absoluteCoords: "", shape: "poly" },
        { id: 'pos-5', title: '', coords: "130,220,54,284,68,287,142,223,150,230,160,227,160,218,204,172,192,159", absoluteCoords: "", shape: "poly" },
        { id: 'pos-6', title: '', coords: "200,255,206,266,202,278,216,278,228,263,218,258,208,249", absoluteCoords: "", shape: "poly" },
        { id: 'pos-7', title: '', coords: "226,289,227,297,6,298,9,288,80,287,79,279,135,279,151,272,154,289", absoluteCoords: "", shape: "poly" }
      ]
    }
  },

  computed: {
    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },
    
    visiblePart() {
      const defaultManekenPart = this.showFront ? 'maneken_merures' : 'maneken_merures_back';
      return this.hoveredPart || defaultManekenPart;
    },
    
    printWidth() { return { width: this.isLayoutLimited ? '100%' : '70%' }; }
  },

  methods: {
    addManekenStyleClass(className) {
    	this.hoveredPart = 'print';
      this.$refs.manekenFront.classList.add(className);
    },

    removeManekenStyleClass(className) {
      this.$refs.manekenFront.classList.remove(className);
      this.hoveredPart = '';
    },

    calcAbsoluteCoords() {
    	const rulerImageSelector = this.showFront ? '.ruler-front-position-image' : '.ruler-back-position-image';
      const img = document.querySelector(rulerImageSelector);
      if (!img) { return; }
      const imageWidth = img.clientWidth;
      const naturalImageWidth = img.naturalWidth;
      
      const mapAreas = this.showFront ? 'rulerFrontMapAreas' : 'rulerBackMapAreas';
      this[mapAreas].forEach(area => {
        area.absoluteCoords = area.coords
          .split(',')
          .map(c => (imageWidth / naturalImageWidth) * c)
          .join(',');
      });
    }
  },
  
  watch: {
  	showFront: {
  		immediate: true,
  		handler(newVal) {
  			this.$nextTick(() => {
  				if (this.rulerImageResizeObserver) { this.rulerImageResizeObserver.disconnect(); }
					
					const rulerImageSelector =  newVal ? '.ruler-front-position-image' : '.ruler-back-position-image';
					this.rulerImageResizeObserver = new ResizeObserver(() => this.calcAbsoluteCoords());
		  		this.rulerImageResizeObserver.observe(document.querySelector(rulerImageSelector));
  			})
  		}
  	}
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/ruler_maneken";

area {
  cursor: pointer;
}
</style>
