<template>
  <div class="tests wrapper d-flex flex-column flex-fill" style="background-color: #F2F2F2;">
    <!-- Lesson title -->
    <div class="lesson-title" style="flex: 10%; max-height: 10%;">
      <h3 class="title text-white text-right py-2 px-3" style="font-size: 18px; background-color: #1b96cf; border-radius: 5px;">
        <span class="code">{{ lessonCode }}</span> {{ lessonTitle }}
      </h3>
    </div>

    <div class="test-form d-flex" style="flex: 90%; max-height: 90%;">
      <div v-if="emptyLessonContent" class="page d-flex justify-content-center align-items-center" style="flex: 100%; max-height: 100%">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
        <span class="ml-3" style="font-size: 20px">{{ $t("telestia.message.empty_test") }}</span>
      </div>

      <form-wizard
        v-else
        ref="wizard"
        class="d-flex flex-column p-0"
        :class="{ 'hide-stepper': !showStepper }"
        color="#1b96cf"
        title=""
        subtitle=""
        stepSize="xs"
        style="flex: 100%; max-height: 100%;"
      >
        <!-- Self assessments tabs -->
        <tab-content
          v-for="(assessment, assIdx) in testLesson.selfAssessments"
          :key="`self-assessment-${assIdx}`"
          style="overflow-y: auto;"
        >
          <div class="wizard-card page">
            <div class="content">
              <span v-if="!testLesson.includesLinks" class="content-title font-weight-bold">{{ $t("telestia.title.practise_yourself") }}</span>
              <div v-html="assessment"></div>
            </div>
          </div>
        </tab-content>

        <!-- multiple choice questions tabs -->
        <tab-content
          v-for="(q, qIdx) in testLesson.multipleChoiceQuestions"
          :key="qIdx"
          style="overflow-y: auto;"
        >
          <div class="wizard-card page">
            <div class="choices position-relative" v-if="q.choices">
              <p class="question font-weight-bold pb-2 mt-3" style="border-bottom: 1px solid #1b96cf;">{{ q.question }}</p>
              <b-form-group v-slot="{ ariaDescribedby }">
                <div v-for="(choice, choiceIdx) in q.choices" :key="choiceIdx" class="mb-2">
                  <b-form-radio :aria-describedby="ariaDescribedby" v-model="answers[qIdx]" :value="choiceIdx" :disabled="disabledQuestions.findIndex((q) => q === qIdx) !== -1">
                    <span class="ml-2" v-html="choice"></span>
                    <font-awesome-icon
                        v-if="results[qIdx] && q.correctAnswer === choiceIdx"
                        :icon="['fas', 'check']"
                        class="ml-2"
                        style="color: green"
                    />
                    <font-awesome-icon
                        v-if="results[qIdx] && answers[qIdx] === choiceIdx && q.correctAnswer !== choiceIdx"
                        :icon="['fas', 'times']"
                        class="ml-2"
                        style="color: red"
                    />
                  </b-form-radio>
                </div>
              </b-form-group>

              <!-- Submit button -->
              <b-button
                v-if="typeof answers[qIdx] !== 'undefined' && !results[qIdx]"
                class="position-absolute primary-btn"
                @click="submit(qIdx)"
                style="bottom: 5px; right: 5px;"
              >
                {{ $t("telestia.button.submit") }}
              </b-button>
            </div>

            <!-- Multiple choice question description -->
            <div v-if="q.choices && q.description" class="gradient-divider position-relative"></div>
            <div class="content" v-if="q.description">
              <span class="content-title font-weight-bold mb-5">{{ $t("telestia.title.notes") }}</span>
              <div v-html="q.description"></div>
            </div>
          </div>
        </tab-content>

        <!-- Results tab -->
        <tab-content
          v-if="total > 0"
          :title="$t('telestia.title.results')"
          style="overflow-y: auto;"
        >
          <div class="wizard-card page text-center chart">
            <div class="comment" style="font-size: 20px;">
              <p v-if="percentCorrect <= 50">{{ $t("telestia.message.extra_practice") }}</p>
              <p v-if="percentCorrect > 50 && percentCorrect<70">{{ $t("telestia.message.ok") }}</p>
              <p v-if="percentCorrect >= 70">{{ $t("telestia.message.well_done") }}</p>
            </div>
            <div class="flex-fill w-100">
		          <div class="section correct w-25" style="display: inline-block;">
		            <div class="bar" :style="{ height: barHeightCorrect }" style="background-color: green;"></div>
		            <div class="result font-weight-bold" style="font-size: 25px; border-top: 5px solid #1b96cf;">{{ correct }}</div>
		            <p class="label">{{ $t("telestia.title.correct_answers") }}</p>
		          </div>
		          <div class="section wrong w-25" style="display: inline-block; margin-left: -4px">
		            <div class="bar" :style="{ height: barHeightWrong }" style="background-color: red;"></div>
		            <div class="result font-weight-bold" style="font-size: 25px; border-top: 5px solid #1b96cf;">{{ total - correct }}</div>
		            <p class="label">{{ $t("telestia.title.wrong_answers") }}</p>
		          </div>
            </div>
          </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left" v-if="!emptyLessonContent">
            <b-button class="wizard-button" v-if="props.activeTabIndex > 0" @click="props.prevTab()">{{ $t("telestia.button.previous") }}</b-button>
          </div>
          <div class="wizard-footer-right" v-if="!emptyLessonContent">
            <b-button
                v-if="!props.isLastStep"
                class="wizard-button"
                @click="props.nextTab()"
                :disabled="props.activeTabIndex > testLesson.selfAssessments.length - 1 && !results[props.activeTabIndex - testLesson.selfAssessments.length]"
            >
              {{ $t("telestia.button.next") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import domParser from "../../../../shared/helpers/domParser";
import lessons from '../../../../shared/helpers/lessons';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

export default {
  components: {
    FormWizard,
    TabContent
  },

  // Name of this component, to use in pages and in other components
  name: 'player-test',

  props: [ 'selectedLesson' ],

  created() { this.initTest(); },

  mounted() { this.addAnchorClickListeners(); },

	// Local data
  data() {
    return {
      testLesson: {},

      answers: [],
      results: [],
      disabledQuestions: [],
    }
  },

  computed: {
    selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },
    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },
    emptyLessonContent() {
      return !this.testLesson.selfAssessments?.length && !this.testLesson.multipleChoiceQuestions?.length
    },
    showStepper() {
      return this.testLesson.multipleChoiceQuestions?.length + this.testLesson.selfAssessments?.length !== 1;
    },
    correct() {
      return this.results.filter(r => r === 'correct').length;
    },
    total() {
      return this.testLesson.multipleChoiceQuestions.length;
    },
    percentCorrect() {
      return this.correct*100/this.total;
    },
    barHeightCorrect() {
      let max = Math.max(this.correct,this.total-this.correct);
      return Math.round(this.correct*(1000/max)/this.total) + 'px';
    },
    barHeightWrong() {
      let max = Math.max(this.correct,this.total-this.correct);
      return Math.round((this.total-this.correct)*(1000/max)/this.total) + 'px';
    }
  },

  methods: {
    initTest() {
      const testContent = this.selectedLesson.html.languages[this.selectedLanguage] || this.selectedLesson.html.languages.en;
      const testLesson = {
        title: this.selectedLesson.title,
        folder: this.selectedLesson.folder,
        includesLinks: false,
        selfAssessments: [],
        multipleChoiceQuestions: []
      }

      if (testContent.lists) {
        testContent.lists.forEach(list => {
          const listContent = domParser.parseHTMLAttributes(list);

          const parser = new DOMParser()
          let section = parser.parseFromString(listContent, 'text/html').body;

          section = domParser.replaceLinkHrefs(section);
          testLesson.selfAssessments.push(section.innerHTML);
        })
      }

      if (testContent.questions) {
        testContent.questions.forEach(q => {
          testLesson.multipleChoiceQuestions.push(
              { question: q.title, description: q.description, choices: q.answers, correctAnswer: q.correctIndex }
          );
        });
      }
      this.testLesson = testLesson;
    },
    addAnchorClickListeners() {
      this.$nextTick(() => {
        const anchorEls = document.querySelectorAll('.article-anchorEl');
        if (anchorEls.length > 0) { this.testLesson.includesLinks = true; }
        anchorEls.forEach(el => el.addEventListener('click', () => {
          const subLessonClass = Array.from(el.classList).find(e => e.includes('lesson-'));
          if (subLessonClass) {
            const subLessonId = subLessonClass.split('-').slice(-1);
            if (!subLessonId) { return; }
            this.selectLesson(subLessonId);
          }
        }))
      })
    },
    selectLesson(sublessonId) {
      const selectedSublessonCode = this.$store.getters.getLessonCode(sublessonId);
      this.$emit("update-selected-lesson", { key: selectedSublessonCode, enabled: true });
    },
    submit(questionId) {
      if (this.answers[questionId] === this.testLesson.multipleChoiceQuestions[questionId].correctAnswer) {
        this.$set(this.results, questionId, 'correct')
      } else {
        this.$set(this.results, questionId, 'wrong')
      }
      this.disabledQuestions.push(questionId + this.testLesson.selfAssessments.length - 1)
    }
  },

  watch: {
    selectedLesson() {
      // update layout
      this.initTest();
      this.addAnchorClickListeners();

      // Initialize form wizard in offline and hybrid build
      this.$refs.wizard.changeTab(0, 0);
    }
  }
}
</script>

<style>
.tests .wizard-header {
  display: none;
}

.tests .vue-form-wizard {
  margin-top: 25px;
}

h3.title span.code {
  display: inline-block;
  color: #1b96cf;
  background-color: white;
  border-radius: 20px;
  font-weight: normal;
  padding: 7px 2px;
  margin-right: 10px;
}
.gradient-divider {
  height: 1px;
  margin: 2% 35%;
}
.gradient-divider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  height: 1px;
  background-image: linear-gradient(to left, transparent, #1b96cf, transparent);
}
div.wrapper div.page ul li {
  padding-top: 10px;
}
.chart {
	display: flex;
  flex-direction: column;
  align-items: center;
}
div.chart div.bar {
  width: 80%;
  margin: 0 auto;
}
.wizard-navigation {
  flex: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}
.wizard-tab-content, .wizard-tab-container {
  flex: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.wizard-card {
  flex: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px 50px;
  border: 1px solid #1b96cf;
  border-radius: 5px;
}
.wizard-button, .wizard-button:focus, .wizard-button:hover, .wizard-button:active {
  color: white;
  background-color: #1b96cf;
  border: none;
  border-radius: 5px;
  box-shadow: none;
}
.hide-stepper .wizard-nav.wizard-nav-pills,
.hide-stepper .wizard-progress-with-circle {
  display:none
}
</style>

