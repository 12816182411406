<template>
  <div class="lesson-extra-technical-drawing d-flex flex-column" style="height: 100%;">
    <technical-drawing :title="title" :figures="figures" :assets="data" />
  </div>
</template>

<script>
import TechnicalDrawing from "../TechnicalDrawing/TechnicalDrawing";

export default {
  props: {
    title: {
      type: Object,
      required: true
    },
    figures: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      required: true
    }
  },

  components: {
    TechnicalDrawing
  }
}
</script>
