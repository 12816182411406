<template>
  <div class="translation-container px-3 mt-3 mb-1 position-relative">
    <div v-if="pendingTranslation" class="loading-overlay">
			<b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
		</div>

    <h6 class="mb-2">{{ $t("telestia.title.video_texts") }}</h6>
    <div>
      <div class="title-section row mb-2">
        <div class="english-title col">
          <b-form-textarea
              id="english-title-textarea"
              v-model="englishTexts.title.text"
              rows="1"
          ></b-form-textarea>
        </div>
        <div v-if="selectedUserLanguage !== 'en'" class="translated-title col">
          <b-form-textarea
              id="translated-title-textarea"
              v-model="translatedTexts.title.text"
              rows="1"
          ></b-form-textarea>
        </div>
      </div>

      <div class="subtitle-section row mb-2">
        <div class="english-subtitle col">
          <b-form-textarea
              id="english-subtitle-textarea"
              v-model="englishTexts.subtitle.text"
              rows="2"
          ></b-form-textarea>
        </div>
        <div v-if="selectedUserLanguage !== 'en'" class="translated-subtitle col">
          <b-form-textarea
              id="translated-subtitle-textarea"
              v-model="translatedTexts.subtitle.text"
              rows="2"
          ></b-form-textarea>
        </div>
      </div>

      <div class="text-section row mb-2">
        <div class="english-text col">
          <b-form-textarea
              id="english-textarea"
              v-model="englishTexts.text.text"
              rows="4"
              max-rows="8"
          ></b-form-textarea>
        </div>
        <div v-if="selectedUserLanguage !== 'en'" class="translated-text col">
          <b-form-textarea
              id="translated-textarea"
              v-model="translatedTexts.text.text"
              rows="4"
              max-rows="8"
          ></b-form-textarea>
        </div>
      </div>

      <div class="buttons row">
        <div class="col d-flex justify-content-end align-items-center">
          <button class="primary-btn py-2 px-3 mr-1" @click="saveTranslations">{{ $t("telestia.button.save") }}</button>
          <button class="primary-btn py-2 px-3" @click="$emit('close')">{{ $t("telestia.button.close") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import axios from "axios";

export default {
  props: {
    lessonId: {
      type: Number,
      required: true
    },
    stopIndex: {
      type: Number,
      required: true
    },
    currentStop: {
      type: Object,
      required: true
    }
  },

  data() {
  	return {
  		pendingTranslation: false,
  	}
  },

  computed: {
    currentProductId() {
      return this.$store.getters["products/getProductId"];
    },

    selectedUserLanguage() {
      return this.$store.getters["user/getSelectedLanguage"];
    },

    castNumbers() {
      return {
        castNumber1: this.currentStop.castNumber1,
        castNumber2: this.currentStop.castNumber2,
        castNumber3: this.currentStop.castNumber3
      }
    },

    englishTexts() {
      const texts = _cloneDeep(this.currentStop.texts.en);
      return {
        cdId: this.currentProductId,
        language: "en",
        title: { castnumber: this.castNumbers.castNumber1, text: texts.text1 },
        subtitle: { castnumber: this.castNumbers.castNumber2, text: texts.text2 },
        text: { castnumber: this.castNumbers.castNumber3, text: texts.text3 }
      }
    },

    translatedTexts() {
      if (this.selectedUserLanguage === "en") { return {} }

      const texts = this.currentStop.texts[this.selectedUserLanguage]
        ? _cloneDeep(this.currentStop.texts[this.selectedUserLanguage])
        : { text1: "", text2: "", text3: "" }

      return {
        cdId: this.currentProductId,
        language: this.selectedUserLanguage,
        title: { castnumber: this.castNumbers.castNumber1, text: texts.text1 },
        subtitle: { castnumber: this.castNumbers.castNumber2, text: texts.text2 },
        text: { castnumber: this.castNumbers.castNumber3, text: texts.text3 }
      }
    },
  },

  methods: {
    async saveTranslations() {
      try {
      	this.pendingTranslation = true;

        let oldTexts = Object.keys(this.currentStop.texts.en).map(k => this.currentStop.texts.en[k]);
        let newTexts = Object.keys(this.englishTexts).filter(k => k !== 'language').map(k => this.englishTexts[k].text);
        if (!_isEqual(oldTexts, newTexts)) {
          await this.$store.dispatch("lessons/editNewVideoTranslation", this.englishTexts);
        }

        if (this.selectedUserLanguage !== 'en') {
          oldTexts = Object.keys(this.currentStop.texts[this.selectedUserLanguage]).map(k => this.currentStop.texts[this.selectedUserLanguage][k]);
          newTexts = Object.keys(this.translatedTexts).filter(k => k !== 'language').map(k => this.translatedTexts[k].text);
          if (!this.currentStop.texts[this.selectedUserLanguage]) {
            await this.$store.dispatch("lessons/addNewVideoTranslation", this.translatedTexts);
          } else if (!_isEqual(oldTexts, newTexts)) {
            await this.$store.dispatch("lessons/editNewVideoTranslation", this.translatedTexts);
          }
        }

        this.updateLocalStorageEntry();
        this.$emit("update");

        this.$swal({ text: 'Translations saved succesfully', icon: 'success', timer: 5000, button: true });
      } catch (e) {
        console.log(e);
        this.$swal({ text: 'There was an error while saving translations! Try again!', icon: 'error', timer: 5000, button: true });
      } finally {
      	this.pendingTranslation = false;
      }
    },

		// Update local storage entry for current lesson after a new translation is saved in db
    updateLocalStorageEntry() {
      const savedTranslations = JSON.parse(localStorage.getItem(`${this.lessonId}`));

      const updatedTexts = this.currentStop.texts;
    	updatedTexts.en = { text1: this.englishTexts.title.text, text2: this.englishTexts.subtitle.text, text3: this.englishTexts.text.text };
    	if (!_isEmpty(this.translatedTexts)) {
    		updatedTexts[`${this.selectedUserLanguage}`]
    			= { text1: this.translatedTexts.title.text, text2: this.translatedTexts.subtitle.text, text3: this.translatedTexts.text.text };
    	}
    	const currentStopUpdated = { ...this.currentStop, texts: updatedTexts };
    	console.log(currentStopUpdated.texts.en.text1);
      savedTranslations[this.stopIndex] = currentStopUpdated;
      localStorage.setItem(`${this.lessonId}`, JSON.stringify(savedTranslations));
    }
  }
}
</script>

<style scoped>
.top-pos {
	position: absolute;
	top: 15px;
	right: 15px;
}
</style>

