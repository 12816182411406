<template>
	<div class="position-relative">
		<div v-if="pendingComponentInit" class="loading-overlay">
			<b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
		</div>

		<table class="measurement-table" bgcolor="#FFFFFF" style="min-width: 500px;">
		  <tbody>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.total_chest_measurement") }}<br>{{ $t("telestia.table.measurements.total_bust_measurement") }}</td>
		      <td>{{ defaultMeasurement.TCM }}<br>{{ defaultMeasurement.TBM }}</td>
		      <td>1/2<br> 1/2</td>
		      <td>
		        <span style="float:left;">{{ computedMeasurements.tcm }}<br>{{ computedMeasurements.tbm }}</span> &nbsp;
		        <img style="vertical-align: middle;" src="../../../../../assets/images/extras/anchor.png" alt="a10icons">
		        <span style="float:right">{{ $t("telestia.table.measurements.chbd") }}<br>{{ computedMeasurements.chbd }}&nbsp; &nbsp;</span>
		      </td>
		    </tr>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.shoulder_distance_front") }}<br>{{ $t("telestia.table.measurements.shoulder_distance_back") }}</td>
		      <td>{{ defaultMeasurement.SDF }}<br> {{ defaultMeasurement.SDB }}</td>
		      <td>1/2<br>1/2</td>
		      <td>
		        <span style="float:left;">{{ computedMeasurements.sdf }}<br> {{ computedMeasurements.sdb }}</span>&nbsp;
		        <img style="vertical-align: middle;" src="../../../../../assets/images/extras/anchor.png" alt="a10icons">
		        <span style="float:right">{{ $t("telestia.table.measurements.shdd") }}<br>{{ computedMeasurements.shdd }}</span>
		      </td>
		    </tr>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.total_bodice_length") }}<br>{{ $t("telestia.table.measurements.bodice_length_back") }}<br>{{ $t("telestia.table.measurements.bodice_length_front") }}</td>
		      <td>{{ defaultMeasurement.TBL }}<br> {{ defaultMeasurement.BLB }}<br> {{ defaultMeasurement.BLF }}</td>
		      <td>1/2<br>&nbsp;<br>&nbsp;</td>
		      <td class="position-relative">
		        <span style="float: left;">{{ computedMeasurements.tbl }}<br> &nbsp;<br> &nbsp;</span> &nbsp;
		        <img class="position-absolute" style="vertical-align: middle; top: 30px; left: 35%;" src="../../../../../assets/images/extras/anchor.png" alt="a10icons">
		        <span style="float: right"><br>{{ $t("telestia.table.measurements.bld") }}<br>{{ computedMeasurements.bld }}</span>
		      </td>
		    </tr>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.bust_length") }}<br>{{ $t("telestia.table.measurements.bust_distance") }}</td>
		      <td>{{ defaultMeasurement.BL }}<br> {{ defaultMeasurement.BD }}</td>
		      <td>&nbsp;<br>1/2</td>
		      <td>&nbsp;<br><span style="float:left;">{{ computedMeasurements.bd }}</span></td>
		    </tr>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.sleeve_length") }}<br>{{ $t("telestia.table.measurements.hip_length") }}<br>{{ $t("telestia.table.measurements.skirt_length") }}</td>
		      <td>{{ defaultMeasurement.SL }}<br>{{ defaultMeasurement.HL }}<br>{{ defaultMeasurement.SkL }}</td>
		      <td>&nbsp;<br>&nbsp;</td>
		      <td>&nbsp;<br>&nbsp;</td>
		    </tr>
		    <tr>
		      <td>{{ $t("telestia.table.measurements.waist_measurement") }}<br>{{ $t("telestia.table.measurements.hip_measurement") }}</td>
		      <td>{{ defaultMeasurement.WM }}<br> {{ defaultMeasurement.HM }}</td>
		      <td>1/4<br>1/4</td>
		      <td>
		        <span style="float:left;">{{ computedMeasurements.wm }}<br> {{ computedMeasurements.hm }}</span> &nbsp;
		        <img style="vertical-align: middle;" src="../../../../../assets/images/extras/anchor.png" alt="a10icons">
		        <span style="float:right">{{ $t("telestia.table.measurements.hwd") }}<br>{{ computedMeasurements.hwd }}</span>
		      </td>
		    </tr>
		    <tr>
		      <td colspan="5">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ $t("telestia.table.measurements.slant") }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ defaultMeasurement.Slant }}</td>
		    </tr>
		  </tbody>
		</table>
  </div>
</template>

<script>
export default {
  created() { if (!this.isOfflineBuild) { this.fetchDefaultUserMeasurement() } },

  data() {
    return {
      pendingComponentInit: false,

      defaultMeasurement: {
        TCM: '.....',
        TBM: '.....',
        SDF: '.....',
        SDB: '.....',
        TBL: '.....',
        BLF: '.....',
        BLB: '.....',
        BL: '.....',
        BD: '.....',
        SL: '.....',
        SkL: '.....',
        WM: '.....',
        HM: '.....',
        Slant: '.....',
      }
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline']; },

    computedMeasurements() {
      let ret = {
        tcm: this.isEmpty(this.defaultMeasurement.TCM) ? '.....' : this.calcValue(this.defaultMeasurement.TCM / 2, {round:2}),
        tbm: this.isEmpty(this.defaultMeasurement.TBM) ? '.....' : this.calcValue(this.defaultMeasurement.TBM / 2, {round:1}),
        sdf: this.isEmpty(this.defaultMeasurement.SDF) ? '.....' : this.calcValue(this.defaultMeasurement.SDF / 2, {round:0.5,decimals:1}),
        sdb: this.isEmpty(this.defaultMeasurement.SDB) ? '.....' : this.calcValue(this.defaultMeasurement.SDB / 2, {round:0.5,decimals:1}),
        tbl: this.isEmpty(this.defaultMeasurement.TBL) ? '.....' : this.calcValue(this.defaultMeasurement.TBL / 2, {round:0.5,decimals:1}),
        bd: this.isEmpty(this.defaultMeasurement.BD) ? '.....' : this.calcValue(this.defaultMeasurement.BD / 2, {round:0.5,decimals:1}),
        wm: this.isEmpty(this.defaultMeasurement.WM) ? '.....' : this.calcValue(this.defaultMeasurement.WM / 4, {round:0.5,decimals:1}),
        hm: this.isEmpty(this.defaultMeasurement.HM) ? '.....' : this.calcValue(this.defaultMeasurement.HM / 4, {round:0.5,decimals:1}),
        chbd: this.isEmpty(this.defaultMeasurement.TBM) || this.isEmpty(this.defaultMeasurement.TCM)
            ? '.....'
            : this.calcValue(this.calcValue(this.defaultMeasurement.TBM/2) - this.calcValue(this.defaultMeasurement.TCM/2, {round:2}), {decimals:1}),
        shdd: this.isEmpty(this.defaultMeasurement.SDB) || this.isEmpty(this.defaultMeasurement.SDF)
            ? '.....'
            : this.calcValue((this.defaultMeasurement.SDB/2) - (this.defaultMeasurement.SDF/2), {decimals:1}),
        bld: this.isEmpty(this.defaultMeasurement.TBL) || this.isEmpty(this.defaultMeasurement.BLB)
            ? '.....'
            : this.calcValue((this.defaultMeasurement.TBL/2) - this.defaultMeasurement.BLB, {decimals: 1}),
        hwd: this.isEmpty(this.defaultMeasurement.HM) || this.isEmpty(this.defaultMeasurement.WM)
            ? '.....'
            : Math.floor(this.calcValue(this.calcValue(this.defaultMeasurement.HM/4, {round:0.5,decimals:1}) - this.calcValue(this.defaultMeasurement.WM/4, {round:0.5,decimals:1}), {decimals:1})),
      };
      ret.slant = this.isEmpty(ret.hm) ? '.....' : this.calcValue(ret.hm/2,{decimals:1});
      return ret;
    }
  },

  methods: {
    async fetchDefaultUserMeasurement() {
      try {
        this.pendingComponentInit = true;

        this.defaultMeasurement = await this.$store.dispatch("measurements/fetchDefaultUserMeasurement");
      } catch (e) {
        console.log(e);
        this.$swal({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.default_measurement') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
          timer: 5000,
          button: true
        });
      } finally {
      	this.pendingComponentInit = false;
      }
    },

    isEmpty(metric) { return metric === '.....'; },

    calcValue(val=0, { round=0, decimals=0, threshold=0 } = {}) {
      let res = parseFloat(val)-threshold;
      if (round!=0) res = Math.round(res / round) * round;
      return Number(res.toFixed(decimals));
    },

  }
}
</script>

