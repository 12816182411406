<template>
  <div id="product-layout" class="flex-grow-1 d-flex flex-column">

    <lesson-navigation v-if="isCourseSelected" class="w-100" :in-lesson="isLessonSelected" />

    <transition name="curtain" :duration="200">
      <lesson-selection v-if="!isLessonSelected" class="flex-grow-1 w-100" />
    </transition>

    <transition name="fade" :duration="200">
      <lesson v-if="isLessonSelected" class="flex-fill" :lesson="selected.lesson" />
    </transition>

  </div>
</template>

<script>
import LessonNavigation from "../../components/Lessons/LessonNavigation.vue";
import LessonSelection from "../../components/Lessons/LessonSelection.vue";
import Lesson from '../../components/Lessons/Lesson.vue';
import _isEmpty from "lodash/isEmpty";

export default {

  components: {
    Lesson,
    LessonNavigation,
    LessonSelection
  },

  mounted() { this.handleHardlinkNavigation() },

  computed: {
  	isOnlineBuild() { return this.$store.getters['app/isOnline']; },
  	isHybridBuild() { return this.$store.getters['app/isHybrid']; },

    hasSelected() {
      const selected = this.$store.getters.getSelected
      return {
        product: selected.productKey !== null,
        course: selected.course !== null,
        menu1: selected.menu1Index !== null,
        menu2: selected.menu2Index !== null,
        lesson: selected.lessonKey !== null
      }
    },

    selected() { return this.$store.getters.getSelected },

    isLessonSelected() { return this.hasSelected.lesson },

    isCourseSelected() { return this.hasSelected.product && this.hasSelected.course },
  },

  methods: {
    async handleHardlinkNavigation() {
    	const productKey = this.$route.params.product_key;
			const courseKey = this.$route.params.course_key.toUpperCase();
			const setProductCourse = !this.hasSelected.product && !this.hasSelected.course;
			const setLesson = this.$route.name === 'App:Lessons:Lesson' && !this.hasSelected.lesson;
			if (setProductCourse) {
				// We expect that only in an online version of the app will there be a possibility of accessing
		    // tls resouces directly via the URL. In such case, we should check for the validity of the path params
				const validProductKey = this.isOnlineBuild ? this.$store.getters['products/isProductKeyValid'](productKey) : true;
				const userAllowedToViewCourse = this.isOnlineBuild
					? (this.$store.getters['userCourses/getCourseId'](courseKey) !== -1) && !this.$store.getters['userCourses/isCourseExpired'](courseKey)
					: true;
				if (!validProductKey || !userAllowedToViewCourse) { this.$router.push({ name: 'App:Profile' }); }
				await this.selectProductCourse(productKey, courseKey, setLesson);
			}

			if (setLesson) {
				const lessonId = parseInt(this.$route.params.lesson_id);
		    
		    // We expect that only in an online version of the app will there be a possibility of accessing
		    // tls resouces directly via the URL. In such case, we should check for the validity of the path params 
		    if (this.isOnlineBuild) {
		    	const lessonBelongsToProduct = this.$store.getters.isLessonInProductSkeleton(lessonId);
		      const userAllowedToViewLesson = this.$store.getters.isAllowedToView(lessonId);
		    	if (!lessonBelongsToProduct || !userAllowedToViewLesson) {
				  	// Navigation to lesson page is canceled, resetting app state 'navigatingToLessonViaHardlink' prop
						this.$store.commit('app/setNavigatingToLessonViaHardlink', false);
				  	this.$router.push({ name: 'App:Profile' });
				  }
		    }
		    
				const lessonKey = this.$store.getters.getLessonCode(lessonId);
				const lessonMenuEntries = this.$store.getters.getLessonMenuEntries(lessonKey);
				const sessionDetails = this.restoreLastUserSessionDetails(productKey, courseKey, lessonKey);
				let menu1Index = lessonMenuEntries.menu1.index;
				let menu2Index = lessonMenuEntries.menu2.index;
				let previous = sessionDetails.previous;
				if (menu1Index === -1 && menu2Index === -1) {
					menu1Index = sessionDetails.menu1Index;
					menu2Index = sessionDetails.menu2Index;
				}
				
				if (!(menu1Index >= 0 || menu2Index >= 0)) {
					const lessonDetails = this.setLessonDetailsUsingMainLesson(lessonId);
					menu1Index = lessonDetails.menu1Index;
					menu2Index = lessonDetails.menu2Index;
					previous = lessonDetails.previous;
				}
				
				// Select lesson
				this.selectLesson({ menu1Index, menu2Index, lessonKey });
				this.$store.commit('setPreviousLessonProps', previous);
				
				// Navigation to lesson page is completed, resetting app state 'navigatingToLessonViaHardlink' prop
				this.$store.commit('app/setNavigatingToLessonViaHardlink', false);
			}
    },
    
    async selectProductCourse(productKey, courseKey, setLesson) {
      try {
    		this.$store.commit('app/setNavigatingToLessonViaHardlink', true);
    		
    		if (this.isOnlineBuild) {
		  		// Fetch product skeleton from server
		      await this.$store.dispatch("products/fetchProductSkeleton", productKey.toLowerCase());
		      
		      // We expect that only in an online version of the app will there be a possibility of accessing
		    	// tls resouces directly via the URL. In such case, we should check for the validity of the path params
		      const courseBelongsToProduct = this.$store.getters.isCourseInProductSkeleton(courseKey);
					if (!courseBelongsToProduct) { this.$router.push({ name: 'App:Profile' }); }
					
					// Fetch course progress from server
				  const courseId = this.$store.getters["userCourses/getCourseId"](courseKey);
				  await this.$store.dispatch("progress/fetchLastNextToStudy", courseId);
				}
				
				// Select course
				this.$store.commit('selectProductCourse', { productKey, courseKey });
				
				if (this.isHybridBuild) {
					// Fetch course progress from server
				  const courseId = this.$store.getters["userCourses/getCourseId"](courseKey);
				  await this.$store.dispatch("progress/fetchLastNextToStudy", courseId);
				}
    	} catch (e) {
    		console.log('[Product.view.vue]: Error while selecting product or/and course');
    		console.log(e);
     		this.$router.push({ name: 'App:Profile' });
    	} finally {
    		// Navigation to course completed, resetting app state 'navigatingToLessonViaHardlink' prop
				if (!setLesson) { this.$store.commit('app/setNavigatingToLessonViaHardlink', false); }
    	}
    },
    
    restoreLastUserSessionDetails(productKey, courseKey, lessonKey) {
    	const sessionDetails = { 
				menu1Index: -1, 
				menu2Index: -1, 
				previous: { key: null, isMainLesson: false, mainLessonPageNum: null, hasAssignment: false } 
			}
    	let lastUserSession = localStorage.getItem("contentState");
			
			if (lastUserSession) {
				lastUserSession = JSON.parse(lastUserSession);
				if (lastUserSession.productKey === productKey && lastUserSession.courseKey === courseKey && lastUserSession.lessonKey === lessonKey) {
					sessionDetails.menu1Index = lastUserSession.menu1Index;
					sessionDetails.menu2Index = lastUserSession.menu2Index;
				}
				if (lastUserSession.previous.key) {
					sessionDetails.previous = lastUserSession.previous;
				}
			}
			return sessionDetails;
    },
    
    setLessonDetailsUsingMainLesson(lessonId) {
    	const mainLessonDetails = this.$store.getters.getMainLessonDetails(lessonId);
    	const previous = { key: mainLessonDetails.code, isMainLesson: true, mainLessonPageNum: mainLessonDetails.mainLessonPageNum, hasAssignment: mainLessonDetails.hasAssignment };
    	return { menu1Index: mainLessonDetails.menu1Index, menu2Index: mainLessonDetails.menu2Index, previous };
    },

    selectLesson(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },
  }
}
</script>
