<template>
  <div class="wrapper d-flex flex-fill" style="background-color: #F2F2F2">
    <!-- Left column -->
    <div v-if="!isLayoutLimited" class="left-column d-flex flex-column align-items-center justify-content-between" style="flex: 6%">
      <div class="logo"><img src="../../../../assets/images/logo-watermark.png" alt="logo-watermark" class="mw-100 h-auto"/></div>

      <button v-if="currentlyViewingExtra" class="primary-btn-rounded px-2 py-1 position-absolute" @click="navigateToLesson" style="top: 2.5%; left: 16%">
        {{ $t("telestia.button.lesson") }}<font-awesome-icon :icon="['fas', 'book']" class="ml-1"/>
      </button>
    </div>

    <!-- Lesson extras -->
    <div v-if="currentlyViewingExtra" class="lesson-extras d-flex px-3" style="flex: 95%; max-height: 100%">
    	<b-tabs class="d-flex flex-column" content-class="pt-4 h-100" style="flex: 95%; max-height: 95%" align="center">
        <b-tab
        	v-for="(extra, index) in otherExtras"
        	:key="`${extra.title}-${index}`"
        	:title="extra.title"
        	title-link-class="border border-2 shadow"
        	@click="navigateToExtra(index)"
        	:active="activeExtraTabIndex === index"
        >
        	<lesson-extra-technical-drawing v-if="currentlyViewingExtra.type.toLowerCase() === 'td'" :title="selectedLesson.title" :figures="figures" :data="currentlyViewingExtra.data" />
      		<lesson-extra-combined v-else :title="currentlyViewingExtra.title" :data="currentlyViewingExtra.data" />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Lesson content -->
    <template v-else>
      <!-- Middle column -->
      <div class="middle-column d-flex flex-column px-3" style="flex: 65%; max-height: 90%">
        <div class="tabbed-interface d-flex" style="flex: 100%; max-height: 100%">
          <template v-if="emptyVideoContent">
            <div class="page d-flex justify-content-center align-items-center" style="flex: 100%; max-height: 100%">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
              <span class="ml-3" style="font-size: 20px">{{ $t("telestia.message.missing_content") }}</span>
            </div>
          </template>

          <template v-else>
            <b-tabs class="d-flex flex-column position-relative" content-class="mh-100 h-94" style="flex: 100%; max-height: 100%" align="center">
              <b-tab v-if="video3dExists" title="3D" title-link-class="border border-2 shadow" @click="tabClick('video3d')" :active="activeTab === 'video3d'">
                <div class="mh-100 video fixed-controls no-volume no-mute" id="video3d-container"></div>
              </b-tab>
              <b-tab v-if="videoExists" title="Video" title-link-class="border border-2 shadow" @click="tabClick('video')" :active="activeTab === 'video'">
                <div class="mh-100 video fixed-controls no-volume no-mute" id="video-container"></div>
              </b-tab>

              <!-- Extras -->
              <template v-if="!isLayoutLimited">
                <button v-if="hasExtras.other && !currentlyViewingExtra" class="primary-btn-rounded px-2 py-1 position-absolute" @click="navigateToExtra(0)" style="top: 0.5%; left: 13.5%;">
                  {{ $t("telestia.button.extras") }}<font-awesome-icon :icon="['fas', 'star']" class="ml-1"/>
                </button>
                <lesson-extra-measurement v-if="hasExtras.measurements" :extras="measurementExtras" class="position-absolute" style="bottom: 10px; left: 10px;" />
              </template>

            </b-tabs>
          </template>

        </div>

        <div :style="{ 'flex': isLayoutLimited ? '20%' : '8%', 'max-height': isLayoutLimited ? '20%' : '8%' }">
          <div class="tls-video-controls--container d-flex justify-content-center position-relative">
            <div v-if="mainLesson" class="tls-video-controls d-flex justify-content-center position-relative w-75">
                <button
                  v-if="mainLesson"
                  class="tls-video-button-main-lesson position-absolute"
                  :title="$t('telestia.hover_text.back_to_mlfp')"
                  @click="backToMainLesson()"
                  style="top: 5px; left: 15px;"
                >
                  <font-awesome-icon :icon="['fas', 'th-large']" style="font-size: 1.8rem;" />
                </button>
                &nbsp;
            </div>
          </div>
        </div>

      </div>

		  <!-- Right column -->
		  <div class="right-column d-flex flex-column px-3" style="flex: 30%; max-height: 100%;">
		    <div class="d-flex flex-column justify-content-between" style="flex: 90%; max-height: 90%;">
		      <div class="textpanel d-flex flex-column">
		        <div class="titlewrapper container px-0 mb-4">
		          <div class="row">
		            <div class="code col-auto d-flex justify-content-center align-items-center">
		              <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
		            </div>
		            <div class="title col d-flex flex-column">
		              <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
		              <span style="border-bottom: 1px solid #1b96cf"/>
		            </div>
		          </div>
		        </div>
		        <div v-if="!emptyVideoContent && sidebarText" class="textwrapper mb-2" style="overflow-y: auto;">
		          <div class="title font-weight-bold" v-html="sidebarText.text1"></div>
		          <div class="subtitle font-weight-bold font-italic" v-html="sidebarText.text2"></div>
		          <div class="text" v-html="sidebarText.text3"></div>
		        </div>
		      </div>

		      <div v-if="!emptyVideoContent" class="figures">
		        <img v-for="(figure, index) in figures" :alt="`figure-${figure.name}`" :key="index" :src="figure.src"
		        />
		      </div>
		    </div>

		    <div v-if="!isOfflineBuild && !emptyVideoContent" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
          <div class="d-flex">
		        <button	class="action-button px-0 mr-2" :class="{ 'disabled': !packagePdf }" :title="$t('telestia.hover_text.download_lp')" @click="downloadPackagePdf">
		          <font-awesome-icon :icon="['fas', 'box']" size="2x" />
		        </button>
		        <button
		          class="action-button px-0"
		          :class="{ 'disabled': !selectedLesson.hasAssignment }"
		          :title="$t('telestia.hover_text.upload_assignment')"
		          @click="$router.push({ name: 'App:Assignments' })"
		        >
		          <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
		        </button>
		      </div>

          <mark-as-complete :completed="completed" :lessonid="lessonId" />
        </div>
		  </div>
		</template>

    <!-- Extras -->
    <template v-if="isLayoutLimited">
      <lesson-extra-measurement v-if="hasExtras.measurements && !currentlyViewingExtra" :extras="measurementExtras" class="extras-measurements-limited" />
      <template v-if="hasExtras.other">
        <div v-if="!currentlyViewingExtra" class="extras-button-limited" @click="navigateToExtra(0)"><font-awesome-icon :icon="['fas', 'star']"/></div>
        <div v-else class="extras-button-limited" @click="navigateToLesson"><font-awesome-icon :icon="['fas', 'book']"/></div>
      </template>
    </template>

  </div>
</template>

<script>
import flowplayer from 'flowplayer/dist/flowplayer.js';
import 'flowplayer/dist/skin/skin.css';
import assets from "../../../../shared/helpers/assets";
import lessons from '../../../../shared/helpers/lessons';
import { sleep } from "../../../../shared/utils/generic";
import LessonExtraTechnicalDrawing from '../Extras/LessonExtraTechnicalDrawing.vue';
import LessonExtraCombined from '../Extras/LessonExtraCombined.vue';
import LessonExtraMeasurement from '../Extras/LessonExtraMeasurement.vue';
import MarkAsComplete from '../../shared/MarkAsComplete.vue';
import envConfig from '../../../../shared/configs/config.local.js';
import _isEmpty from "lodash/isEmpty";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  name: 'player-stream',

  components: { LessonExtraTechnicalDrawing, LessonExtraCombined, LessonExtraMeasurement, MarkAsComplete },

  props: [ 'selectedLesson', 'completed', 'mainLesson' ],

  mounted() { this._setup() },

  // Local data
  data() {
    return {
      activeTab: null,
      position: 0,
      players: {
        video: null,
        currentTime: 0,
        video3d: null,
        currentTime3d: 0
      },

			figures: [],
      currentlyViewingExtra: null,
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline']; },

    isOnlineBuild() { return this.$store.getters['app/isOnline']; },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

    packagePdf() { return this.$store.getters.getPackagePdf(this.selectedLesson.key); },

    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },

    videoExists() {
      return this.selectedLesson.video.video.source;
    },

    video3dExists() {
      return this.selectedLesson.video.video3d.source;
    },

    emptyVideoContent() {
      return !this.selectedLesson.video.video.source && !this.selectedLesson.video.video3d.source;
    },

    sidebarText() {
      if (!this.activeTab) { return ''; }
      if (!this.selectedLesson.video[this.activeTab].texts || Object.keys(this.selectedLesson.video[this.activeTab].texts).length === 0) { return ''; }

      const translatedText = this.selectedLesson.video[this.activeTab]?.texts[this.position]?.texts[this.selectedLanguage]
      return translatedText ? translatedText : this.selectedLesson.video[this.activeTab]?.texts[this.position]?.texts.en;
    },

    hasExtras() {
      const hasExtras = { measurements: false, other: false };
      if (!this.selectedLesson.extras || !this.selectedLesson.extras.tabs) { return hasExtras ;}

      const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
      if (this.selectedLesson.extras.tabs.some(t => measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.measurements = true; }
      if (this.selectedLesson.extras.tabs.some(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.other = true; }
      return hasExtras;
    },

    measurementExtras() {
      if (!this.hasExtras.measurements) { return []; }

  		const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
  		return this.selectedLesson.extras.tabs
        .filter(e => measurementsExtrasTypes.includes(e.type.toLowerCase()))
        .map(e => { return { type: e.type.toLowerCase(), title: e.title } });
  	},

    otherExtras() {
      if (!this.hasExtras.other) { return []; }

  		const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
  		return this.selectedLesson.extras.tabs.filter(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()));
  	},

    activeExtraTabIndex() { return this.otherExtras.findIndex(e => e === this.currentlyViewingExtra) },
  },

  methods: {
    async _setup() {
      try {
		    if(!this.emptyVideoContent) {
		    	await this._fetchFiguresImages()

		      let self = this;
		      if (!this.activeTab) { this.activeTab = this.video3dExists ? 'video3d' : 'video'; }

		      flowplayer.conf.embed = false;
		      flowplayer.conf.share = false;
		      flowplayer.conf.adaptiveRatio = true;

		      if (this.videoExists) {
		        const videoUrl = this.isOnlineBuild
		        	? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.selectedLesson.video.video.source}&language=${this.selectedLanguage}`
		        	: this.getVideoURL(this.selectedLesson.folder, this.selectedLesson.video.video.source)
		        
		        if (this.players.video) { this.players.video.shutdown() }
		        this.players.video = flowplayer('#video-container', {
		          clip: {
		            sources: [{ type: 'video/mp4', src: videoUrl }]
		          }
		        }).bind('ready', function(e, api) {
		        	api.seek(self.players.currentTime);
		        }).bind('progress', function(e, api, pos) {
		        	self.players.currentTime = pos;
		          self.getPosition(pos);
		        });
		      }

		      if (this.video3dExists) {
		      	const video3dUrl = this.isOnlineBuild
		        	? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.selectedLesson.video.video3d.source}&language=${this.selectedLanguage}`
		        	: this.getVideoURL(this.selectedLesson.folder, this.selectedLesson.video.video3d.source)
		        
		        if (this.players.video3d) { this.players.video3d.shutdown() }
		        this.players.video3d = flowplayer('#video3d-container', {
		          clip: {
		            sources: [{ type: 'video/mp4', src: video3dUrl }]
		          }
		        }).bind('ready', function(e, api) {
		        	api.seek(self.players.currentTime3d);
		        }).bind('progress', function(e, api, pos) {
		          self.players.currentTime3d = pos;
		          self.getPosition(pos);
		        })
		      }
		    }
		  } catch (e) {
		  	console.log(e);
		  }
    },

    async _fetchFiguresImages() {
    	try {
    		const figures = this.selectedLesson.video.figures.map(f => { return { name: f }; });
    		this.figures = await this._fetchImageSources(figures);

    		return Promise.resolve();
    	} catch (e) {
    		console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.figures') + "\n" + this.$t('telestia.error.connection'),
        });
    		return Promise.resolve();
    	}
    },

    async _fetchImageSources(images) {
      try {
        if (!this.isOnlineBuild) {
          images.forEach(img => img.src = this.getImageBase64(this.selectedLesson.folder, img.name))
          return Promise.resolve(images);
        }

        const requests = [];
        for (let img of images) {
          requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product: this.selectedLesson.folder, title: img.name }));
        }
        const results = await Promise.allSettled(requests);

        images.forEach((img, index) => {
          if (results[index].status !== 'rejected') { img.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { img.src = this.getImageBase64(''); }
        })

        return Promise.resolve(images);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    backToMainLesson() {
      const menuItems = {
        menu1Index: this.mainLesson.menu1.index,
        menu2Index: this.mainLesson.menu2.index,
        lessonKey: this.mainLesson.code
      };

      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? menuItems : { ...menuItems, productSkeleton  };
      this.$store.commit('selectMenu', payload);
      this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(payload.lessonKey) } });
    },

    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    getImageBase64(folder, imageName) {
      return assets.getImageData(folder, imageName);
    },

    getVideoURL(folder, videoName) {
      return assets.getFileURL(folder, videoName);
    },

    tabClick(tab) {
      this.activeTab = tab === 'video' ? 'video' : 'video3d';
      if (this.videoExists && this.video3dExists) { this.players[tab === 'video' ? 'video3d' : 'video'].pause(); }
    },

    getPosition(pos) {
    	this.position =
          this.selectedLesson.video[this.activeTab].texts.findIndex(item => (parseInt(item.from) <= pos && parseInt(item.to) > pos));
      return this.position;
    },

    navigateToExtra(index) { this.currentlyViewingExtra = this.otherExtras[index]; },

    async navigateToLesson() {
  		this.currentlyViewingExtra = null
  		await sleep(100);
      this._setup();
  	},

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
    selectedLesson() {
      this._setup();
    }
  }
}
</script>

<style scoped>

/*----------------------------------------------------------------------------------------------*/
/* TEXT PANEL */

.title-text {
  font-size: 18px;
}

.textpanel {
	overflow-x: hidden;
	overflow-y: auto;
}

.extras-button-limited {
  position: absolute;
  bottom: 5%;
  padding: 10px 15px;
  z-index: 999;
  border-radius: 10px;
  background-color: #1b96cf;
  color: white;
  font-size: 16px;
}

.extras-measurements-limited {
  position: absolute;
  bottom: 18%;
}
</style>

<style>
/*----------------------------------------------------------------------------------------------*/
/* TABS */

.tab-pane {
	height: 100%;
}

.h-94 {
  height: 94%;
}



.tls-video-controls {
  padding-left: 13px;
  padding-right: 13px;
  background: #1b96cf;
  border-radius: 50px;
  direction: ltr !important;
  min-height: 36px;
  height: fit-content;
}

.tls-video-button-main-lesson {
  color: white;
  background-color: #1b96cf;
  border: none;
  outline: 0;
}
.tls-video-button-main-lesson:hover { color: #fec801; }

/*----------------------------------------------------------------------------------------------*/
/* VIDEO PLAYER */

.fp-player a {
  z-index: -1 !important;
}
	
.fp-player a[href*=flowplayer][style*=absolute],
.fp-player .fp-context-menu,
.fp-player a.fp-share {
  width: 0px !important;
  height: 0px !important;
  overflow: hidden !important;
  z-index: -1 !important;
}
</style>
