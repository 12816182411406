<template>
  <div class="wrapper d-flex flex-fill position-relative py-1" style="background-color: #F2F2F2">
    <!-- Left column -->
    <div v-if="!isLayoutLimited" class="left-column d-flex flex-column align-items-center justify-content-between" style="flex: 6%">
      <img src="../../../../assets/images/logo-watermark.png" alt="logo" class="logo-watermark mw-100 h-auto">

      <button v-if="currentlyViewingExtra" class="primary-btn-rounded px-2 py-1 position-absolute" @click="navigateToLesson" style="top: 2%; left: 13%">
        {{ $t("telestia.button.lesson") }}<font-awesome-icon :icon="['fas', 'book']" class="ml-1"/>
      </button>
    </div>

    <!-- Lesson extras -->
    <div v-if="currentlyViewingExtra" class="lesson-extras d-flex px-3" style="flex: 95%; max-height: 100%">
      <b-tabs class="d-flex flex-column" content-class="pt-4 h-100" style="flex: 95%; max-height: 95%" align="center">
        <b-tab
          v-for="(extra, index) in otherExtras"
          :key="`${extra.title}-${index}`"
          :title="extra.title"
          title-link-class="border border-2 shadow"
          @click="navigateToExtra(index)"
          :active="activeExtraTabIndex === index"
        >
          <lesson-extra-technical-drawing v-if="currentlyViewingExtra.type.toLowerCase() === 'td'" :title="selectedLesson.title" :figures="figures" :data="currentlyViewingExtra.data" />
          <lesson-extra-combined v-else :title="currentlyViewingExtra.title" :data="currentlyViewingExtra.data" />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Lesson content -->
    <template v-if="!currentlyViewingExtra">

      <!-- Middle column -->
      <div
        class="middle-column d-flex flex-column"
        style="flex: 65%; max-height: 100%"
        :style="{ 'overflow-y': showTranslationSection ? 'auto' : 'unset', 'overflow-x': 'unset' }"
      >
        <template v-if="emptyLessonContent">
          <div class="page d-flex justify-content-center align-items-center" style="flex: 100%; max-height: 100%">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
            <span class="ml-3" style="font-size: 20px;">{{ $t("telestia.message.missing_content") }}</span>
          </div>
        </template>

        <template v-else>
          <div
            class="canvas-container d-flex justify-content-center align-items-center px-3 position-relative"
            :style="{ 'overflow': showTranslationSection ? 'unset' : 'unset', 'flex': isLayoutLimited ? '80%' : '92%', 'max-height': isLayoutLimited ? '80%' : '92%' }"
          >
            <canvas id="tls-video-canvas" class="tls-video-canvas" style="border: none;"></canvas>

            <!-- Extras -->
            <template v-if="!isLayoutLimited">
              <button id="button-extra" v-if="hasExtras.other && !currentlyViewingExtra" class="primary-btn-rounded px-2 py-1 position-absolute p-rotate-90" @click="navigateToExtra(0)" style="left: 10%;">
                {{ $t("telestia.button.extras") }}<font-awesome-icon :icon="['fas', 'star']" class="ml-1"/>
              </button>
              <lesson-extra-measurement id="lesson-extra-measurement" v-if="hasExtras.measurements" :extras="measurementExtras" class="position-absolute" style="bottom: 25px; left: -15px;" />
            </template>

          </div>

          <div :style="{ 'flex': isLayoutLimited ? '20%' : '8%', 'max-height': isLayoutLimited ? '20%' : '8%' }">
            <div class="tls-video-tracker--container d-flex justify-content-center mb-1">
              <div class="tls-video-tracker position-relative w-75" @mousedown="_toggleTrackerTooltip" @mouseup="_toggleTrackerTooltip">
                <div class="tooltip-container">
                  <div class="slider-tooltip"></div>
                </div>
                <input
                  class="steps-tracker-slider w-100"
                  :class="{ 'disabled': !hasStops }"
                  type="range"
                  min="0" :max="selectedLesson.newVideo.stops.length-1" step="1"
                  v-model="trackerValue"
                  @change="_updateStop"
                >
              </div>
            </div>
            <div class="tls-video-controls--container d-flex justify-content-center position-relative">
              <button
                v-if="showTranslationButton"
                class="primary-btn py-2 px-3 position-absolute"
                @click="startTranslationProcess"
                style="top: 0px; left: 0px;"
              >{{ $t("telestia.button.translate") }}</button>
              <button
                v-if="showTranslationButton && lsEntryExists"
                class="primary-btn py-2 px-3 position-absolute"
                @click="clearStorage"
                style="top: -50px; left: 0px;"
              >{{ $t("telestia.button.clear") }}</button>

              <div class="tls-video-controls d-flex justify-content-center position-relative w-75" :class="{'first': isFirststop, 'last': isLaststop}" style="height: fit-content;">
                <button
                  v-if="mainLesson"
                  class="tls-video-button-main-lesson position-absolute"
                  :title="$t('telestia.hover_text.back_to_mlfp')"
                  @click="backToMainLesson()"
                  style="top: 5px; left: 15px;"
                >
                  <font-awesome-icon :icon="['fas', 'th-large']" style="font-size: 1.8rem;" />
                </button>
                <button class="tls-video-button tls-video-button-begin" :title="$t('telestia.hover_text.start_again')" @click="begin()"></button>
                <button class="tls-video-button tls-video-button-prev" :title="$t('telestia.hover_text.previous_step')" @click="prev()"></button>
                <button class="tls-video-button tls-video-button-next" :title="$t('telestia.hover_text.play_to_next')" @click="playNext()"></button>
                <button class="tls-video-button tls-video-button-play" :title="$t('telestia.hover_text.next_step')" @click="next()"></button>
                <button
                  class="tls-video-button"
                  :class="{'tls-video-button-pause': isPlaying, 'tls-video-button-playall': !isPlaying}"
                  :title="$t('telestia.hover_text.play_all')"
                  @click="playAll()"
                ></button>
                
                <span v-if="hasStops" class="position-absolute text-white" style="top: 10px; right: 10px;">
                  {{ currentStopIndex + 1 }}/{{ selectedLesson.newVideo.stops.length }}
                </span>
              </div>
            </div>
          </div>

          <translation
            v-if="showTranslationSection"
            :current-stop="this.currentStopTranslationHelper"
            :lesson-id="selectedLesson.dbid"
            :stop-index="currentStopIndex"
            @update="mockCurrentStopUpdate"
            @close="endTranslationProcess"
          ></translation>
        </template>
      </div>

      <!-- Right column -->
      <div class="right-column d-flex flex-column px-0" style="flex: 30%; max-height: 100%;">
        <div class="d-flex flex-column justify-content-between" style="flex: 90%; max-height: 90%;">
          <div class="tls-html-textpanel d-flex flex-column">
            <div class="titlewrapper container px-0 mb-3">
              <div class="row">
                <div class="code col-auto d-flex justify-content-center align-items-center">
                  <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
                </div>
                <div class="title col d-flex flex-column">
                  <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
                  <span style="border-bottom: 1px solid #1b96cf"/>
                </div>
              </div>
            </div>
            <div v-if="!emptyLessonContent" class="stepswrapper" style="overflow-y: auto;">
              <div class="tls-video-title font-weight-bold mb-1" v-html="sidebarText.text1"></div>
              <div class="tls-video-subtitle mb-1" v-html="sidebarText.text2"></div>
              <div class="text-wrapper">
                <div class="tls-video-text" v-html="sidebarText.text3"></div>
              </div>
            </div>
          </div>

          <div v-if="!emptyLessonContent && !isLayoutLimited" class="figures">
            <img v-for="(figure, index) in figures" :alt="`figure-${figure.name}`" :key="index" :src="figure.src"/>
          </div>
        </div>

        <div v-if="!isOfflineBuild && !emptyLessonContent" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
          <div class="d-flex">
            <button	class="action-button px-0 mr-2" :class="{ 'disabled': !packagePdf }" :title="$t('telestia.hover_text.download_lp')" @click="downloadPackagePdf">
              <font-awesome-icon :icon="['fas', 'box']" size="2x" />
            </button>
            <button
              class="action-button px-0"
              :class="{ 'disabled': !hasAssignment }"
              :title="$t('telestia.hover_text.upload_assignment')"
              @click="$router.push({ name: 'App:Assignments' })"
            >
              <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
            </button>
          </div>

          <mark-as-complete :completed="completed" :lessonid="lessonId" />
        </div>
      </div>

    </template>

    <!-- Extras -->
    <template v-if="isLayoutLimited">
      <lesson-extra-measurement v-if="hasExtras.measurements && !currentlyViewingExtra" :extras="measurementExtras" class="extras-measurements-limited" />
      <template v-if="hasExtras.other">
        <div v-if="!currentlyViewingExtra" class="extras-button-limited" @click="navigateToExtra(0)"><font-awesome-icon :icon="['fas', 'star']"/></div>
        <div v-else class="extras-button-limited" @click="navigateToLesson"><font-awesome-icon :icon="['fas', 'book']"/></div>
      </template>
    </template>

  </div>
</template>


<script>
import assets from '../../../../shared/helpers/assets';
import lessons from '../../../../shared/helpers/lessons';
import { sleep } from "../../../../shared/utils/generic";
import Translation from "./Translation.vue";
import LessonExtraCombined from '../Extras/LessonExtraCombined.vue';
import LessonExtraMeasurement from '../Extras/LessonExtraMeasurement.vue';
import LessonExtraTechnicalDrawing from "../Extras/LessonExtraTechnicalDrawing";
import MarkAsComplete from '../../shared/MarkAsComplete.vue';
import _isEmpty from "lodash/isEmpty";
import envConfig from "../../../../shared/configs/config.local";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  name: 'player-video',

  props: [ 'selectedLesson', 'completed', 'mainLesson' ],

  components: {
    Translation,
    LessonExtraCombined,
    LessonExtraMeasurement,
    LessonExtraTechnicalDrawing,
    MarkAsComplete
  },

  mounted() {
    this._setup();
    window.addEventListener('resize', this._setSizes);
  },

  beforeDestroy() { window.cancelAnimationFrame(this.requestId); },

  data() {
    return {
      imageTooltips: [],
      figures: [],
      isPlaying: false,
      isFirststop: true,
      isLaststop: false,

      currentStopIndex: 0,
      trackerValue: 0,

      translationInProgress: false,
      currentlyViewingExtra: null
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    showTranslationButton() { return this.$store.getters["user/isAdmin"] && this.isOnlineBuild && !this.translationInProgress && !this.isLayoutLimited; },

    showTranslationSection() { return !!this.translationInProgress; },

    hasStops() { return this.selectedLesson.newVideo.stops.length > 0; },

    hasAssignment() { return this.selectedLesson.hasAssignment || (this.mainLesson && this.mainLesson.hasAssignment); },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

    hasExtras() {
      const hasExtras = { measurements: true, other: false };
      if (!this.selectedLesson.extras || !this.selectedLesson.extras.tabs) { return hasExtras; }

      const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
      if (this.selectedLesson.extras.tabs.some(t => measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.measurements = true; }
      if (this.selectedLesson.extras.tabs.some(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.other = true; }
      return hasExtras;
    },

    measurementExtras() {
      //if (!this.hasExtras.measurements) { return []; }
      //Show measurements tables only in PM
      if ((this.selectedLesson.folder||'').toUpperCase()!='PM') return [];
      
      //Force all tables
      return [{
        type: 'ca',
        title: 'Case A'
      },{
        type: 'cb',
        title: 'Case B'
      },{
        type: 'cc',
        title: 'Case C'
      },{
        type: 'cd',
        title: 'Case D'
      },{
        type: 'measurements',
        title: 'Measurements Table'
      }];
      
      /*
      const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
      return this.selectedLesson.extras.tabs
        .filter(e => measurementsExtrasTypes.includes(e.type.toLowerCase()))
        .map(e => { return { type: e.type.toLowerCase(), title: e.title } });
      */
    },

    otherExtras() {
      if (!this.hasExtras.other) { return []; }

      const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
      return this.selectedLesson.extras.tabs.filter(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()));
    },

    activeExtraTabIndex() { return this.otherExtras.findIndex(e => e === this.currentlyViewingExtra) },

    packagePdf() {
      if (this.mainLesson) { return this.$store.getters.getPackagePdf(this.mainLesson.code); }
      return this.$store.getters.getPackagePdf(this.selectedLesson.key);
    },

    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },

    emptyLessonContent() {
      return !this.selectedLesson.newVideo.source
    },

    sidebarText() {
      if (!this.hasStops) { return {}; }

      const savedTranslations = JSON.parse(localStorage.getItem(`${this.selectedLesson.dbid}`));
      const translatedText = savedTranslations && savedTranslations[this.currentStopIndex]
        ? savedTranslations[this.currentStopIndex].texts[this.selectedLanguage]
        : this.selectedLesson.newVideo.stops[this.currentStopIndex].texts[this.selectedLanguage];

      if (translatedText) { return translatedText; }
      return savedTranslations && savedTranslations[this.currentStopIndex]
        ? savedTranslations[this.currentStopIndex].texts.en
        : this.selectedLesson.newVideo.stops[this.currentStopIndex].texts.en;
    },

    currentStopTranslationHelper() {
      if (!this.hasStops) { return {}; }

      const savedTranslations = JSON.parse(localStorage.getItem(`${this.selectedLesson.dbid}`));
      if (!savedTranslations) { return {}; }

      if (savedTranslations[this.currentStopIndex]) { return savedTranslations[this.currentStopIndex]; }
      return this.selectedLesson.newVideo.stops[this.currentStopIndex];
    },

    lsEntryExists() {
      return localStorage.getItem(`${this.selectedLesson.dbid}`);
    }
  },

  methods: {
    async fetchFiguresImages() {
      try {
        const figures = this.selectedLesson.figures.map(f => { return { name: f }; });
        this.figures = await this.fetchImageSources(figures);

        return Promise.resolve();
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.figures') + "\n" + this.$t('telestia.error.connection'),
        });
        return Promise.resolve();
      }
    },

    async fetchImageSources(images) {
      try {
        if (!this.isOnlineBuild) {
          images.forEach(img => img.src = this.getImageBase64(this.selectedLesson.folder, img.name))
          return Promise.resolve(images);
        }

        const requests = [];
        for (let img of images) {
          requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product: this.selectedLesson.folder, title: img.name }));
        }
        const results = await Promise.allSettled(requests);

        images.forEach((img, index) => {
          if (results[index].status !== 'rejected') { img.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { img.src = this.getImageBase64(''); }
        })

        return Promise.resolve(images);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    getImageBase64(folder, imageName) {
      return assets.getImageData(folder, imageName);
    },

    navigateToExtra(index) { this.currentlyViewingExtra = this.otherExtras[index]; },

    async navigateToLesson() {
      this.currentlyViewingExtra = null
      await sleep(100);
      this._setup();
    },

    backToMainLesson() {
      const menuItems = {
        menu1Index: this.mainLesson.menu1.index,
        menu2Index: this.mainLesson.menu2.index,
        lessonKey: this.mainLesson.code
      };

      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? menuItems : { ...menuItems, productSkeleton  };
      this.$store.commit('selectMenu', payload);
      this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(payload.lessonKey) } });
    },

    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    // Create (if not exists) local storage entry for current lesson to temporarily keep modified translations
    startTranslationProcess() {
      let savedTranslations = JSON.parse(localStorage.getItem(`${this.selectedLesson.dbid}`));
      if (!savedTranslations) {
        const translations = new Array(this.selectedLesson.newVideo.stops.length).fill(undefined);
        localStorage.setItem(`${this.selectedLesson.dbid}`, JSON.stringify(translations));
      }

      this.translationInProgress = true;
    },

    // Useful in order to trigger computed property sidebarText, after a new video translation is saved in local storage
    mockCurrentStopUpdate() {
      this.currentStopIndex--;
      this.currentStopIndex++;
    },

    endTranslationProcess() { this.translationInProgress = false; },

    clearStorage() {
      if (localStorage.getItem(`${this.selectedLesson.dbid}`)) {
        localStorage.removeItem(`${this.selectedLesson.dbid}`);
        this.$swal({ title: 'Local storage item cleared', text: '', icon: 'success', timer: 5000, button: true });
      }
    },

    // Go to beginning of video
    begin() {
      this.currentStopIndex = 0;
      this.pause();
    },

    // Go to previous stop
    prev() {
      if (this.currentStopIndex > 0) { this.currentStopIndex = !this.video.paused ? this._getStop() : this.currentStopIndex - 1; }
      this.pause();
    },

    // Go to next stop
    next() {
      if (!this.hasStops) { return; }

      if (this.currentStopIndex < this.selectedLesson.newVideo.stops.length - 1) { this.currentStopIndex++; }
      this.pause();
    },

    // Play video until the next stop
    playNext() {
      this.continuous = false;
      this.video.play();
      this._refresh();
    },

    // Play all video continuously or pause if is already playing
    playAll() {
      if (this.video.paused) {
        this.continuous = true;
        this.video.play();
      } else {
        this.video.pause();
      }
      this._refresh();
    },

    // Pause the video and go to the nearest stop
    pause() {
      this.video.pause();
      //this.gotoStop(this.currentStopIndex);
      this._refresh();
    },

    /**
      * Go to specific seconds in the video
      * @param {float} seconds
      */
    gotoSeconds(seconds) {
      this.video.currentTime = parseFloat(seconds);
    },

    /**
      * Go to specific stop in the video
      * @param {number} stop - Index of stop in the stops array
      */
    gotoStop(stop) {
      if (!this.hasStops) { return; }

      this.gotoSeconds(this.selectedLesson.newVideo.stops[stop].seconds);
    },

    /*-------------------------------------------------------------------------------*/
    /* private methods */
    /*-------------------------------------------------------------------------------*/

    // Setup the video player
    async _setup() {
      if (this.emptyLessonContent) { return; }

      try {
        await this.fetchFiguresImages();

        // get canvas and context
        this.canvas = this.$el.querySelector('canvas');
        this.canvas.width = document.querySelector('.canvas-container').clientWidth;
        this.canvas.height = document.querySelector('.canvas-container').clientHeight;
        this.ctx = this.canvas.getContext('2d');

        // get tooltip elements
        this.sliderTooltip = document.querySelector('.slider-tooltip');
        this.sliderTooltip.style.display = 'none';

        // create in-memory video
        this.video = document.createElement('video');
        this.video.src = this.isOnlineBuild
          ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.selectedLesson.newVideo.source}&language=${this.selectedLanguage}`
          : assets.getFileURL(this.selectedLesson.folder, this.selectedLesson.newVideo.source);

        const self = this;
        this.video.onloadedmetadata = function () {
          //set beginning
          self.gotoStop(self.currentStopIndex);
          self._setSizes();
        };

        this.helperVideo = document.createElement('video');
        this.helperVideo.src = self.video.src;
        this.helperVideo.preload = 'auto';
        this.helperVideo.crossOrigin = 'Anonymous';
        this.helperCanvas = document.createElement('canvas');
        this.helperContext = this.helperCanvas.getContext('2d');

        let index = 0;
        this.helperVideo.onseeked = (e) => {
          self.helperContext.drawImage(self.helperVideo, 0, 0);
          const dataURL = self.helperCanvas.toDataURL();
          self.imageTooltips.push(dataURL);

          if (index < self.selectedLesson.newVideo.stops.length - 1) {
          	index++;
          	self.helperVideo.currentTime = parseFloat(self.selectedLesson.newVideo.stops[index].seconds);
          } else {
          	self.drawTooltip(0);
          }
        };

        this.helperVideo.onloadedmetadata = () => {
          // resize canvas getting size from actual video
          self.helperCanvas.width = self.helperVideo.videoWidth - 1;
          self.helperCanvas.height = self.helperVideo.videoHeight - 1;

          if (self.hasStops) {
            self.helperVideo.currentTime = parseFloat(self.selectedLesson.newVideo.stops[0].seconds);
          }
        };

        //animation loop
        this.animationLoop = () => {
          self._draw();
          self._checkStop();
          window.cancelAnimationFrame(self.requestId);
          self.requestId = window.requestAnimationFrame(self.animationLoop);
        };
        this.requestId = window.requestAnimationFrame(this.animationLoop);
      } catch (e) {
        console.log(e);
      }
    },

    drawTooltip(index) {
      const img = document.createElement('img');
      img.width = "150";
      img.height= "150";
      if (this.imageTooltips[index]) {
      	img.setAttribute('src', this.imageTooltips[index]);
      } else {
      	img.setAttribute('src', require('../../../../assets/images/loading.png'));
      	img.setAttribute('style', 'background-color: white; padding: 50px;');
      }

      const currentStopSpan = document.createElement('span');
      currentStopSpan.innerHTML = parseInt(this.trackerValue) + 1;
      currentStopSpan.style.cssText = 'position: absolute; bottom: 5px; right: 10px';

      //append img in container div
      this.sliderTooltip.innerHTML = '';
      this.sliderTooltip.appendChild(img);
      this.sliderTooltip.appendChild(currentStopSpan);
    },

    // Refresh/update interface
    _refresh() {
      //set buttons status
      this.isPlaying = !this.video.paused;
      this.isFirststop = this.video.currentTime === 0 || this.currentStopIndex === 0;
      if (this.hasStops) { this.isLaststop = this.currentStopIndex >= this.selectedLesson.newVideo.stops.length - 1; }
    },

    // Draw current frame from the in-memory video to the canvas
    _draw() {
      const videoWidth = (this.canvas.height / this.video.videoHeight) * this.video.videoWidth;
      const dx = (this.canvas.width - videoWidth) / 2
      this.ctx.drawImage(this.video, dx, 0, videoWidth, this.canvas.height);
    },

    /**
      * Get current stop based on current video position
      * @returns {number} current stop index
      */
    _getStop() {
      const stops = this.selectedLesson.newVideo.stops;
      if (stops.length === 0) { return; }
      const ct = Math.round(this.video.currentTime * 100) / 100;

      for (let i = 0; i < stops.length; ++i) {
        if (parseFloat(stops[i].seconds) == ct) { return i; }
        if (parseFloat(stops[i].seconds) < ct && stops[i + 1] && ct < parseFloat(stops[i + 1].seconds)) { return i; }
      }
      if (parseFloat(stops[stops.length - 1].seconds) < ct) { return stops.length - 1; }
      return 0;
    },

    // Check if playback reached a stop
    _checkStop() {
      const idx = this._getStop();

      if (idx > -1 && idx > this.currentStopIndex) {
        this.currentStopIndex = idx;
        if (!this.continuous) this.pause();
      }
      this._refresh();
    },

    // Toggle tooltip visibility whenever the slider is used
    _toggleTrackerTooltip() {
      this.sliderTooltip.style.display === 'none' ? this.sliderTooltip.style.display = 'block' : this.sliderTooltip.style.display = 'none'
    },

    // Update current stop when slider emits change event
    _updateStop(e) {
      this.currentStopIndex = parseInt(e.target.value);
    },

    _setSizes() {
      let vc = document.getElementById('tls-video-canvas');
      let cc = document.querySelector('.canvas-container');
      if (!vc || !cc) return;

      //set canvas size
      vc.style.display = 'none';
      this.canvas.width = cc.clientWidth;
      this.canvas.height = cc.clientHeight;
      vc.style.display = 'block';
    
      if (this.video) {
        let dir = document.getElementById('app').classList.contains('rtl') ? 'right' : 'left';

        //reposition extras measurements tables
        let lem = document.getElementById('lesson-extra-measurement');
        let pos = (-vc.offsetLeft+Math.max((vc.width-((vc.height/this.video.videoHeight)*this.video.videoWidth))/2,0));
        if (lem) {
          lem.style.left = lem.style.right = '';
          lem.style[dir] =  pos + 'px';
        }

        //reposition extras button
        let be = document.getElementById('button-extra');
        if (be) {
          be.style.left = be.style.right = '';
          be.style[dir] = (pos - be.offsetHeight) + 'px';
          be.style.bottom = (parseInt(lem.style.bottom,10) + parseInt(getComputedStyle(lem,null).getPropertyValue('height'),10) + 30) + 'px';
        }
      }

    },

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }

  },

  watch: {
    selectedLesson() {
      this._setup();
    },

    currentStopIndex(n) {
      this.gotoStop(n);
      // Update slider v-model
      if (this.trackerValue !== n) { this.trackerValue = n; }
    },

    trackerValue(n) {
      const stepsTrackerSlider = document.querySelector('.steps-tracker-slider');
      const tooltipContainer = document.querySelector('.tooltip-container');
      const newValue = Number((n - stepsTrackerSlider.min) * 100 / (stepsTrackerSlider.max - stepsTrackerSlider.min));
      const newPosition = 10 - (newValue * 0.2);

      this.drawTooltip(n);

      tooltipContainer.style.left = `calc(${newValue}% + (${newPosition}px))`;
    }
  },
}
</script>

<style scoped>
.p-rotate-90 {
  transform: rotate(90deg);
  padding: 0;
  margin: 0;
}

.tls-video-subtitle {
  font-size: 1.1em;
}

/*----------------------------------------------------------------------------------------------*/
/* CANVAS */

canvas {
  border: 1px solid black;
}

.tls-html-textpanel {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.extras-button-limited {
  position: absolute;
  bottom: 5%;
  padding: 10px 15px;
  z-index: 999;
  border-radius: 10px;
  background-color: #1b96cf;
  color: white;
  font-size: 16px;
}

.extras-measurements-limited {
  position: absolute;
  bottom: 18%;
}


.tls-video-tracker {
  direction: ltr !important;
}

/*----------------------------------------------------------------------------------------------*/
/* VIDEO CONTROLS */

.tls-video-controls {
  padding-left: 13px;
  padding-right: 13px;
  background: #1b96cf;
  border-radius: 50px;
  direction: ltr !important;
}

.tls-video-controls .tls-video-button {
  position: relative;
  display: inline-block;
  background-image: url(../../../../assets/images/new-video/toolbar-down28px-white.png);
  background-color: #1b96cf;
  width: 33px;
  height: 28px;
  border: none;
  margin: 4px 20px;
  outline: 0;
  color: transparent;
  background-repeat: no-repeat;
}

.tls-video-button-main-lesson {
  color: white;
  background-color: #1b96cf;
  border: none;
  outline: 0;
}
.tls-video-button-main-lesson:hover { color: #fec801; }

.tls-video-controls .tls-video-button-begin {
  background-position: -1px 0;
}

.tls-video-controls .tls-video-button-prev {
  background-position: -86px 0;
  width: 33px;
}

.tls-video-controls .tls-video-button-next {
  background-position: -172px 0;
}

.tls-video-controls .tls-video-button-play {
  background-position: -258px 0;
  width: 43px
}

.tls-video-controls .tls-video-button-playall {
  background-position: -424px 0;
  width: 26px;
}

.tls-video-controls .tls-video-button-pause {
  background-position: -354px 0;
  width: 26px;
}

.tls-video-controls .tls-video-button-help {
  background-position: -492px 0;
  width: 19px;
}

.tls-video-controls .tls-video-button:hover {
  background-image: url(../../../../assets/images/new-video/toolbar-down28px-yellow.png);
}

.tls-video-controls.first .tls-video-button-begin,
.tls-video-controls.first .tls-video-button-prev,
.tls-video-controls.last .tls-video-button-next,
.tls-video-controls.last .tls-video-button-play,
.tls-video-controls.last .tls-video-button-playall,
.tls-video-controls.last .tls-video-button-pause {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
</style>
