<template>
  <div id="lesson-selection" class="mb-2">
    <div class="lower-menu">

      <!-- Menu 1 -->
      <div v-if="hasSelected.course" class="menu1-menu row m-0">
        <div class="stripe"></div>
        <div class="menu1 col-12 col-lg-8 mx-auto" style="overflow-x: auto">
          <div
            class="menu1__button"
            v-for="(menu1, menu1Index) in menu1Items"
            :key="menu1Index"
            :class="{selected: menu1Index===selected.menu1Index}"
            @click="selectMenu1(menu1Index)"
          >
            <div class="number">0{{ menu1.key }}</div>
            <div class="title" v-html="menu1.title"></div>
            <div class="subtitle" v-html="menu1.descr"></div>
          </div>
        </div>
      </div>

      <!-- Menu 2 -->
      <div v-if="hasSelected.menu1" class="menu2-menu row">
        <div class="menu2 col-12 col-lg-8 mx-auto" style="overflow-x: auto">
          <div
            class="menu2__button"
            v-for="(menu2, menu2Index) in menu2Items"
            :key="menu2Index"
            :class="{selected: menu2Index===selected.menu2Index}"
            @click="selectMenu2(menu2Index)"
          >
            <div class="title" v-html="menu2.title"></div>
          </div>
        </div>
        <div class="lesson-menu col-12 col-lg-8 offset-lg-2 mx-auto" v-if="hasSelected.menu2">
          <div class="lesson">
            <a class="lesson__button"
            	v-for="lesson in menuLessons"
            	:key="lesson.key"
            	:class="{selected: isHighlighted(lesson.key), enabled: lesson.enabled}"
            	@mouseenter="hoveredLessonKey = lesson.key"
            	@mouseleave="hoveredLessonKey = null"
            	@click="selectLesson(lesson)"
            >
              <div class="lesson__hint">{{ lesson.title }}</div>

              <div v-if="menuIcons.find(i => i.lessonId === lesson.id).loading" class="lesson__image__loader">
              	<b-spinner variant="light" label="Spinning"></b-spinner>
              </div>
              <img v-else class="lesson__image" :alt="`image-${menuIcons.find(i => i.lessonId === lesson.id).name}`" :class="{disabled: !lesson.enabled}" :src="menuIcons.find(i => i.lessonId === lesson.id).src" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import assets from '../../shared/helpers/assets.js'
import _isEmpty from 'lodash/isEmpty';

export default {
  data() {
    return {
    	menuIcons: [],
      hoveredLessonKey: null
    }
  },

  computed: {
  	isOfflineBuild() { return this.$store.getters['app/isOffline'] },

  	isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    // Check if a product/course/etc has been selected => Boolean
    hasSelected() {
      const selected = this.$store.getters.getSelected
      return {
        course: selected.course !== null,
        menu1: selected.menu1Index !== null,
        menu2: selected.menu2Index !== null,
        lesson: selected.lessonKey !== null
      }
    },

    selectedCourseKey() { return this.$store.getters.getSelected.course },

    // Get menu menu1 items (current product must be selected) => [{key, title, descr}]
    menu1Items() { return this.$store.getters.getMenu1Items },

    // Get menu menu2 items (current product must be selected) => [{key, title, descr}]
    menu2Items() { return this.$store.getters.getMenu2Items },

    // Get menu lesson items
    menuLessons() { return this.$store.getters.getMenuLessons },

    // Get current (selected) keys and indices => { product, course, menu1Index, menu2Index, lesson }
    selected() { return this.$store.getters.getSelected },

		// Get last visited lesson
    lastViewedLesson() { return this.$store.getters.getPreviouslyViewedLesson; },

    // Get next lesson to study
    nextLessonToStudy() { return this.$store.getters["progress/getNextToStudy"]; }
  },

  methods: {
    // Fetch progress for selected course
  	async fetchProgressForSelectedCourse() {
  		try {
  			await this.$store.dispatch("app/toggleCourseProgressFetchState");

  			const selectedCourseKey = this.$store.getters.getSelected.course;
  			const courseId = this.$store.getters["userCourses/getCourseId"](selectedCourseKey);
				await this.$store.dispatch("progress/fetchLastNextToStudy", courseId);

				// Navigate to the lower menu where the 'next lesson to study' is or to the first menu entry, only if there is no 'last viewed lesson'
				// i.e. the user hasn't visited any lessons of the selected course until now in his current session
				if (!this.lastViewedLesson.key) {
					const menu1Index = this.nextLessonToStudy.id && this.nextLessonToStudy.menu.code ? this.nextLessonToStudy.menu.menu1.index : 0;
					const menu2Index = this.nextLessonToStudy.id && this.nextLessonToStudy.menu.code ? this.nextLessonToStudy.menu.menu2.index : 0;
					this.selectMenu({ menu1Index, menu2Index });
				}
  		} catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.course_progress') + "\n" + this.$t('telestia.error.connection'),
        });
  		} finally {
  			this.$store.dispatch("app/toggleCourseProgressFetchState");
  		}
  	},

    // Return base64 encoded image source
    getImageBase64(pathName) {
      return assets.getImageData(this.selected.productKey.toUpperCase(), pathName);
    },

    // Returns true if the provided lessonKey is the key of the hovered lesson or the lastly viewed lesson or the next lesson to study
    isHighlighted(lessonKey) {
      return lessonKey === this.hoveredLessonKey
      	|| (lessonKey === this.lastViewedLesson.key && this.hoveredLessonKey === null)
      	|| (this.nextLessonToStudy.id && this.nextLessonToStudy.menu.code && lessonKey === this.nextLessonToStudy.menu.code && this.hoveredLessonKey === null && !this.lastViewedLesson.key);
    },
    
    // Select a course
    async onCourseSelection({ productKey, courseKey }) {
    	try {
    		await this.$store.dispatch("app/toggleCourseSelectionState");

    		// Select product and course
    		this.$store.commit('selectProductCourse', { productKey, courseKey });

    		// In case of online build, fetch product skeleton from server
    		if (this.isOnlineBuild) { await this.$store.dispatch("products/fetchProductSkeleton", productKey.toLowerCase()); }

    		// Initialize menu options
    		this.selectMenu({ lessonKey: null });

				// Navigate to Lessons route
        this.$router.push({ name: 'App:Lessons', params: { product_key: productKey.toLowerCase(), course_key: courseKey.toLowerCase() } });
    	} catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.course') + "\n" + this.$t('telestia.error.connection'),
        });
    	} finally {
    		await this.$store.dispatch("app/toggleCourseSelectionState");
    	}
    },

    // Select a lesson, if the user is allowed to view it and it is enabled
    selectLesson({ key: lessonKey, enabled, id }) {
      if (!enabled) { return; }

      if (!this.$store.getters.isAllowedToView(id)) {
        this.showErrorAlert({
          title: '',
          text: this.$t('telestia.error.not_active') + `"${this.nextLessonToStudy.menu.title}"` + "\n" + this.$t('telestia.error.check_todaysclass'),
        });
        return;
      }

      this.selectMenu({ lessonKey });
      this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: id } })
    },

    // Select menu1 option
    selectMenu1(menu1Index) {
    	this.selectMenu({ menu1Index });
    	if (!this.hasSelected.menu2 || this.selected.menu2Index > this.menu2Items.length - 1) { this.selectMenu2(0); }
    },

    // Select menu2 option
    selectMenu2(menu2Index) {
    	this.selectMenu({ menu2Index });
    },

    // Items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    fetchMenuIcons() {
      const productKey = this.$store.getters["products/getCurrentProductKey"];
      this.menuLessons.forEach(l => {
        const menuIcon = this.menuIcons.find(i => i.lessonId === l.id)
        if (!menuIcon.src) {
        	menuIcon.loading = true;

		      this.$store.dispatch("lessons/fetchLessonMenuIcon", { productKey, title: l.image })
		          .then((response) => {
		            if (response) {
		              menuIcon.src = response;
		            } else {
		              menuIcon.src = assets.getImagePlaceholder();
		            }
		          })
		          .catch(e => {
		            console.log(e)
		            menuIcon.src = assets.getImagePlaceholder();
		          })
		          .finally(() => {
		            menuIcon.loading = false;
		          })
        }
      })
    },

    showErrorAlert({ title, text }) {
    	this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
  	selectedCourseKey: {
  		immediate: true,
  		async handler(n, o) {
  			// If the user is navigating directly to a lesson, lesson progress is already retrieved
  			if (this.$store.getters["app/getNavigatingToLessonViaHardlink"]) { return; }
  			
  			// In case of hybrid or online build, fetch last studied and next to study
				if (!this.isOfflineBuild && n) {
					await this.fetchProgressForSelectedCourse();
				} else {
          if (!this.lastViewedLesson.key) { this.selectMenu({ menu1Index: 0, menu2Index: 0 }); }
        }
  		}
  	},

    menuLessons: {
      immediate: true,
      handler(n, o) {
        // Retrieve menu icons if the user has just entered a course or the current course lessons have changed
        if (!o || o.length === 0 || (n.length > 0 && n.some((l, index) => l.id !== o[index].id))) {
          this.menuIcons = this.menuIcons.concat(n.map(l => { return { lessonId: l.id, name: l.image, src: '', loading: false } }));

          if (this.isOnlineBuild) {
            this.fetchMenuIcons();
          } else {
            this.menuIcons.forEach(i => {
              if (n.findIndex(l => l.id === i.lessonId) > -1 && !i.src) { i.src = this.getImageBase64(i.name); }
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
a,
div,
li,
input,
span {
  font-family: Roboto,Tahoma,Verdana,Arial,sans-serif !important;
}
.menu {
  position: relative;
  flex: 1;
}
.lower-menu {
  position: relative;
  width: 100%;
  flex: 1;
}
.menu1-menu {
  width: 100%;
  position: relative;
}
.menu1-menu .stripe {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  height: 18px;
  z-index: 10;
  background: #cbcbcb;
}
.menu1 {
  display: flex;
  justify-content: space-between;
}
.menu1__button {
  cursor: pointer;
  max-width: 200px;
  padding-right: 5px;
}
.menu1__button:hover > div {
  color: #1b96cf;
}
.menu1__button .number {
  font-size: 62px;
  font-family: "Lithos Pro", Tahoma, Arial, sans-serif !important;
  color: #cbcbcb;
	z-index: 1;
}
.menu1__button .title {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin: -37px 0 0;
  z-index: 10;
  white-space: normal;
}
.menu1__button .subtitle {
  font-size: 13px;
  line-height: 16px;
  color: #cbcbcb;
  margin: 2px 0 15px;
}
.menu1__button.selected:after {
  content: "";
  position: absolute;
  bottom: -10px;
  background: transparent;
  background-image: url(../../assets/images/navigation/menu-arrow.png);
  width: 28px;
  display: block;
  height: 10px;
  color: transparent;
  z-index: 100;
}
.menu1__button.selected .number,
.menu1__button.selected .title,
.menu1__button.selected .subtitle {
  color: #1b96cf;
}
.menu2-menu {
  width: 100%;
  margin: 0px;
  position: relative;
  color: #fff;
  background-color: #1b96cf;
  padding: 20px 0 20px;
}
.menu2__button {
  display: table-cell;
  max-width: 110px;
  height: 70px;
  padding: 0 10px;
  border-right: 2px dashed #fff;
  border-left: none;
  box-sizing: content-box;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
#app.rtl .menu2__button {
	border-right: none;
  border-left: 2px dashed #fff;
}
.menu2__button.selected {
  color: #ffa500;
  text-decoration: underline;
}
.menu2__button:hover {
  text-decoration: underline;
}
#app.rtl .menu2__button:last-child {
	border-left: none;
}
.menu2__button:last-child {
  border-right: none;
}
.lesson-menu {
  background-color: #1a96cf;
  padding: 40px 0 0px;
  position: relative;
}
.lesson__hint {
  position: absolute;
  width: 92%;
  left: 0;
  top: 10px;
  color: #303030;
  background-color: #fff;
  display: none;
  padding: 0px 20px;
  border-radius: 4px;
}
#app.rtl .lesson__hint {
	margin-left: 8%;
}
.lesson__button {
  display: inline-block;
  margin: 2px 3px 3px -2px;
  padding: 0;
  border: 6px solid rgba(0,0,0,0);
  border-radius: 4px;
  vertical-align: top;
  opacity: 0.9;
}
.lesson__button.selected {
  border: 6px solid #ffa500 !important;
}
.lesson__button.selected .lesson__hint {
  display: block;
}
.lesson__button.enabled {
  opacity: 1;
  cursor: pointer;
}
.lesson__image {
  height: auto;
  box-shadow: 3px 5px 6px rgba(0,0,0,0.6);
  border-radius: 3px;
  max-width: 120px;
}
.lesson__image.disabled {
  filter: grayscale(100%) contrast(70%);
}
.lesson__image__loader {
	display: flex;
	justify-content: center;
	align-items: center;
  height: 96px;
  width: 95px;
  box-shadow: 3px 5px 6px rgba(0,0,0,0.6);
  border-radius: 3px;
}
</style>
