<template>
  <div ref="contentWrapper" class="wrapper d-flex flex-fill" style="background-color: #F2F2F2">
    <!-- Left column -->
    <div v-if="!isLayoutLimited" class="left-column d-flex flex-column align-items-center justify-content-between" style="flex: 6%">
      <div class="logo"><img src="../../../../assets/images/logo-watermark.png" alt="logo-watermark" class="mw-100 h-auto"/></div>

      <button v-if="hasExtras.other && !currentlyViewingExtra" class="primary-btn-rounded px-2 py-1" @click="navigateToExtra(0)">
        {{ $t("telestia.button.extras") }}<font-awesome-icon :icon="['fas', 'star']" class="ml-1"/>
      </button>
      <button v-if="currentlyViewingExtra" class="primary-btn-rounded px-2 py-1" @click="navigateToLesson">
        {{ $t("telestia.button.lesson") }}<font-awesome-icon :icon="['fas', 'book']" class="ml-1"/>
      </button>
    </div>

    <!-- Lesson extras -->
    <div v-if="currentlyViewingExtra" class="lesson-extras d-flex px-3" style="flex: 95%; max-height: 100%">
      <b-tabs class="d-flex flex-column" content-class="pt-4 h-100" style="flex: 95%; max-height: 95%" align="center">
        <b-tab
            v-for="(extra, index) in otherExtras"
            :key="`${extra.title}-${index}`"
            :title="extra.title"
            title-link-class="border border-2 shadow"
            @click="navigateToExtra(index)"
            :active="activeExtraTabIndex === index"
        >
          <lesson-extra-technical-drawing v-if="currentlyViewingExtra.type.toLowerCase() === 'td'" :title="selectedLesson.title" :data="currentlyViewingExtra.data" />
          <lesson-extra-combined v-else :title="currentlyViewingExtra.title" :data="currentlyViewingExtra.data" />
        </b-tab>
      </b-tabs>
    </div>

		<!-- Lesson content -->
    <template v-if="!currentlyViewingExtra && position >= 0">

      <!-- Middle column -->
      <div class="middle-column d-flex flex-column" style="flex: 65%; max-height: 100%">
        <!-- Loading state -->
				<div v-if="pendingComponentInit" class="d-flex justify-content-center align-items-center w-100 h-100">
				  <div class="loading-state mx-auto align-self-center">
				    <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
				  </div>
			  </div>

        <!-- Loaded state -->
        <template v-else>
          <!-- Empty content placeholder -->
          <div v-if="emptyContent" class="page d-flex justify-content-center align-items-center" style="flex: 100%; max-height: 100%">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="ml-1"/>
            <span class="ml-3" style="font-size: 20px">{{ $t("telestia.message.missing_content") }}</span>
          </div>

          <template v-else>
          	<div
				      class="content-wrapper d-flex bg-white position-relative mb-2"
				      :class="{ 'justify-content-center align-items-center': isLessonSlideshow }"
				      style="flex: 100%; max-height: 100%; overflow-y: auto;"
				    >
				      <img
				        v-if="isLessonSlideshow && lesson.pages[position] && lesson.pages[position].image"
				        :src="lesson.pages[position].image.src"
				        :height="lesson.pages[position].image.height"
				        :width="lesson.pages[position].image.width"
				        class="content"
				      />

				      <div
				        v-if="isLessonArticle && lesson.pages[position] && lesson.pages[position].content"
				        class="content p-3 w-100"
				        v-html="lesson.pages[position].content"
				      ></div>

				      <lesson-extra-measurement v-if="hasExtras.measurements" :extras="measurementExtras" class="position-absolute" style="bottom: 10px; left: 10px;" />
				    </div>

						<!-- Controls container -->
				    <div class="controls-wrapper d-flex justify-content-center align-items-center w-100">
				      <div class="controls d-flex justify-content-center align-items-center position-relative w-75" style="height: fit-content;">
				      	<button
				        	v-if="mainLesson"
				        	class="slideshow-button-main-lesson position-absolute"
				        	:title="$t('telestia.hover_text.back_to_mlfp')"
				        	@click="backToMainLesson()"
				        	style="top: 8px; left: 15px;"
				        >
				          <font-awesome-icon :icon="['fas', 'th-large']" style="font-size: 1.8rem;" />
				        </button>
				        <button
				        	class="controls-button begin"
				        	:class="{ disabled: position === 0 }"
				        	:title="$t('telestia.hover_text.start_again')"
				        	@click="goto(-position)"
				        ></button>
				        <button
				        	class="controls-button prev ml-2"
				        	:class="{ disabled: position === 0 }"
				        	:title="$t('telestia.hover_text.previous_step')"
				        	@click="goto(-1)"
				        ></button>
				        <button
				        	class="controls-button next ml-2"
				        	:class="{ disabled: position === lesson.pages.length - 1 }"
				        	:title="$t('telestia.hover_text.next_step')"
				        	@click="goto(+1)"
				        ></button>
				        <span class="position-absolute steps" style="top: 10px; right: 10px;">{{ position+1 }}/{{ lesson.pages.length }}</span>
				      </div>
				    </div>
          </template>
        </template>
      </div>

      <!-- Right column -->
      <div class="right-column d-flex flex-column pl-3" style="flex: 30%; max-height: 100%;">
        <div class="d-flex flex-column" :class="!emptyContent && [lesson.pages[position].video ? 'flex-80' : 'flex-90']">
          <div class="titlewrapper container px-0 mb-4">
            <div class="row">
              <div class="code col-auto d-flex justify-content-center align-items-center">
                <span class="circular-blue font-weight-bold text-white">{{ lessonCode }}</span>
              </div>
              <div class="title col d-flex flex-column">
                <span class="title-text font-weight-bold mb-2">{{ lessonTitle }}</span>
                <span style="border-bottom: 1px solid #1b96cf"/>
              </div>
            </div>
          </div>

          <div v-if="!emptyContent" class="textwrapper mb-2" style="overflow-y: auto;">
            <div class="text">
              <div v-if="lesson.pages[position].title" v-html="lesson.pages[position].title"></div>
              <div v-if="lesson.pages[position].text" v-html="lesson.pages[position].text"></div>
            </div>
            <div v-if="lesson.index" class="text" style="overflow-x: auto">
              <nav>
                <ol><li v-for="(anchor, pos) in lesson.index">
                  <a class="mouse-pointer" :class="{'selected-entry': position === pos}" @click="navigateTo(anchor.id)">{{ anchor.content }}</a>
                </li></ol>
              </nav>
            </div>
          </div>
        </div>

        <div v-if="!emptyContent && lesson.pages[position].video" style="flex: 20%"></div>

        <div v-if="!isOfflineBuild && !emptyContent" class="action-buttons d-flex align-items-end justify-content-between mt-auto" style="flex: 10%; max-height: 10%;">
          <div class="d-flex">
		        <button	class="action-button px-0 mr-2" :class="{ 'disabled': !packagePdf }" :title="$t('telestia.hover_text.download_lp')" @click="downloadPackagePdf">
		          <font-awesome-icon :icon="['fas', 'box']" size="2x" />
		        </button>
		        <button
		          class="action-button px-0"
		          :class="{ 'disabled': !hasAssignment }"
		          :title="$t('telestia.hover_text.upload_assignment')"
		          @click="$router.push({ name: 'App:Assignments' })"
		        >
		          <font-awesome-icon :icon="['fas', 'edit']" size="2x" />
		        </button>
		      </div>

          <mark-as-complete :completed="completed" :lessonid="lessonId" />
        </div>
      </div>

    </template>

    <!-- Extras -->
    <template v-if="isLayoutLimited">
      <lesson-extra-measurement v-if="hasExtras.measurements && !currentlyViewingExtra" :extras="measurementExtras" class="extras-measurements-limited" />
      <template v-if="hasExtras.other">
        <div v-if="!currentlyViewingExtra" class="extras-button-limited" @click="navigateToExtra(0)"><font-awesome-icon :icon="['fas', 'star']"/></div>
        <div v-else class="extras-button-limited" @click="navigateToLesson"><font-awesome-icon :icon="['fas', 'book']"/></div>
      </template>
    </template>

  </div>
</template>

<script>
import domParser from "../../../../shared/helpers/domParser";
import assets from "../../../../shared/helpers/assets";
import lessons from "../../../../shared/helpers/lessons";
import LessonExtraTechnicalDrawing from '../Extras/LessonExtraTechnicalDrawing.vue';
import LessonExtraCombined from '../Extras/LessonExtraCombined.vue';
import LessonExtraMeasurement from '../Extras/LessonExtraMeasurement.vue';
import MarkAsComplete from '../../shared/MarkAsComplete.vue';
import _isEmpty from "lodash/isEmpty";
import envConfig from '../../../../shared/configs/config.local.js';

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  name: 'player-slideshow',

  components: { LessonExtraTechnicalDrawing, LessonExtraCombined, LessonExtraMeasurement, MarkAsComplete },

  props: ['selectedLesson', 'completed', 'mainLesson'],

  mounted() { this._initComponent(); },

  data() {
    return {
      pendingComponentInit: true,

      position: -1,
      currentlyViewingExtra: null,

      lesson: null
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    isLessonSlideshow() { return this.selectedLesson.type === 'slideShow'; },

    selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },

    isLessonArticle() { return this.selectedLesson.type === 'html'; },

    lessonId() { return this.mainLesson?.dbid || this.selectedLesson.dbid; },

    emptyContent() {
    	return (this.isLessonArticle && (this.selectedLesson.html.languages.length === 0 || this.selectedLesson.html.languages.every(l => l.pages.length === 0))) ||
    		(this.isLessonSlideshow && this.selectedLesson.slideShow.slides.length === 0);
    },

    packagePdf() {
      if (this.mainLesson) { return this.$store.getters.getPackagePdf(this.mainLesson.code); }
      return this.$store.getters.getPackagePdf(this.selectedLesson.key);
    },

    lessonCode() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonCode(title);
    },

    lessonTitle() {
      const title = (this.selectedLesson.title[this.selectedLanguage] || this.selectedLesson.title.en).trim();
      return lessons.extractLessonTitle(title);
    },

    hasAssignment() { return this.selectedLesson.hasAssignment || (this.mainLesson && this.mainLesson.hasAssignment); },

    hasExtras() {
      const hasExtras = { measurements: false, other: false };
      if (!this.selectedLesson.extras || !this.selectedLesson.extras.tabs) { return hasExtras; }

      const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
      if (this.selectedLesson.extras.tabs.some(t => measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.measurements = true; }
      if (this.selectedLesson.extras.tabs.some(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()))) { hasExtras.other = true; }
      return hasExtras;
    },

    measurementExtras() {
      if (!this.hasExtras.measurements) { return []; }

  		const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
  		return this.selectedLesson.extras.tabs
        .filter(e => measurementsExtrasTypes.includes(e.type.toLowerCase()))
        .map(e => { return { type: e.type.toLowerCase(), title: e.title } });
  	},

    otherExtras() {
      if (!this.hasExtras.other) { return []; }

  		const measurementsExtrasTypes = ['ca', 'cb', 'cc', 'cd', 'measurements'];
  		return this.selectedLesson.extras.tabs.filter(t => !measurementsExtrasTypes.includes(t.type.toLowerCase()));
  	},

    activeExtraTabIndex() { return this.otherExtras.findIndex(e => e === this.currentlyViewingExtra) },
  },

  methods: {
    async _initComponent() {
      this.lesson = this._generateLessonStructure();
      if (this.position === 0) { this._generatePageContent(this.position); }
      else { this.position = 0; }
    },

    _generateLessonStructure() {
    	if (this.emptyContent) { return {}; }

      const lesson = { title: this.selectedLesson.title, folder: this.selectedLesson.folder, pages: [] }
      const voiceoverExist =
          this.selectedLesson.voiceOver && Object.keys(this.selectedLesson.voiceOver).some(k => this.selectedLesson.voiceOver[k].length > 0);
      const translatedVoiceover = voiceoverExist ? this.selectedLesson.voiceOver[this.selectedLanguage] || this.selectedLesson.voiceOver.en : [];
      switch (this.selectedLesson.type) {
        case 'slideShow':
          this.selectedLesson.slideShow.slides.forEach((slide, index) => {
            if (slide.image) {
              const currentSlideVoiceover = voiceoverExist ? translatedVoiceover.find(v => v.order === index + 1) : null;
              const currentPage = { image: { name: slide.image }, title: '', text: '', video: currentSlideVoiceover ? currentSlideVoiceover.name : null };
              if (slide.texts) { currentPage.title = slide.texts[this.selectedLanguage] ? slide.texts[this.selectedLanguage] : slide.texts.en; }
              if (slide.subTexts) {	currentPage.text = slide.subTexts[this.selectedLanguage] ? slide.subTexts[this.selectedLanguage] : slide.subTexts.en; }

              lesson.pages.push(currentPage);
            }
          })
          break;
        case 'html': {
          let articleContent = this.selectedLesson.html.languages.find(l => l.language === this.selectedLanguage)
          if (!articleContent) { articleContent = this.selectedLesson.html.languages.find(l => l.language === 'en') }
          lesson.index = articleContent.index

          articleContent.pages.forEach((page, index) => {
            const currentPageVoiceover = voiceoverExist ? translatedVoiceover.find(v => v.order === index + 1) : null;
            lesson.pages.push({
              id: page.sectionId,
              content: { html: page.content, images: page.images, videos: page.videos, pdfs: page.downloadFiles },
              video: currentPageVoiceover ? currentPageVoiceover.name : null
            })
          })
          break;
        }
      }

      return lesson;
    },

    async _generatePageContent(pageIndex) {
    	try {
        this.pendingComponentInit = true;

				if (!this.emptyContent) {
        	await this._getPageMultimedia(pageIndex);
        	this.showVoiceover(pageIndex);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
        this._addHrefClickListeners();
      }
    },

    async _getPageMultimedia(index) {
      try {
        switch (this.selectedLesson.type) {
          case 'slideShow': {
            const slide = this.lesson.pages[index];
            if (slide.image.src) { break; }

            const assets = await this._fetchAssets([slide.image], [], []);
            const imageSrc = assets.images[0].src
            let imageDimensions = await this.getImageDimensions(imageSrc);
            imageDimensions = this.adjustImageDimensions(imageDimensions);
            slide.image.src = imageSrc;
            slide.image.height = imageDimensions.height;
            slide.image.width = imageDimensions.width;
            break;
          }
          case 'html': {
            const page = this.lesson.pages[index];
            if (!page.content.html) { break; }

            const assets = await this._fetchAssets(page.content.images, page.content.videos, page.content.pdfs);
            page.content = this.replaceSrc(page.content.html, assets.images, assets.videos, assets.pdfs);
            break;
          }
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async _fetchAssets(images, videos, pdfs) {
      try {
        const product = this.selectedLesson.folder;
        if (!this.isOnlineBuild) {
          images.forEach(img => img.src = this.getImageBase64(product, img.name))
          videos.forEach(video => {
            video.posterImage.src = this.getImageBase64(product, video.posterImage.name)
            video.sources.forEach(s => s.src = this.getFileURL(product, s.name))
          })
          pdfs.forEach(pdf => pdf.url = this.getFileURL(product, pdf.name))
          return Promise.resolve({ images, videos, pdfs });
        }

        // Initialize and send requests
        const requests = [];
        images.forEach(img => requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: img.name })))
        videos.forEach(video => {
          if (video.posterImage.name) { requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: video.posterImage.name })) }
        })
        const results = await Promise.allSettled(requests);

        // Get assets sources
        images.forEach((img, index) => {
          if (results[index].status !== 'rejected') { img.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { img.src = this.getImageBase64(''); }
        })
        let index = images.length - 1;
        videos.forEach(video => {
          // Poster image
          if (video.posterImage.name) {
          	index++;
		        if (results[index].status !== 'rejected') { video.posterImage.src = results[index].value ? results[index].value : this.getImageBase64(''); }
		        else { video.posterImage.src = this.getImageBase64(''); }
		      } else {
		      	video.posterImage.src = this.getImageBase64('');
		      }
        })

        videos.forEach(video => video.sources.forEach(s => s.src = `${FILE_SERVER}/assets?product=${product}&title=${s.name}&language=${this.selectedLanguage}`))
        pdfs.forEach(pdf => {
        	pdf.url = `${FILE_SERVER}/assets?`;
        	pdf.params = [{ name: "product", value: product }, { name: "title", value: pdf.name }, { name: "language", value: this.selectedLanguage }]
        })

        return Promise.resolve({ images, videos, pdfs });
      } catch (e) {
        return Promise.reject(e);
      }
    },

    getImageDimensions(imgSrc) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => { resolve({ height: image.naturalHeight, width: image.naturalWidth }); }
        image.src = imgSrc;
      })
    },

    adjustImageDimensions(dimensions) {
      if (!this.$refs.contentWrapper) { return dimensions; }
      const wrapperComputedStyles = getComputedStyle(this.$refs.contentWrapper);
      const padding = wrapperComputedStyles.fontSize.split('px')[0];
      const slideshowContentAreaHeight = (this.$refs.contentWrapper.clientHeight - padding * 2) * 0.9;

      if (dimensions.height > slideshowContentAreaHeight) {
        const imageRatio = dimensions.width / dimensions.height;
        dimensions.height = slideshowContentAreaHeight;
        dimensions.width = dimensions.height * imageRatio;
      }

      return dimensions;
    },

    replaceSrc(content, images, videos, pdfs) {
      // Set attributes to nodes
      content = domParser.parseHTMLAttributes(content);

      // Generate HTML content
      const parser = new DOMParser()
      let section = parser.parseFromString(content, 'text/html').body

      // Replace images
      section = domParser.replaceImageSources(section, this.selectedLesson.folder, images);

      // Replace videos
      section = domParser.replaceVideoSources(section, this.selectedLesson.folder, videos, true, false, "noplaybackrate nodownload noremoteplayback");

      // Replace link hrefs
      section = domParser.replaceLinkHrefs(section);

      // Replace pdfs
      section = domParser.replacePdfSources(section, this.selectedLesson.folder, pdfs);

      return section.innerHTML;
    },

    getImageBase64(folder, imageName) { return assets.getImageData(folder, imageName); },

    getFileURL(folder, videoName) { return assets.getFileURL(folder, videoName); },

    showVoiceover(pageIndex) {
      let objectToEmit = (this.lesson.pages[pageIndex].video)
          ? this.lesson.pages[pageIndex].video
          : null;

      this.$emit('show-voiceover', objectToEmit);
    },

    _addHrefClickListeners() {
      if (this.isLessonSlideshow) { return; }

      this.$nextTick(() => {
        const anchorEls = document.querySelectorAll('.article-anchorEl');
        anchorEls.forEach(el => el.addEventListener('click', () => {
          const subLessonClass = Array.from(el.classList).find(e => e.includes('lesson-'));
          if (subLessonClass) {
            const subLessonId = subLessonClass.split('-').slice(-1);
            if (!subLessonId) { return; }
            this.selectLesson(subLessonId);
          }
        }))

        //open pdf files from form action
        Array.from(document.getElementsByTagName('form')).forEach(form => {
          if (form.dataset.pdfname) {
            form.onsubmit = ()=>{
              assets.openWindow(form.action, 'application/pdf', form.dataset.pdfname);
              return false;
            }
          }
        });
      })
    },

    _translateLessonTexts() {
    	if (this.emptyContent) { return; }

      switch (this.selectedLesson.type) {
        case 'slideShow':
          this.lesson.pages.forEach((p, index) => {
            const slide = this.selectedLesson.slideShow.slides[index];
            p.title = slide.texts[this.selectedLanguage] ? slide.texts[this.selectedLanguage] : slide.texts.en;
            p.text = slide.subTexts[this.selectedLanguage] ? slide.subTexts[this.selectedLanguage] : slide.subTexts.en;
          })
          break;
        case 'html': {
          let articleContent = this.selectedLesson.html.languages.find(l => l.language === this.selectedLanguage)
          if (!articleContent) {
            articleContent = this.selectedLesson.html.languages.find(l => l.language === 'en')
          }
          this.lesson.index = articleContent.index
          this.lesson.pages.forEach((p, index) => {
            const page = articleContent.pages[index];
            p.content = { html: page.content, images: page.images, videos: page.videos, pdfs: page.downloadFiles }
            this._getPageMultimedia(index);
          })
          break;
        }
      }
    },

    downloadPackagePdf() {
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${this.selectedLesson.folder}&title=${this.packagePdf}&language=${this.selectedLanguage}`
        : assets.getFileURL(this.selectedLesson.folder, this.packagePdf),
        'application/pdf', this.packagePdf
      );
    },

    goto(step) {
      this.position = Math.min(Math.max(this.position + step, 0), this.lesson.pages.length-1);
    },

    navigateTo(sectionId) {
      this.position = this.lesson.pages.findIndex(p => p.id === sectionId);
    },

    selectLesson(sublessonId) {
      const selectedSublessonCode = this.$store.getters.getLessonCode(sublessonId);
      this.$emit("update-selected-lesson", { key: selectedSublessonCode, enabled: true });
    },

    backToMainLesson() {
      const menuItems = {
        menu1Index: this.mainLesson.menu1.index,
        menu2Index: this.mainLesson.menu2.index,
        lessonKey: this.mainLesson.code
      };
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? menuItems : { ...menuItems, productSkeleton  };
      this.$store.commit('selectMenu', payload);
      this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(payload.lessonKey) } });
    },

    navigateToExtra(index) { this.currentlyViewingExtra = this.otherExtras[index]; },

    navigateToLesson() { this.currentlyViewingExtra = null;	},

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

	watch: {
		selectedLesson() {
      this._initComponent();
		},

    position(newValue) {
    	this._generatePageContent(newValue);
    },

    selectedLanguage() {
      this._translateLessonTexts();
    }
	}
}
</script>

<style scoped>
.extras-button-limited {
  position: absolute;
  bottom: 3.5%;
  padding: 10px 15px;
  z-index: 999;
  border-radius: 10px;
  background-color: #1b96cf;
  color: white;
  font-size: 16px;
}

.extras-measurements-limited {
  position: absolute;
  bottom: 18%;
}
/*----------------------------------------------------------------------------------------------*/
/* TEXT PANEL */

.title-text {
  font-size: 18px;
}

/*----------------------------------------------------------------------------------------------*/
/* CONTROLS */

.controls {
  background-color: #1b96cf;
  border-radius: 50px;
}

.controls .controls-button {
  position: relative;
  display: inline-block;
  background-image: url(../../../../assets/images/slideshow/toolbar-down28px-white.png);
  background-color: #1b96cf;
  width: 33px;
  height: 28px;
  border: none;
  margin: 8px 10px;
  outline: 0;
  color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
}
.controls .controls-button:hover {
	border: none;
	background-color: transparent;
  background-image: url(../../../../assets/images/slideshow/toolbar-down28px-yellow.png);
}
.controls .controls-button .prev {
  background-position: -86px 0;
}
.controls .controls-button .next {
  background-position: -172px 0;
}
.controls .steps {
  color: white;
}

.selected-entry {
	font-weight: bold;
}

.slideshow-button-main-lesson {
	color: white;
	background-color: #1b96cf;
	border: none;
	outline: 0;
}
.slideshow-button-main-lesson:hover { color: #fec801; }
</style>

<style>
/*----------------------------------------------------------------------------------------------*/
/* CONTENT */

.content-wrapper div.content ul {
  padding-left: 30px;
}
.content-wrapper div.content li {
  padding-bottom: 10px;
}

.content-wrapper div.content h3 {
  margin-top: 0;
  margin-bottom: 25px;
  padding: 0 0 0 20px;
  font-weight: 300;
  text-shadow: 0px 1px 0 rgb(26 150 207 / 40%);
  position: relative;
  font-size: 22px;
  line-height: 40px;
}
.content-wrapper div.content h3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 50%), 0 1px 1px rgb(255 255 255 / 30%);
  background-color: #1b96cf;
}

.content-wrapper div.content h4 {
  margin: 1.33em 0;
}
.content-wrapper div.content p {
  margin: 1em 0;
}

.content-wrapper div.content img {
  max-width: 100%;
  max-height: 100%;
}

/*VIDEO*/
.content-wrapper video::-webkit-media-controls-volume-control-container {
  display:none;
}

/*TABLES*/
.content-wrapper table[bordercolor] tr,
.content-wrapper table[bordercolor] td, 
.content-wrapper table[bordercolor] th {
  border-width: 1px;
  vertical-align: top;
}
.content-wrapper .table-zebra tbody tr:nth-child(odd) {
    background-color: #1a96cf;
}

/*FORMS*/
.content-wrapper form {
  margin-top: 20px;
}
.content-wrapper button,
.content-wrapper input[type="submit"],
.content-wrapper input[type="button"] {
  padding: .4em 1em;
  border-radius: 3px;
  border: 1px solid #1b96cf;
  background-color: #1b96cf;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.content-wrapper button:hover,
.content-wrapper input[type="submit"]:hover,
.content-wrapper input[type="button"]:hover {
  border: 1px solid #e8b20c;
  background-color: #e8b20c;
}

/* Content Grids */
.content-wrapper div.content .grid-wrapper,
.content-wrapper div.content .grid-2-wrapper,
.content-wrapper div.content .grid-3-wrapper { clear: both; }
.content-wrapper div.content .grid-2-wrapper video { width: 100%; max-height:500px; border: 1px solid lightblue; border-radius: 2px; }
.content-wrapper div.content .grid-1 { width: 100% }
.content-wrapper div.content .grid-2-left,
.content-wrapper div.content .grid-2-right { width: 50%; float: left; box-sizing: border-box; }
.content-wrapper div.content .grid-2-left { padding-right: 30px; }
.content-wrapper div.content .grid-3-left,
.content-wrapper div.content .grid-3-middle,
.content-wrapper div.content .grid-3-right { width: 33%; float: left; box-sizing: border-box; }
.content-wrapper div.content .grid-3-left,
.content-wrapper div.content .grid-3-middle { padding-right: 30px; }

/* Tools Template */
.content-wrapper div.content .number { font-size: 20px; color: #e79601; width: 10%; float: left; margin-bottom: 10px; }
.content-wrapper div.content .number span { float: left; width: 25px; height: 25px; text-align: center; border: 1px solid #e79601; border-radius: 25px; line-height: 25px; }
.content-wrapper div.grid { display: grid; grid-template-columns: 1fr 1fr }
.content-wrapper div.content .item { width: 50%; float: left; min-height: 200px; border-style: none none dotted none; border-color: white white #e79601 white; padding: 10px 10px 10px 10px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.content-wrapper div.content .item.item-odd { border-style: none dotted dotted none; border-color: white #e79601 #e79601 white; }
.content-wrapper div.content .item .text-holder { width: 50%; float: left; font-size: 14px; }
.content-wrapper div.content .item .img-holder { max-height: 100%; width: 30%; float: right; }
.content-wrapper div.content .item .img-holder img { float: right; width: 100%; }

.textwrapper nav {
  font-weight: bold;
}
.textwrapper nav ol {
  padding-left: 30px;
}
.textwrapper nav li {
  margin-bottom: 10px;
}
.textwrapper nav a {
  color: black;
  font-weight: 300;
  text-decoration: none;
  border-bottom: 1px dashed #5fb6dd;
  line-height: 1.1em;
}

/* Tables */
table { width: 100%; }

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #1b96cf;
   color: white;
   border: 2px solid white;
}

div.content section table:not(.table-striped):not(.table-zebra) th {
  border: 1px #cccccc solid;
  padding: 5px;
  font-size: 16px;
  font-weight: 700;
  background-color: #f7e2a9;
}

div.content section table:not(.table-striped):not(.table-zebra) tr td {
    border: 1px solid #e0e0e0;
}
</style>

