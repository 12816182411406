<template>
  <!-- Lesson details -->
  <div id="lesson-navigation" class="container-fluid" v-click-outside="hideLessonPaths">

    <template v-if="isLayoutLimited && inLesson">
      <font-awesome-icon v-if="inLesson" :icon="['fas', 'bars']" size="3x" class="mouse-pointer back-to-menu-limited" @click="returnToLessonSelectionMenu" />
    </template>

    <template v-else>
      <div id="lesson-navigation-top" class="row py-1 text-white bg-blue">
        <div class="col col-lg-8 mx-auto d-flex">
          <div class="d-flex flex-50 align-items-center">
            <font-awesome-icon v-if="inLesson" :icon="['fas', 'bars']" size="2x" class="burger-menu-button mouse-pointer back-to-menu" @click="returnToLessonSelectionMenu" />
            <p v-if="inLesson" class="lesson-navigation-button mb-0 mouse-pointer mx-2" @click="showLessonPaths=!showLessonPaths">
              {{ $t("telestia.table.header.lesson_navigation") }}
              <font-awesome-icon class="icon" :icon="['fas', 'chevron-down']" size="lg"/>
            </p>
            <network-status />
          </div>
          <div class="d-flex flex-50 align-items-center">
            <div v-if="currentCourseTitle" class="mb-0 w-100 text-right d-flex" style="justify-content:flex-end">
              <p class="m-0">{{ $t("telestia.title.course") }}:</p>&nbsp;
              <p class="m-0"><b class="text-uppercase">{{ currentCourseTitle }}</b></p></div>
          </div>
        </div>
      </div>

      <div id="lesson-paths" v-if="!inLesson || showLessonPaths" class="row py-1 bg-dark-blue" :class="inLesson ? 'position-absolute  w-100' : ''">
        <div class="col col-lg-8 mx-auto d-flex">

          <!-- Next lesson to study section -->
          <div class="d-flex align-items-center text-green pr-2 flex-50 border-dashed">
            <div v-if="!isLessonPathEmpty(next) && (isOfflineBuild || !hasCurrentCourseFinished)" class="d-flex flex-column align-items-start lh-22 py-1">
              <p class="mb-0 text-right"><small class="uppercase">{{ $t('telestia.title.next') }}:</small></p>
              <p class="mb-0 text-right">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item menu1">{{ stripBrElements(next.menu1.title.toLowerCase()) }}</li>
                  <li class="breadcrumb-item"><font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></li>
                  <li class="breadcrumb-item">{{ stripBrElements(next.menu2.title) }}</li>
                  <li class="breadcrumb-item"><font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></li>
                </ol>
              </p>
              <p class="mb-0 text-right">
                <a
                    class="mouse-pointer text-green"
                    @click="visitNextLesson"
                >
                  <u> {{ next.title }}</u>
                </a>
              </p>
            </div>

            <div v-else-if="hasCurrentCourseFinished" class="d-flex flex-column align-items-end">
              <p class="mb-0">
                {{ $t("telestia.message.all_lessons_active") }}
                <span v-if="!isOfflineBuild && orgAssignments['telestia.assignments.certificate']">
                  <br><span class="mr-1"><font-awesome-icon :icon="['fas', 'trophy']" style="color:white;width:18px;height:auto"/></span>
                  <a :href="`${orgAssignments['telestia.assignments.certificate']}?lang=${selectedLanguage}`" class="text-white" target="_blank">{{ $t('telestia.assignments.certificate') }}</a>
                </span>
              </p>
            </div>
          </div>

          <!-- Last Studied or Currently Viewing lesson section -->
          <div class="d-flex flex-column justify-content-end pl-2 flex-50 text-white">
            <div v-if="!isLessonPathEmpty(previous)" class="lesson-path d-flex flex-column align-items-end lh-22 py-1">
              <p class="mb-0">
                <small :class="{ uppercase: inLesson }">
                  {{ inLesson ? $t('telestia.title.currently_viewing') : $t('telestia.title.last_studied') }}:
                </small>
              </p>
              <p class="mb-0">
                <ol class="breadcrumb right">
                  <li class="breadcrumb-item menu1">{{ stripBrElements(previous.menu1.title.toLowerCase()) }}</li>
                  <li class="breadcrumb-item"><font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></li>
                  <li class="breadcrumb-item">{{ stripBrElements(previous.menu2.title) }}</li>
                  <li class="breadcrumb-item"><font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></li>
                </ol>
              </p>
              <p class="mb-0">
                <a class="mouse-pointer text-white"
                  @click="selectMenu({ menu1Index: previous.menu1.index, menu2Index: previous.menu2.index, lessonKey: previous.code })"
                >
                  <u> {{ previous.title }}</u>
                </a>
              </p>
            </div>

            <div v-else-if="isLessonPathEmpty(previous) && !hasCurrentCourseStarted" class="d-flex flex-column align-items-end">
              <p class="mb-0">{{ $t("telestia.message.welcome_to_course") }}</p>
            </div>
          </div>

        </div>
      </div>
    </template>

  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import vClickOutside from 'v-click-outside';
import NetworkStatus from '../shared/NetworkStatus.vue';

export default {
  name: "lesson-navigation",

  components: {
    NetworkStatus,
  },

  directives: {
    clickOutside: vClickOutside.directive
  },
  
  props: {
    inLesson: {
      type: Boolean,
      required: true
    },
  },

  data() {
    return {
      currentCourseTitle:'',

      currentlyViewing: {},
      nextToView: {},

      previousLessonCode: '',
      currentLessonCode: '',
      isCurrentSublesson: false,
      showLessonPaths: false
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isRtl() { return this.$store.getters['app/isRtl']; },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    // Currently selected product
    currentProductKey() { return this.$store.getters.getSelected.productKey; },

    // Currently selected course
    currentCourse() { return this.$store.getters.getSelected.course; },

    // Currently selected lesson
    currentLesson() { return this.$store.getters.getSelected.lesson; },

    // Is productSkeleton currently being fetched?
    fetchingProductSkeleton() { return this.$store.getters['products/getFetchingProductSkeleton']; },

    selectedLanguage() { return this.$store.getters["user/getSelectedLanguage"]; },

    // Last studied lesson (based on course progress, in offline build course progress is not taken into account)
    lastStudied() {
      return this.isOfflineBuild ? {} : this.$store.getters["progress/getLastStudied"].menu;
    },

    // Has current course just started
    hasCurrentCourseStarted() { return this.$store.getters["progress/getCurrentCourseState"].started; },

    // Next lesson to study (based on course progress, in offline build course progress is not taken into account)
    nextToStudy() {
      return this.isOfflineBuild ? {} : this.$store.getters["progress/getNextToStudy"].menu;
    },

    // Is current course finished
    hasCurrentCourseFinished() { return this.$store.getters["progress/getCurrentCourseState"].finished; },

    orgAssignments() { return (this.$store.getters['userOrganisation/getOrganisation']).assignments },

    // If current route is App:Lesson show currentlyViewing property, else lastStudied
    previous() {
      return this.inLesson ? this.currentlyViewing : this.lastStudied;
    },

    // If in offline build show nextToView. If the user is currently viewing the next lesson to study, any lesson
    // after that (meaning that the next lesson to study entry is not refreshed, hence the user has not yet returned
    // to the menu), or a sublesson, show nextToView. In any other case show nextToStudy
    next() {
      if (this.fetchingProductSkeleton) { return {}; }

      if (this.isOfflineBuild) { return this.inLesson ? this.nextToView : {}; }

      const isNextToStudy = this.nextToStudy &&
        (this.nextToStudy.code === this.currentLessonCode || (this.isCurrentSublesson && this.nextToStudy.code === this.previousLessonCode));
      const isAfterNextToStudy = this.nextToStudy &&
        (this.$store.getters.isAfter(this.currentLessonCode, this.nextToStudy.code) ||
        (this.isCurrentSublesson && this.$store.getters.isAfter(this.previousLessonCode, this.nextToStudy.code)));
      return this.inLesson && (isNextToStudy || isAfterNextToStudy)
        ? this.nextToView
        : this.nextToStudy;
    }
  },

  methods: {
    // Returns current course title
    async getCurrentCourseTitle() {
      let currentCourseTitle = ''
      if (!this.isOnlineBuild) {
        try {
          const products = await this.$store.dispatch('getProductCourses')
          products.forEach(prod => {
            const selectedCourse = prod.courses.find(c => c.ckey === this.$store.getters.getSelected.course)
            if (selectedCourse) {
              currentCourseTitle = selectedCourse.ctitle
            }
          })
          return Promise.resolve(currentCourseTitle);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      const currentCourseKey = this.$store.getters.getSelected.course;
      currentCourseTitle = this.$store.getters.getCourseTitle(currentCourseKey);
      return Promise.resolve(currentCourseTitle);
    },

  	// Update currentlyViewingLesson and nextToView properties based on previous and current lesson
    updateInLessonEntries(newVal, oldVal) {
      this.previousLessonCode = oldVal?.key;
      this.currentLessonCode = newVal.key;
      this.currentlyViewing = this.$store.getters.getCurrentlyViewingLesson;

      const isPreviousAnMlfp = this.$store.getters.getPreviouslyViewedLesson.isMainLesson;
      const isCurrentSublessonOfPrevious =
        oldVal
        && oldVal.html
        && oldVal.html.contentCategory === 1
        && oldVal.html.languages
        && oldVal.html.languages.en.pages.some(p => p.subLessons.find(l => parseInt(l.subLessonId) === newVal.dbid));

      if (isPreviousAnMlfp && isCurrentSublessonOfPrevious) {
        this.isCurrentSublesson = true;
        return;
      }

      this.isCurrentSublesson = false;

      // oldVal = null => previous route was not App:Lessons
      if (!oldVal && isPreviousAnMlfp) {
        // Get nextToView taking previouslyViewedLesson into account
        this.nextToView = this.$store.getters.getNextLesson(true);
        return;
      }
      this.nextToView = this.$store.getters.getNextLesson(false);
    },

    // Update currentlyViewingLesson and nextToView to provided a translation of their menu info
    translateInLessonEntries() {
      this.currentlyViewing = this.$store.getters.getLessonMenuEntries(this.currentlyViewing.code);
      this.nextToView = this.$store.getters.getLessonMenuEntries(this.nextToView.code);
    },

    updateNotInLessonEntries: async function() {
      if (!this.isOfflineBuild) {
        // Update lastStudied and nextToStudy properties to provide a translation of their menu info
        if (this.lastStudied && this.lastStudied.code) {
          const lastStudiedMenuInfo = this.isOfflineBuild ? {} : this.$store.getters.getLessonMenuEntries(this.lastStudied.code);
          this.$store.dispatch("progress/updateLastStudiedLesson", lastStudiedMenuInfo);
        }

        if (this.nextToStudy && this.nextToStudy.code) {
          const nextToStudyMenuInfo = this.isOfflineBuild ? {} : this.$store.getters.getLessonMenuEntries(this.nextToStudy.code);
          this.$store.dispatch("progress/updateNextLessonToStudy", nextToStudyMenuInfo);
        }
      }

      // Update course title
      this.currentCourseTitle = await this.getCurrentCourseTitle();
    },

    returnToLessonSelectionMenu() {
      this.selectMenu({ lessonKey: null });
      this.$router.push({ name: 'App:Lessons', params: { product_key: this.currentProductKey.toLowerCase(), course_key: this.currentCourse.toLowerCase() } });
    },

    visitNextLesson() {
    	this.selectMenu({ menu1Index: this.next.menu1.index, menu2Index: this.next.menu2.index, lessonKey: this.next.code });
    	this.$router.push({ name: 'App:Lessons:Lesson', params: { lesson_id: this.$store.getters.getLessonId(this.next.code) } });
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    // Helper function that strips br elements from a string path
    stripBrElements(path) {
      const stripped = path ? path.replaceAll('<br>', ' ') : path;
      return stripped;
    },

    isLessonPathEmpty(path) {
      if (!path) { return true; }
      return Object.keys(path).every(k => Object.keys(path[k]).length === 0);
    },

    hideLessonPaths(){
      this.showLessonPaths = false;
    }
  },

  watch: {
    currentLesson: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal || _isEmpty(newVal) || newVal === oldVal) { return; }

        this.updateInLessonEntries(newVal, oldVal);
      }
    },

    currentCourse: {
      immediate: true,
      async handler() {
        if (this.fetchingProductSkeleton) {
          this.currentCourseTitle = '';
          return;
        }

        try { this.currentCourseTitle = await this.getCurrentCourseTitle(); }
        catch (e) { console.log(e); }
      }
    },

    fetchingProductSkeleton: {
      async handler(n, o) {
        if (o === false) { return; }

        try { this.currentCourseTitle = await this.getCurrentCourseTitle(); }
        catch (e) { console.log(e); }
      }
    },

    // Update entries whenever the selected language is changed
    selectedLanguage() {
      this.translateInLessonEntries();
      this.updateNotInLessonEntries();
    }
  }
}
</script>

<style scoped>
  .flex-50 { flex: 50%; }
  .text-green { color: #7bebac; }
  .bg-dark-blue { background-color: #096594; }
  .border-dashed {
    border-right: 1px dashed #fff;
    border-left: none;
  }
  #app.rtl .border-dashed {
    border-left: 1px dashed #fff;
    border-right: none;
  }
  .back-to-menu { margin: 0 1.5rem 0 0; }
  #app.rtl .back-to-menu { margin: 0 0 0 1.5rem; }
  .lh-22 { line-height: 22px; }

  .back-to-menu-limited {
    position: absolute;
    z-index: 999;
    top: 2%;
    padding: 10px;
    border-radius: 10px;
    background-color: #4c68b0;
    color: white;
  }

  .burger-menu-button:hover {
    color: #facc2f;
  }

  .lesson-navigation-button {
    background: white;
    color: #1b96cf;
    border: 1px solid #086695;
    border-radius: 7px;
    padding: 1px 5px;
  }
  .lesson-navigation-button:hover {
    background: #086695;
    color: white;
  }

  .lesson-navigation-button svg {
    background: #086695;
    color: white;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-left: 5px;
  }
  .lesson-navigation-button:hover svg {
    background: white;
    color: #086695;
  }

  #lesson-paths {
    z-index: 999;
  }

  .breadcrumb .breadcrumb-item,
  .lesson-path > p {
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .breadcrumb {
    margin-bottom: 0;
  }
  .breadcrumb .breadcrumb-item {
    padding: 0 2px;
  }
  .breadcrumb .breadcrumb-item::before {
    display: none;
  }
  .breadcrumb .breadcrumb-item.menu1 {
    text-transform: capitalize;
  }
  .breadcrumb.right {
    justify-content: flex-end;
  }
  .uppercase {
    text-transform: uppercase;
  }
</style>
