import { render, staticRenderFns } from "./Maneken.vue?vue&type=template&id=3ee5d87d&scoped=true&"
import script from "./Maneken.vue?vue&type=script&lang=js&"
export * from "./Maneken.vue?vue&type=script&lang=js&"
import style0 from "./Maneken.vue?vue&type=style&index=0&id=3ee5d87d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee5d87d",
  null
  
)

export default component.exports