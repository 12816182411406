<template>
	<div class="lesson-extra-combined d-flex flex-column" style="max-height: 100%;">
    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="d-flex justify-content-center align-items-center w-100 h-100">
      <div class="loading-state mx-auto align-self-center">
        <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
      </div>
    </div>

    <template v-else>
      <div v-if="textOrPdfContent" style="padding: 0 25%; max-height: 100%; overflow-y: auto;">
        <div v-html="translatedText" class="d-flex flex-column justify-content-center align-items-center"></div>

        <div v-for="(pdf, index) in data.pdfs" :key="`pdf-${index}`" class="pdf-download-buttons mb-2 text-center">
          <button class="primary-btn px-2 py-1" @click="downloadPdf(pdf.name)">{{ pdf.buttonText }}</button>
        </div>
      </div>

      <div v-else-if="multimediaContent" class="d-flex flex-column align-items-center" style="padding: 0 20%; max-height: 100%; overflow-y: auto;">
        <img
          v-for="(img, index) in images"
          :key="`image-${index}`"
          class="img-fluid mb-2"
          :src="img.src"
          :alt="`${img.name}-${index}`"
        />

        <video
          v-for="(video, index) of videos"
          :key="`video-${index}`"
          class="mb-2 mw-100"
          :poster="video.posterImage.src"
          controls
          disablepictureinpicture
          controlsList="nodownload nofullscreen noplaybackrate"
        >
          <source
            :key="video.source.name"
            :src="video.source.src"
            type="video/mp4"
          />
        </video>
      </div>

      <div v-else class="lesson-extra-content d-flex" :class="{ 'flex-column align-items-center': textIsTitle }" style="max-height: 100%; overflow-y: auto;">
        <!-- Text and pdfs container -->
        <div class="px-2" style="flex: 50%; max-width: 50%">
          <div v-html="translatedText" class="d-flex flex-column justify-content-center align-items-center"></div>

          <div class="pdf-download-buttons">
            <button v-for="(pdf, index) in data.pdfs" :key="`pdf-${index}`" class="primary-btn px-2 py-1" @click="downloadPdf(pdf.name)">{{ pdf.buttonText }}</button>
          </div>
        </div>

        <!-- Multimedia container -->
        <div class="d-flex flex-column align-items-center px-2" style="flex: 50%; max-width: 50%;">
          <img
            v-for="(img, index) in images"
            :key="`image-${index}`"
            class="img-fluid m-2"
            :src="img.src"
            :alt="`${img.name}-${index}`"
          />

          <video
            v-for="(video, index) of videos"
            :key="`video-${index}`"
            class="mw-100"
            :poster="video.posterImage.src"
            controls
            disablepictureinpicture
            controlsList="nodownload nofullscreen noplaybackrate"
          >
            <source
              :key="video.source.name"
              :src="video.source.src"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import assets from "../../../../shared/helpers/assets";
import envConfig from "../../../../shared/configs/config.local";

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pendingComponentInit: false,
      images: [],
      videos: []
    }
  },

  created() { this.initComponent(); },

  computed: {
    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    translatedText() {
      // TODO: Uncomment when translations are added
      // const translatedText = this.data.texts[this.selectedLanguage]
      // if (translatedText) { return translatedText; }
      //
      // return this.data.texts.en;
      return this.data.texts;
    },

    selectedProductKey() {
      return this.$store.getters.getSelected.productKey;
    },

    textOrPdfContent() {
    	return this.data.images.length === 0 && this.data.videos.length === 0 && (this.data.pdfs.length > 0 || this.data.texts);
    	// return this.data.images.length === 0 && this.data.videos.length === 0 && (this.data.pdfs.length > 0 || Object.keys(this.data.texts).length > 0);
    },

    multimediaContent() {
      return (this.data.images.length > 0 || this.data.videos.length > 0) && this.data.pdfs.length === 0 && !this.data.texts;
      // return (this.data.images.length > 0 || this.data.videos.length > 0) && this.data.pdfs.length === 0 && Object.keys(this.data.texts).length === 0;
    },

    textIsTitle() {
    	const parser = new DOMParser();
 			let textElement = parser.parseFromString(this.translatedText, 'text/html').body;

			const htmlElements = textElement.getElementsByTagName("*");
			const tags = [];
			for (let i = 0; i < htmlElements.length; i++) {
				if (tags.indexOf(htmlElements[i].tagName.toLowerCase()) !== -1) { continue; }
				tags.push(htmlElements[i].tagName.toLowerCase());
			}

			return tags.length === 1 && tags[0] === 'h4';
    }
  },

  methods: {
    async initComponent() {
      try {
        this.pendingComponentInit = true;

				const images = _cloneDeep(this.data.images);
				const videos = _cloneDeep(this.data.videos)

        const assets = await this.fetchAssets(images, videos);
        this.images = assets.images;
        this.videos = assets.videos;
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    },

    async fetchAssets(images, videos) {
      try {
        const product = this.selectedProductKey.toUpperCase();
        if (!this.isOnlineBuild) {
          images = images.map(img => { return { name: img, src: this.getImageBase64(product, img) } })
          videos.forEach(video => {
            video.posterImage = { name: video.posterImage, src: this.getImageBase64(product, video.posterImage) }
            video.source = { name: video.source, src: this.getFileURL(product, video.source) }
          })
          return Promise.resolve({ images, videos });
        }

        // Initialize and send requests
        const requests = [];
        images.forEach(img => requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: img })))
        videos.forEach(video => {
          if (video.posterImage) { requests.push(this.$store.dispatch("lessons/fetchLessonAsset", { product, title: video.posterImage })) }
        })
        const results = await Promise.allSettled(requests);

        // Get assets sources
        images = images.map((img, index) => {
          const image = { name: img, src: '' }
          if (results[index].status !== 'rejected') { image.src = results[index].value ? results[index].value : this.getImageBase64(''); }
          else { image.src = this.getImageBase64(''); }
          return image
        })

        let index = images.length - 1;
        videos.forEach(video => {
          // Poster image
          let src = '';
          if (video.posterImage) {
            index++;
            if (results[index].status !== 'rejected') { src = results[index].value ? results[index].value : this.getImageBase64(''); }
            else { src = this.getImageBase64(''); }
          } else {
            src = this.getImageBase64('');
          }
          video.posterImage = { name: video.posterImage, src }
        })

        videos.forEach(video => video.source = { name: video.source, src: `${FILE_SERVER}/assets?product=${product}&title=${video.source}&language=${this.selectedLanguage}` })

        return Promise.resolve({ images, videos });
      } catch (e) {
        return Promise.reject(e);
      }
    },

    downloadPdf(pdfName) {
      const selectedProduct = this.selectedProductKey.toUpperCase();
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${selectedProduct}&title=${pdfName}&language=${this.selectedLanguage}`
        : this.getFileURL(selectedProduct, pdfName),
        'application/pdf', pdfName
      );
    },

    getImageBase64(folder, imageName) {
      return assets.getImageData(folder, imageName);
    },

    getFileURL(folder, videoName) {
      return assets.getFileURL(folder, videoName);
    },

    showErrorAlert({ title, text }) {
      this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
    data() { this.initComponent(); }
  }
}
</script>
